import React from 'react';
import { Quill } from 'react-quill';
import styled from 'styled-components';
import StyledToolTip from '../styledComponents/StyledToolTip';
import htmlEditButton from "quill-html-edit-button";
Quill.register("modules/htmlEditButton", htmlEditButton);
// Custom Undo button icon component for Quill editor. You can import it directly
// from 'quill/assets/icons/undo.svg' but I found that a number of loaders do not
// handle them correctly

// Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo();
}

function redoChange() {
  this.quill.history.redo();
}

// Add sizes to whitelist and register them
const Size = Quill.import('formats/size');
Size.whitelist = ['extra-small', 'small', 'medium', 'large'];
Quill.register(Size, true);

const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);

// Add fonts to whitelist and register them
const Font = Quill.import('formats/font');
Font.whitelist = [
  'arial',
  'comic-sans',
  'courier-new',
  'georgia',
  'helvetica',
  'lucida',
];
Quill.register(Font, true);

// Modules object for setting up the Quill editor
export const getModules = (id) => ({
  toolbar: {
    container: `#${id || 'toolbar'}`,
    handlers: {
      undo: undoChange,
      redo: redoChange,
    },
  },
  htmlEditButton: {
    msg: "Edit the content in HTML format", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
    okText: "Save", // Text to display in the OK button, default: Ok,
    cancelText: "Cancel", // Text to display in the cancel button, default: Cancel
    buttonHTML: "HTML", // Text to display in the toolbar button, default: <>
    buttonTitle: "Show HTML source", // Text to display as the tooltip for the toolbar button, default: Show HTML source
    syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
    prependSelector: "div#myelement", // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
    editorModules: {} // The default mod
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
});

// Formats objects for setting up the Quill editor
export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'clean',
];


// Quill Toolbar component
export const QuillToolbar = ({ id, noToolBar }) => (
  <>
    <StyledToolBar>
      <div id={`${id || 'toolbar'}`} className={`d-flex ${noToolBar && 'd-none'}`}>
        <div className="ql-formats d-flex me-0 toolbar-flex">
          <StyledToolTip title="Bold" childrenClass={'ms-0'}>
            <button className="ql-bold" />
          </StyledToolTip>
          <StyledToolTip title="Italic" childrenClass={'ms-0'}>
            <button className="ql-italic" />
          </StyledToolTip>
          <StyledToolTip title="Underline" childrenClass={'ms-0'}>
            <button className="ql-underline" />
          </StyledToolTip>
          <StyledToolTip title="Paragraph" childrenClass={'ms-0'}>
            <button className="ql-direction" value="rtl" />
          </StyledToolTip>
          <StyledToolTip title="Numbered list" childrenClass={'ms-0'}>
            <button className="ql-list" value="ordered" />
          </StyledToolTip>
          <StyledToolTip title="Bullet points" childrenClass={'ms-0'}>
            <button className="ql-list" value="bullet" />
          </StyledToolTip>
          <StyledToolTip title="Align left" childrenClass={'ms-0'}>
            <button className="ql-align" value="" />
          </StyledToolTip>
          <StyledToolTip title="Align Center" childrenClass={'ms-0'}>
            <button className="ql-align" value="center" />
          </StyledToolTip>
          <StyledToolTip title="Align right" childrenClass={'ms-0'}>
            <button className="ql-align" value="right" />
          </StyledToolTip>
          <StyledToolTip title="Justify" childrenClass={'ms-0'}>
            <button className="ql-align" value="justify" />
          </StyledToolTip>
          <StyledToolTip title="Insert/edit link" childrenClass={'ms-0'}>
            <button className="ql-link" />
          </StyledToolTip>
          <StyledToolTip title="Insert image" childrenClass={'ms-0'}>
            <button className="ql-image d-none" />
          </StyledToolTip>
          <StyledToolTip title="Insert video" childrenClass={'ms-0'}>
            <button className="ql-video d-none" />
          </StyledToolTip>
          <StyledToolTip title="Undo" childrenClass={'ms-0'}>
            <button className="ql-undo">
              <CustomUndo />
            </button>
          </StyledToolTip>
          <StyledToolTip title="Redo" childrenClass={'ms-0'}>
            <button className="ql-redo">
              <CustomRedo />
            </button>
          </StyledToolTip>
          <StyledToolTip title="Block" childrenClass={'ms-0'}>
            <button className="code-block" />
          </StyledToolTip>
        </div>
      </div>
    </StyledToolBar>
  </>
);
const StyledToolBar = styled.div`
  ${props => props.theme.sm} {
    .toolbar-flex {
      .ql-link,
      .ql-code-block,
      .ql-direction,
      .ql-align {
        display: none;
      }
    }
  }
`;

export default QuillToolbar;
