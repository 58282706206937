import { Form, Formik } from 'formik';
import React from 'react';
import { Modal } from 'react-bootstrap';
import FormikDatePicker from '../../../app/components/formikComponents/FormikDatePicker';
import FormikInputField from '../../../app/components/formikComponents/FormikInputField';
import FormikSelect from '../../../app/components/formikComponents/FormikSelect';
import PortletBody from '../../../app/components/portlet/PortletBody';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import { Wrap } from '../../../assets/styledComponents/Global';
import { MeetingTimes } from '../assets/utills';

const CandidateProposeModal = ({ show, onHide, handleSubmit }) => {
  return (
    <>
      <Modal show={show} backdrop="static" centered onHide={onHide} size={'lg'}>
        <Modal.Header
          closeButton
          className="border-bottom-0 align-items-baseline px-4"
        >
          <Modal.Title
            as="h4"
            className="flex-grow-1 px-0 pb-2 cursor-pointer flex-center py-3"
          >
            Propose a date and time
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex  flex-column bg-body rounded">
          <Formik
            initialValues={{
              pickDate: '',
              meeting: '',
              comment: '',
            }}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <PortletBody>
                  <Wrap>
                    <label htmlFor="pickDate" className="form-label">
                      Pick a Date
                    </label>
                    <FormikDatePicker name="pickDate" format={ 'DD.MM.YYYY - dddd' }/>
                  </Wrap>
                  <Wrap className="my-4">
                    <label htmlFor="meeting" className="form-label">
                      Pick a Date
                    </label>
                    <FormikSelect name="meeting" options={MeetingTimes} />
                  </Wrap>
                  <Wrap>
                    <label htmlFor="comment" className="form-label">
                      Additional Comments (optional)
                    </label>
                    <FormikInputField name="comment" as="textArea" />
                  </Wrap>
                  <Wrap className="justify-content-end d-flex mt-3">
                    <StyledButton type="submit">Submit</StyledButton>
                  </Wrap>
                </PortletBody>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CandidateProposeModal;
