import React from 'react'
import { Row, Col } from 'react-bootstrap'
import SideBarWelcome from './SideBarWelcome'
import { useState } from 'react'
import { WelcomeSidebarMenu } from './welcomeUtils'
import styled from 'styled-components'
import { useEffect } from 'react'
import StyledButton from '../../app/components/styledComponents/StyledButton'
import OccyLogoBlue from '../../app/components/svgIcons/OccyLogoBlue'
import WelcomeSideLayer from '../../app/components/svgIcons/WelcomeSideLayer'
import WelcomeOccyIcon from '../../app/components/svgIcons/WelcomeOccyIcon'
import WelcomeUperLayer from '../../app/components/svgIcons/WelcomeUperLayer'
import CompanySetupImage from '../../app/components/svgIcons/CompanySetupImage.png'
import JobSettingImage from '../../app/components/svgIcons/JobSettingImage.png'
import ProfileAccountImage from '../../app/components/svgIcons/ProfileAccountImage.png'
import CommunicationImage from '../../app/components/svgIcons/CommunicationImage.png'
import OnboardingCircleIcon from '../../app/components/svgIcons/OnboardingCircleIcon'
import { useNavigate } from 'react-router-dom'
import { StyledLabel } from '../../assets/styledComponents/Global'
import useAuth from '../../hooks/useAuth';
import theme from '../../utils/themes'
import ArrowButtonIcon from '../../app/components/svgIcons/ArrowButtonIcon'

const WelcomePage = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const [tab, setTab] = useState(1);
    const getContent = () => {
        return WelcomeSidebarMenu?.find((item) => item?.id === tab)?.content
    }

    useEffect(() => {
        getContent()
    }, [tab])

    const handleDone = () => {
        navigate('/wizard');
    }

    const getClicked = () => {
        const obj = WelcomeSidebarMenu.find(item => item.id === tab);
        if (obj) {
            obj.tick = true;
        }
    }
    return (
        <StyledStagesContainer>

            <div className='d-flex position-relative'>
                <StyledPosition variant='layer'>
                    <WelcomeUperLayer />
                </StyledPosition>
            </div>
            <StyledContainer className='d-flex flex-column'>
                <Row>
                    <div className='d-flex position-relative'>
                        <StyledPosition variant='logo'>
                            <OccyLogoBlue />
                        </StyledPosition>
                    </div>
                    <Col lg={3} className='ps-5'>
                        <SideBarWelcome setTab={setTab} tab={tab} />
                    </Col>
                    <StyledCol lg={9} >
                        {tab === 1 &&
                            <StyledLabel weight='600' size='24px' color={theme?.primaryDark}>Hello {user?.firstName + " " + user?.lastName} 👋🏻 </StyledLabel>
                        }
                        {getContent()}
                        <StyledButton endIcon={tab === 1 && <ArrowButtonIcon className='ms-2' />} variant={'primary'} className='mt-4 cursor-pointer' onClick={() => {
                            getClicked();
                            tab !== 5 ? setTab(tab + 1) : handleDone();
                        }}>
                            {tab === 1 ? `Let's go` : tab === 5 ? 'Done' : 'Next'}
                        </StyledButton>
                    </StyledCol>
                    <div className='d-flex position-relative'>
                        {tab === 1 ?
                            <>
                                <StyledPosition variant='icon'>
                                    <WelcomeSideLayer />
                                </StyledPosition>
                                <StyledPosition variant='occy'>
                                    <WelcomeOccyIcon />
                                </StyledPosition>
                            </>
                            :
                            <>
                                <StyledPosition variant='circle'>
                                    <OnboardingCircleIcon />
                                </StyledPosition>
                                <StyledPosition variant='image'>
                                    <img src={tab === 2 ? CompanySetupImage
                                        : tab === 3 ? JobSettingImage
                                            : tab === 4 ? CommunicationImage
                                                : ProfileAccountImage}
                                        alt='com' />
                                </StyledPosition>
                            </>
                        }
                    </div>
                </Row>
            </StyledContainer>
        </StyledStagesContainer >
    )
}

export default WelcomePage


const StyledStagesContainer = styled.div`
background - image : url(${(props) => (props?.background)});
background - repeat: no - repeat;
background - size: cover;
`;


const StyledPosition = styled.div`
${({ variant }) => {
        const basicProperties = `position: absolute;`;
        switch (variant) {
            case 'image':
                return `${basicProperties}
    top:-43px;
    right:0px;`;
            case 'icon':
                return `
    ${basicProperties}
    top:-370px;
    right:10px;`;
            case 'occy':
                return `
    ${basicProperties}
    top:-80px;
    right:50px;`;
            case 'circle':
                return `
    ${basicProperties}
    top:-100px;
    right:0px;`;
            case 'logo':
                return `
    ${basicProperties}
    top:-10px;
    right:120px;`;
            case 'layer':
                return `
    ${basicProperties}
    top:-120px;
    z-index:0;
    left:10px;`;
            default:
                return `
    ${basicProperties}`;
        }
    }}
`;

const StyledContainer = styled.div`
z-index:2;
margin-top:130px;
`

const StyledCol = styled(Col)`
z-index:2;
@media (min-width: 992px) and (max-width: 1445px) {
padding-left:100px;
  }
`