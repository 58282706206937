import React from 'react';

const LocationIcon = ({ size = '17', color = 'currentColor',...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.7467 4.63341C11.0467 1.55341 8.36006 0.166748 6.00006 0.166748C6.00006 0.166748 6.00006 0.166748 5.9934 0.166748C3.64006 0.166748 0.94673 1.54675 0.24673 4.62675C-0.53327 8.06675 1.5734 10.9801 3.48006 12.8134C4.18673 13.4934 5.0934 13.8334 6.00006 13.8334C6.90673 13.8334 7.8134 13.4934 8.5134 12.8134C10.4201 10.9801 12.5267 8.07341 11.7467 4.63341ZM6.00006 7.97341C4.84006 7.97341 3.90006 7.03342 3.90006 5.87341C3.90006 4.71342 4.84006 3.77341 6.00006 3.77341C7.16006 3.77341 8.10006 4.71342 8.10006 5.87341C8.10006 7.03342 7.16006 7.97341 6.00006 7.97341Z"
      fill={color}
    />
  </svg>
);

export default LocationIcon;
