import React, { useState, useEffect } from 'react';

const GoogleMap = ({ address, postalCode, height }) => {
    const [mapUrl, setMapUrl] = useState('');

    useEffect(() => {
        const encodedAddress = encodeURIComponent(postalCode || address);
        const url = `https://maps.google.com/maps?q=${encodedAddress}&t=&z=13&ie=UTF8&iwloc=&output=embed`;
        setMapUrl(url);
    }, [postalCode, address]);

    return (
        <iframe
            title="Google Map"
            src={mapUrl}
            width='100%'
            height={height}
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen
        />
    );
};

export default GoogleMap;