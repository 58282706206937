import React from 'react';

const TickIcon = ({ size = 16,color="#26A69A", ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.75 16.25C8.36458 16.2517 7.9908 16.1181 7.69375 15.8725L2.01583 10.99C1.89301 10.8831 1.81643 10.7328 1.80221 10.5706C1.78799 10.4084 1.83723 10.247 1.93958 10.1204L3.25791 8.50667C3.3108 8.44185 3.37616 8.38831 3.45012 8.34921C3.52408 8.31012 3.60514 8.28627 3.68848 8.27908C3.77183 8.27189 3.85577 8.28151 3.93534 8.30736C4.0149 8.33322 4.08846 8.37478 4.15166 8.42959L8.58333 12.2771L15.7083 4.45584C15.8192 4.33389 15.9737 4.26065 16.1383 4.25207C16.3028 4.24349 16.4641 4.30025 16.5871 4.41L18.1404 5.80417C18.2017 5.85915 18.2516 5.92571 18.2871 6.00001C18.3226 6.07432 18.343 6.1549 18.3473 6.23714C18.3516 6.31938 18.3395 6.40166 18.3119 6.47923C18.2842 6.5568 18.2415 6.62815 18.1862 6.68917L9.99166 15.6971C9.83454 15.8705 9.64295 16.0093 9.42915 16.1045C9.21535 16.1997 8.98404 16.2493 8.75 16.25Z"
      fill={color}
    />
  </svg>
);

export default TickIcon;
