export const duplicateTemplates = [
  {
    id: 1,
    name: 'Internship Reachout',
    reference: 'Abc',
    email: 'xyz@gmail.com',
  },
  { id: 2, name: 'Offer Generated', reference: 'XYZ', email: 'xyz@gmail.com' },
];
export const TEMPLATEROUTE = {
  PROFILE: 0,
  TEAM: 4,
  EMAIL: 8,
  SMS: 9,
  JOB: 10,
  NOTIFICATIONS: 1,
  USERPERMISSION: 5,
};
export const USERROLES = {
  ADMIN: 'Admin',
  RECRUITER: 'Recruiter',
  HIRINGMANAGER: 'Hiring Manager',
  INTERVIEWER: 'Interviewer',
  APPROVER: 'Approver',
  BILLINGADMIN: 'Billing',
};
export const RolesType = [
  {
    label: 'Recruiter',
    values: 1,
  },
  {
    label: 'Interviewer',
    values: 2,
  },
  {
    label: 'Admin',
    values: 3,
  },
  {
    label: 'Billing Admin',
    values: 4,
  },
  {
    label: 'Hiring Manager',
    values: 5,
  },
  {
    label: 'Approver',
    values: 6,
  },
];
export const teamFilters = [
  {
    id: 1,
    label: 'Added On (Latest First)',
    value: 'applied-latest-to-oldest',
    sortvalue: { path: 'createdAt', order: 'DESC' },
  },
  {
    id: 2,
    label: 'Added On (Oldest First)',
    value: 'applied-oldest-to-latest',
    sortvalue: { path: 'createdAt', order: 'ASC' },
  },
];
export const UserPermissionTabs = [
  {
    id: 1,
    title: 'General',
    active: true,
    status: 1,
  },
  {
    id: 2,
    title: `Features`,
    active: false,
    status: 2,
  },
  {
    id: 3,
    title: `Billing`,
    active: false,
    status: 3,
  },
];

export const GeneralTabData = [
  {
    name: 'Recruiter',
    responce: [
      {
        id: 1,
        title: 'Jobs',
        data: [
          {
            id: 1,
            title: 'High-level data access on all jobs',
            subtitle:
              'Can view, search, filter or share on social media for all jobs.',
            icon: true,
          },
          {
            id: 2,
            title: 'View jobs they have access to/created by them ',
            subtitle:
              'Can view, manage or share jobs assigned to them or created by them.',
            icon: true,
          },
          {
            id: 3,
            title: 'Manage jobs',
            subtitle: 'Can edit jobs that are created by or assigned to them.',
            icon: true,
          },
          {
            id: 4,
            title: 'Delete jobs they have access to/created by them',
            subtitle:
              'Can view, manage or share jobs assigned to them or created by them.',
            icon: true,
          },
          {
            id: 5,
            title: 'Create jobs',
            subtitle: 'Can create new jobs.',
            icon: true,
          },
          {
            id: 6,
            title: 'Share jobs',
            subtitle:
              'Generate link to share the job with any other recipient or through social media.',
            icon: true,
          },
          {
            id: 7,
            title: 'Add notes against a job',
            subtitle: 'User can view and add notes against a job.',
            icon: true,
          },
          {
            id: 8,
            title: 'Manage hiring team',
            subtitle:
              'User can add/invite or remove members from the hiring team.',
            icon: true,
          },
          {
            id: 9,
            title: 'Follow jobs',
            subtitle:
              'User can follow jobs that they are not a part of to get related notifications.',
            icon: true,
          },
          {
            id: 10,
            title: 'High-level data access on all hiring plan',
            subtitle: 'Can view, search or filter for all jobs.',
            icon: true,
          },
          {
            id: 11,
            title: 'create hiring plan',
            subtitle: 'Can create new hiring plan.',
            icon: true,
          },
          {
            id: 12,
            title: 'View hiring plan they have access to/created by them ',
            subtitle:
              'Can view or manage hiring plan assigned to them or created by them.',
            icon: true,
          },
        ],
      },
      {
        id: 2,
        title: 'Candidates',
        data: [
          {
            id: 10,
            title: 'View all candidates',
            subtitle:
              'User can view all candidates listed in the candidate section.',
            icon: true,
          },
          {
            id: 11,
            title: 'Manage candidates ',
            subtitle:
              'Can add, edit, merge candidate information, and move them between stages. Can also add/manage tags, sources, and upload files.',
            icon: true,
          },
          {
            id: 12,
            title: 'Delete candidates',
            subtitle: 'Can delete any candidate they have access to.',
            icon: true,
          },
          {
            id: 13,
            title: 'Share candidates',
            subtitle:
              'Can share candidate data for any candidate the user has access to.',
            icon: true,
          },
          {
            id: 14,
            title: 'Add notes against a candidate/application',
            subtitle:
              'User can view and add notes against a candidate or an application.',
            icon: true,
          },
          {
            id: 15,
            title: 'View questionnaire forms against a candidate',
            subtitle:
              'User can view questionnaire answers that have been filled by candidates they have access to.',
            icon: true,
          },
          {
            id: 16,
            title: 'Add/edit evaluation forms against a Job',
            subtitle:
              'User can add and edit evaluations forms to jobs they have access to.',
            icon: true,
          },
        ],
      },
      {
        id: 3,
        title: 'Interviews',
        data: [
          {
            id: 17,
            title: 'Create interview slots',
            subtitle:
              'User can create interview slots for jobs that are created by or assigned to them and share it with candidates.',
            icon: true,
          },
        ],
      },
    ],
  },
  {
    name: 'Interviewer',
    responce: [
      {
        id: 1,
        title: 'Jobs',
        data: [
          {
            id: 1,
            title: 'High-level data access on all jobs',
            subtitle:
              'Can view, search, filter or share on social media for all jobs.',
            icon: false,
          },
          {
            id: 2,
            title: 'View jobs they have access to/created by them ',
            subtitle:
              'Can view, manage or share jobs assigned to them or created by them.',
            icon: true,
          },
          {
            id: 3,
            title: 'Manage jobs',
            subtitle: 'Can edit jobs that are created by or assigned to them.',
            icon: false,
          },
          {
            id: 4,
            title: 'Delete jobs they have access to/created by them',
            subtitle:
              'Can view, manage or share jobs assigned to them or created by them.',
            icon: false,
          },
          {
            id: 5,
            title: 'Create jobs',
            subtitle: 'Can create new jobs.',
            icon: false,
          },
          {
            id: 6,
            title: 'Share jobs',
            subtitle:
              'Generate link to share the job with any other recipient or through social media.',
            icon: false,
          },
          {
            id: 7,
            title: 'Add notes against a Job',
            subtitle: 'User can view and add notes against a job.',
            icon: true,
          },
          {
            id: 8,
            title: 'Manage hiring team',
            subtitle:
              'User can add/invite or remove members from the hiring team.',
            icon: false,
          },
          {
            id: 9,
            title: 'Follow jobs',
            subtitle:
              'User can follow jobs that they are not a part of to get related notifications.',
            icon: true,
          },
          {
            id: 10,
            title: 'High-level data access on all hiring plan',
            subtitle: 'Can view, search or filter for all jobs.',
            icon: false,
          },
          {
            id: 11,
            title: 'create hiring plan',
            subtitle: 'Can create new hiring plan.',
            icon: false,
          },
          {
            id: 12,
            title: 'View hiring plan they have access to/created by them ',
            subtitle:
              'Can view or manage hiring plan assigned to them or created by them.',
            icon: false,
          },
        ],
      },
      {
        id: 2,
        title: 'Candidates',
        data: [
          {
            id: 10,
            title: 'View all candidates',
            subtitle:
              'User can view all candidates listed in the candidate section.',
            icon: false,
          },
          {
            id: 11,
            title: 'Manage candidates ',
            subtitle:
              'Can add, edit, merge candidate information, and move them between stages. Can also add/manage tags, sources, and upload files.',
            icon: false,
          },
          {
            id: 12,
            title: 'Delete candidates',
            subtitle: 'Can delete any candidate they have access to.',
            icon: false,
          },
          {
            id: 13,
            title: 'Share candidates',
            subtitle:
              'Can share candidate data for any candidate the user has access to.',
            icon: false,
          },
          {
            id: 14,
            title: 'Add notes against a candidate/application',
            subtitle:
              'User can view and add notes against a candidate or an application.',
            icon: true,
          },
          {
            id: 15,
            title: 'View questionnaire forms against a candidate',
            subtitle:
              'User can view questionnaire answers that have been filled by candidates they have access to.',
            icon: true,
          },
          {
            id: 16,
            title: 'Add/Edit evaluation forms against a Job',
            subtitle:
              'User can add and edit evaluations forms to jobs they have access to.',
            icon: true,
          },
        ],
      },
      {
        id: 3,
        title: 'Interviews',
        data: [
          {
            id: 17,
            title: 'Create interview slots',
            subtitle:
              'User can create Interview slots for jobs that are created by or assigned to them and share it with candidates.',
            icon: false,
          },
        ],
      },
    ],
  },
  {
    name: 'Admin',
    responce: [
      {
        id: 1,
        title: 'Jobs',
        data: [
          {
            id: 1,
            title: 'High-level data access on all jobs',
            subtitle:
              'Can view, search, filter or share on social media for all jobs.',
            icon: true,
          },
          {
            id: 2,
            title: 'View jobs they have access to/created by them ',
            subtitle:
              'Can view, manage or share jobs assigned to them or created by them.',
            icon: true,
          },
          {
            id: 3,
            title: 'Manage jobs',
            subtitle: 'Can edit jobs that are created by or assigned to them.',
            icon: true,
          },
          {
            id: 4,
            title: 'Delete jobs they have access to/created by them',
            subtitle:
              'Can view, manage or share jobs assigned to them or created by them.',
            icon: true,
          },
          {
            id: 5,
            title: 'Create jobs',
            subtitle: 'Can create new jobs.',
            icon: true,
          },
          {
            id: 6,
            title: 'Share jobs',
            subtitle:
              'Generate link to share the job with any other recipient or through social media.',
            icon: true,
          },
          {
            id: 7,
            title: 'Add notes against a Job',
            subtitle: 'User can view and add notes against a job.',
            icon: true,
          },
          {
            id: 8,
            title: 'Manage hiring team',
            subtitle:
              'User can add/invite or remove members from the hiring team.',
            icon: true,
          },
          {
            id: 9,
            title: 'Follow jobs',
            subtitle:
              'User can follow jobs that they are not a part of to get related notifications.',
            icon: true,
          },
          {
            id: 10,
            title: 'High-level data access on all hiring plan',
            subtitle: 'Can view, search or filter for all jobs.',
            icon: true,
          },
          {
            id: 11,
            title: 'create hiring plan',
            subtitle: 'Can create new hiring plan.',
            icon: true,
          },
          {
            id: 12,
            title: 'View hiring plan they have access to/created by them ',
            subtitle:
              'Can view or manage hiring plan assigned to them or created by them.',
            icon: true,
          },
        ],
      },
      {
        id: 2,
        title: 'Candidates',
        data: [
          {
            id: 10,
            title: 'View all candidates',
            subtitle:
              'User can view all candidates listed in the candidate section.',
            icon: true,
          },
          {
            id: 11,
            title: 'Manage candidates ',
            subtitle:
              'Can add, edit, merge candidate information, and move them between stages. Can also add/manage tags, sources, and upload files.',
            icon: true,
          },
          {
            id: 12,
            title: 'Delete candidates',
            subtitle: 'Can delete any candidate they have access to.',
            icon: true,
          },
          {
            id: 13,
            title: 'Share candidates',
            subtitle:
              'Can share candidate data for any candidate the user has access to.',
            icon: true,
          },
          {
            id: 14,
            title: 'Add notes against a candidate/application',
            subtitle:
              'User can view and add notes against a candidate or an application.',
            icon: true,
          },
          {
            id: 15,
            title: 'View questionnaire forms against a candidate',
            subtitle:
              'User can view questionnaire answers that have been filled by candidates they have access to.',
            icon: true,
          },
          {
            id: 16,
            title: 'Add/Edit evaluation forms against a Job',
            subtitle:
              'User can add and edit evaluations forms to jobs they have access to.',
            icon: true,
          },
        ],
      },
      {
        id: 3,
        title: 'Interviews',
        data: [
          {
            id: 17,
            title: 'Create interview slots',
            subtitle:
              'User can create interview slots for jobs that are created by or assigned to them and share it with candidates.',
            icon: true,
          },
        ],
      },
    ],
  },
  {
    name: 'Billing Admin',
    responce: [
      {
        id: 1,
        title: 'Jobs',
        data: [
          {
            id: 1,
            title: 'High-level data access on all jobs',
            subtitle:
              'Can view, search, filter or share on social media for all jobs.',
            icon: false,
          },
          {
            id: 2,
            title: 'View jobs they have access to/created by them ',
            subtitle:
              'Can view, manage or share jobs assigned to them or created by them.',
            icon: false,
          },
          {
            id: 3,
            title: 'Manage jobs',
            subtitle: 'Can edit jobs that are created by or assigned to them.',
            icon: false,
          },
          {
            id: 4,
            title: 'Delete jobs they have access to/created by them',
            subtitle:
              'Can view, manage or share jobs assigned to them or created by them.',
            icon: false,
          },
          {
            id: 5,
            title: 'Create jobs',
            subtitle: 'Can create new jobs.',
            icon: false,
          },
          {
            id: 6,
            title: 'Share jobs',
            subtitle:
              'Generate link to share the job with any other recipient or through social media.',
            icon: false,
          },
          {
            id: 7,
            title: 'Add notes against a Job',
            subtitle: 'User can view and add notes against a job.',
            icon: false,
          },
          {
            id: 8,
            title: 'Manage hiring team',
            subtitle:
              'User can add/invite or remove members from the hiring team.',
            icon: false,
          },
          {
            id: 9,
            title: 'Follow jobs',
            subtitle:
              'User can follow jobs that they are not a part of to get related notifications.',
            icon: true,
          },
          {
            id: 10,
            title: 'High-level data access on all hiring plan',
            subtitle: 'Can view, search or filter for all jobs.',
            icon: false,
          },
          {
            id: 11,
            title: 'create hiring plan',
            subtitle: 'Can create new hiring plan.',
            icon: false,
          },
          {
            id: 12,
            title: 'View hiring plan they have access to/created by them ',
            subtitle:
              'Can view or manage hiring plan assigned to them or created by them.',
            icon: false,
          },
        ],
      },
      {
        id: 2,
        title: 'Candidates',
        data: [
          {
            id: 10,
            title: 'View all candidates',
            subtitle:
              'User can view all candidates listed in the candidate section.',
            icon: false,
          },
          {
            id: 11,
            title: 'Manage candidates ',
            subtitle:
              'Can add, edit, merge candidate information, and move them between stages. Can also add/manage tags, sources, and upload files.',
            icon: false,
          },
          {
            id: 12,
            title: 'Delete candidates',
            subtitle: 'Can delete any candidate they have access to.',
            icon: false,
          },
          {
            id: 13,
            title: 'Share candidates',
            subtitle:
              'Can share candidate data for any candidate the user has access to.',
            icon: false,
          },
          {
            id: 14,
            title: 'Add notes against a candidate/application',
            subtitle:
              'User can view and add notes against a candidate or an application.',
            icon: false,
          },
          {
            id: 15,
            title: 'View questionnaire forms against a candidate',
            subtitle:
              'User can view questionnaire answers that have been filled by candidates they have access to.',
            icon: false,
          },
          {
            id: 16,
            title: 'Add/Edit evaluation forms against a Job',
            subtitle:
              'User can add and edit evaluations forms to jobs they have access to.',
            icon: false,
          },
        ],
      },
      {
        id: 3,
        title: 'Interviews',
        data: [
          {
            id: 17,
            title: 'Create interview slots',
            subtitle:
              'User can create Interview slots for jobs that are created by or assigned to them and share it with candidates.',
            icon: false,
          },
        ],
      },
    ],
  },
  {
    name: 'Hiring Manager',
    responce: [
      {
        id: 1,
        title: 'Jobs',
        data: [
          {
            id: 1,
            title: 'High-level data access on all jobs',
            subtitle:
              'Can view, search, filter or share on social media for all jobs.',
            icon: false,
          },
          {
            id: 2,
            title: 'View jobs they have access to/created by them ',
            subtitle:
              'Can view, manage or share jobs assigned to them or created by them.',
            icon: true,
          },
          {
            id: 3,
            title: 'Manage jobs',
            subtitle: 'Can edit jobs that are created by or assigned to them.',
            icon: true,
          },
          {
            id: 4,
            title: 'Delete jobs they have access to/created by them',
            subtitle:
              'Can view, manage or share jobs assigned to them or created by them.',
            icon: true,
          },
          {
            id: 5,
            title: 'Create jobs',
            subtitle: 'Can create new jobs.',
            icon: true,
          },
          {
            id: 6,
            title: 'Share jobs',
            subtitle:
              'Generate link to share the job with any other recipient or through social media.',
            icon: true,
          },
          {
            id: 7,
            title: 'Add notes against a Job',
            subtitle: 'User can view and add notes against a job.',
            icon: true,
          },
          {
            id: 8,
            title: 'Manage hiring team',
            subtitle:
              'User can add/invite or remove members from the hiring team.',
            icon: true,
          },
          {
            id: 9,
            title: 'Follow jobs',
            subtitle:
              'User can follow jobs that they are not a part of to get related notifications.',
            icon: true,
          },
          {
            id: 10,
            title: 'High-level data access on all hiring plan',
            subtitle: 'Can view, search or filter for all jobs.',
            icon: false,
          },
          {
            id: 11,
            title: 'create hiring plan',
            subtitle: 'Can create new hiring plan.',
            icon: true,
          },
          {
            id: 12,
            title: 'View hiring plan they have access to/created by them ',
            subtitle:
              'Can view or manage hiring plan assigned to them or created by them.',
            icon: true,
          },
        ],
      },
      {
        id: 2,
        title: 'Candidates',
        data: [
          {
            id: 10,
            title: 'View all candidates',
            subtitle:
              'User can view all candidates listed in the candidate section.',
            icon: true,
          },
          {
            id: 11,
            title: 'Manage candidates ',
            subtitle:
              'Can add, edit, merge candidate information, and move them between stages. Can also add/manage tags, sources, and upload files.',
            icon: true,
          },
          {
            id: 12,
            title: 'Delete candidates',
            subtitle: 'Can delete any candidate they have access to.',
            icon: true,
          },
          {
            id: 13,
            title: 'Share candidates',
            subtitle:
              'Can share candidate data for any candidate the user has access to.',
            icon: true,
          },
          {
            id: 14,
            title: 'Add notes against a candidate/application',
            subtitle:
              'User can view and add notes against a candidate or an application.',
            icon: true,
          },
          {
            id: 15,
            title: 'View questionnaire forms against a candidate',
            subtitle:
              'User can view questionnaire answers that have been filled by candidates they have access to.',
            icon: true,
          },
          {
            id: 16,
            title: 'Add/Edit evaluation forms against a Job',
            subtitle:
              'User can add and edit evaluations forms to jobs they have access to.',
            icon: false,
          },
        ],
      },
      {
        id: 3,
        title: 'Interviews',
        data: [
          {
            id: 17,
            title: 'Create interview slots',
            subtitle:
              'User can create interview slots for jobs that are created by or assigned to them and share it with candidates.',
            icon: false,
          },
        ],
      },
    ],
  },
  {
    name: 'Approver',
    responce: [
      {
        id: 1,
        title: 'Jobs',
        data: [
          {
            id: 1,
            title: 'High-level data access on all jobs',
            subtitle:
              'Can view, search, filter or share on social media for all jobs.',
            icon: false,
          },
          {
            id: 2,
            title: 'View jobs they have access to/created by them ',
            subtitle:
              'Can view, manage or share jobs assigned to them or created by them.',
            icon: false,
          },
          {
            id: 3,
            title: 'Manage jobs',
            subtitle: 'Can edit jobs that are created by or assigned to them.',
            icon: false,
          },
          {
            id: 4,
            title: 'Delete jobs they have access to/created by them',
            subtitle:
              'Can view, manage or share jobs assigned to them or created by them.',
            icon: false,
          },
          {
            id: 5,
            title: 'Create jobs',
            subtitle: 'Can create new jobs.',
            icon: false,
          },
          {
            id: 6,
            title: 'Share jobs',
            subtitle:
              'Generate link to share the job with any other recipient or through social media.',
            icon: false,
          },
          {
            id: 7,
            title: 'Add notes against a Job',
            subtitle: 'User can view and add notes against a job.',
            icon: false,
          },
          {
            id: 8,
            title: 'Manage hiring team',
            subtitle:
              'User can add/invite or remove members from the hiring team.',
            icon: false,
          },
          {
            id: 9,
            title: 'Follow jobs',
            subtitle:
              'User can follow jobs that they are not a part of to get related notifications.',
            icon: false,
          },
          {
            id: 10,
            title: 'High-level data access on all hiring plan',
            subtitle: 'Can view, search or filter for all jobs.',
            icon: false,
          },
          {
            id: 11,
            title: 'create hiring plan',
            subtitle: 'Can create new hiring plan.',
            icon: false,
          },
          {
            id: 12,
            title: 'View hiring plan they have access to/created by them ',
            subtitle:
              'Can view or manage hiring plan assigned to them or created by them.',
            icon: false,
          },
        ],
      },
      {
        id: 2,
        title: 'Candidates',
        data: [
          {
            id: 10,
            title: 'View all candidates',
            subtitle:
              'User can view all candidates listed in the candidate section.',
            icon: false,
          },
          {
            id: 11,
            title: 'Manage candidates ',
            subtitle:
              'Can add, edit, merge candidate information, and move them between stages. Can also add/manage tags, sources, and upload files.',
            icon: false,
          },
          {
            id: 12,
            title: 'Delete candidates',
            subtitle: 'Can delete any candidate they have access to.',
            icon: false,
          },
          {
            id: 13,
            title: 'Share candidates',
            subtitle:
              'Can share candidate data for any candidate the user has access to.',
            icon: false,
          },
          {
            id: 14,
            title: 'Add notes against a candidate/application',
            subtitle:
              'User can view and add notes against a candidate or an application.',
            icon: false,
          },
          {
            id: 15,
            title: 'View questionnaire forms against a candidate',
            subtitle:
              'User can view questionnaire answers that have been filled by candidates they have access to.',
            icon: false,
          },
          {
            id: 16,
            title: 'Add/Edit evaluation forms against a Job',
            subtitle:
              'User can add and edit evaluations forms to jobs they have access to.',
            icon: false,
          },
        ],
      },
      {
        id: 3,
        title: 'Interviews',
        data: [
          {
            id: 17,
            title: 'Create interview slots',
            subtitle:
              'User can create interview slots for jobs that are created by or assigned to them and share it with candidates.',
            icon: false,
          },
        ],
      },
    ],
  },
];

export const FeatureTabData = [
  {
    name: 'Recruiter',
    responce: [
      {
        id: 1,
        title: 'Mailbox',
        data: [
          {
            id: 1,
            title: 'View mailbox',
            subtitle: `Can access candidate emails sent to personal and team inboxes, mark emails read/unread, and access emails within a candidate's profile.`,
            icon: true,
          },
          {
            id: 2,
            title: 'Send emails',
            subtitle: `Can send, archive, delete, reply to, and attach files/templates to emails using mailbox and from within a candidate's profile.`,
            icon: true,
          },
        ],
      },
      {
        id: 2,
        title: 'Forms',
        data: [
          {
            id: 1,
            title: 'View forms',
            subtitle:
              'Can view all forms shared to and filled by a candidate for any job they have been added to.',
            icon: true,
          },
          {
            id: 2,
            title: 'Create forms',
            subtitle:
              'Can create forms to be sent out to candidates for any job/application.',
            icon: false,
          },
          {
            id: 3,
            title: 'Manage forms',
            subtitle:
              'Can edit and delete forms to be sent out to candidates for any job/application.',
            icon: false,
          },
        ],
      },
      {
        id: 3,
        title: 'Templates',
        data: [
          {
            id: 1,
            title: 'View templates',
            subtitle: 'Can view all sms, email and job templates.',
            icon: true,
          },
          {
            id: 2,
            title: 'Create templates',
            subtitle: 'Can add a new sms, email or job template.',
            icon: false,
          },
          {
            id: 3,
            title: 'Manage templates',
            subtitle:
              'Can edit and delete any new sms, email or job template except for standard templates.',
            icon: false,
          },
        ],
      },
      {
        id: 4,
        title: 'Approvals',
        data: [
          {
            id: 1,
            title: 'Accept & reject plans ',
            subtitle: `Can approve, view or reject hiring plans.`,
            icon: false,
          },
        ],
      },
      {
        id: 5,
        title: 'Feeds',
        data: [
          {
            id: 17,
            title: 'View Feeds',
            subtitle: 'Can view or share feeds.',
            icon: false,
          },
        ],
      },
    ],
  },
  {
    name: 'Interviewer',
    responce: [
      {
        id: 1,
        title: 'Mailbox',
        data: [
          {
            id: 1,
            title: 'View mailbox',
            subtitle: `Can access candidate emails sent to personal and team inboxes, mark emails read/unread, and access emails within a candidate's profile.`,
            icon: false,
          },
          {
            id: 2,
            title: 'Send emails',
            subtitle: `Can send, archive, delete, reply to, and attach files/templates to emails using mailbox and from within a candidate's profile.`,
            icon: false,
          },
        ],
      },
      {
        id: 2,
        title: 'Forms',
        data: [
          {
            id: 1,
            title: 'View forms',
            subtitle:
              'Can view all forms shared to and filled by a candidate for any job they have been added to.',
            icon: true,
          },
          {
            id: 2,
            title: 'Create forms',
            subtitle:
              'Can create forms to be sent out to candidates for any job/application.',
            icon: false,
          },
          {
            id: 3,
            title: 'Manage forms',
            subtitle:
              'Can edit and delete forms to be sent out to candidates for any job/application.',
            icon: false,
          },
        ],
      },
      {
        id: 3,
        title: 'Templates',
        data: [
          {
            id: 1,
            title: 'View templates',
            subtitle: 'Can view all sms, email and job templates.',
            icon: false,
          },
          {
            id: 2,
            title: 'Create templates',
            subtitle: 'Can add a new sms, email or Job template.',
            icon: false,
          },
          {
            id: 3,
            title: 'Manage templates',
            subtitle:
              'Can edit and delete any new sms, email or job template except for standard templates.',
            icon: false,
          },
        ],
      },
      {
        id: 4,
        title: 'Approvals',
        data: [
          {
            id: 1,
            title: 'Accept & reject plans ',
            subtitle: `Can approve, view or reject hiring plans.`,
            icon: false,
          },
        ],
      },
      {
        id: 5,
        title: 'Feeds',
        data: [
          {
            id: 17,
            title: 'View Feeds',
            subtitle: 'Can view or share feeds.',
            icon: false,
          },
        ],
      },
    ],
  },
  {
    name: 'Admin',
    responce: [
      {
        id: 1,
        title: 'Mailbox',
        data: [
          {
            id: 1,
            title: 'View mailbox',
            subtitle: `Can access candidate emails sent to personal and team inboxes, mark emails read/unread, and access emails within a candidate's profile.`,
            icon: true,
          },
          {
            id: 2,
            title: 'Send emails',
            subtitle: `Can send, archive, delete, reply to, and attach files/templates to emails using mailbox and from within a candidate's profile.`,
            icon: true,
          },
        ],
      },
      {
        id: 2,
        title: 'Forms',
        data: [
          {
            id: 1,
            title: 'View forms',
            subtitle:
              'Can view all forms shared to and filled by a candidate for any job they have been added to.',
            icon: true,
          },
          {
            id: 2,
            title: 'Create forms',
            subtitle:
              'Can create forms to be sent out to candidates for any job/application.',
            icon: true,
          },
          {
            id: 3,
            title: 'Manage forms',
            subtitle:
              'Can edit and delete forms to be sent out to candidates for any job/application.',
            icon: true,
          },
        ],
      },
      {
        id: 3,
        title: 'Templates',
        data: [
          {
            id: 1,
            title: 'View templates',
            subtitle: 'Can view all sms, email and job templates.',
            icon: true,
          },
          {
            id: 2,
            title: 'Create templates',
            subtitle: 'Can add a new sms, email or job template.',
            icon: true,
          },
          {
            id: 3,
            title: 'Manage templates',
            subtitle:
              'Can edit and delete any new sms, email or job template except for standard templates.',
            icon: true,
          },
        ],
      },
      {
        id: 4,
        title: 'Approvals',
        data: [
          {
            id: 1,
            title: 'Accept & reject plans ',
            subtitle: `Can approve, view or reject hiring plans.`,
            icon: false,
          },
        ],
      },
      {
        id: 5,
        title: 'Feeds',
        data: [
          {
            id: 17,
            title: 'View Feeds',
            subtitle: 'Can view or share feeds.',
            icon: true,
          },
        ],
      },
    ],
  },
  {
    name: 'Billing Admin',
    responce: [
      {
        id: 1,
        title: 'Mailbox',
        data: [
          {
            id: 1,
            title: 'View mailbox',
            subtitle: `Can access candidate emails sent to personal and team inboxes, mark emails read/unread, and access emails within a candidate's profile.`,
            icon: false,
          },
          {
            id: 2,
            title: 'Send emails',
            subtitle: `Can send, archive, delete, reply to, and attach files/templates to emails using mailbox and from within a candidate's profile.`,
            icon: false,
          },
        ],
      },
      {
        id: 2,
        title: 'Forms',
        data: [
          {
            id: 1,
            title: 'View forms',
            subtitle:
              'Can view all forms shared to and filled by a candidate for any job they have been added to.',
            icon: false,
          },
          {
            id: 2,
            title: 'Create forms',
            subtitle:
              'Can create forms to be sent out to candidates for any job/application.',
            icon: false,
          },
          {
            id: 3,
            title: 'Manage forms',
            subtitle:
              'Can edit and delete forms to be sent out to candidates for any job/application.',
            icon: false,
          },
        ],
      },
      {
        id: 3,
        title: 'Templates',
        data: [
          {
            id: 1,
            title: 'View templates',
            subtitle: 'Can view all sms, email and job templates.',
            icon: false,
          },
          {
            id: 2,
            title: 'Create templates',
            subtitle: 'Can add a new sms, email or job template.',
            icon: false,
          },
          {
            id: 3,
            title: 'Manage templates',
            subtitle:
              'Can edit and delete any new sms, email or job template except for standard templates.',
            icon: false,
          },
        ],
      },
      {
        id: 4,
        title: 'Approvals',
        data: [
          {
            id: 1,
            title: 'Accept & reject plans ',
            subtitle: `Can approve, view or reject hiring plans.`,
            icon: false,
          },
        ],
      },
      {
        id: 5,
        title: 'Feeds',
        data: [
          {
            id: 17,
            title: 'View Feeds',
            subtitle: 'Can view or share feeds.',
            icon: false,
          },
        ],
      },
    ],
  },
  {
    name: 'Hiring Manager',
    responce: [
      {
        id: 1,
        title: 'Mailbox',
        data: [
          {
            id: 1,
            title: 'View mailbox',
            subtitle: `Can access candidate emails sent to personal and team inboxes, mark emails read/unread, and access emails within a candidate's profile.`,
            icon: false,
          },
          {
            id: 2,
            title: 'Send emails',
            subtitle: `Can send, archive, delete, reply to, and attach files/templates to emails using mailbox and from within a candidate's profile.`,
            icon: false,
          },
        ],
      },
      {
        id: 2,
        title: 'Forms',
        data: [
          {
            id: 1,
            title: 'View forms',
            subtitle:
              'Can view all forms shared to and filled by a candidate for any job they have been added to.',
            icon: true,
          },
          {
            id: 2,
            title: 'Create forms',
            subtitle:
              'Can create forms to be sent out to candidates for any job/application.',
            icon: false,
          },
          {
            id: 3,
            title: 'Manage forms',
            subtitle:
              'Can edit and delete forms to be sent out to candidates for any job/application.',
            icon: false,
          },
        ],
      },
      {
        id: 3,
        title: 'Templates',
        data: [
          {
            id: 1,
            title: 'View templates',
            subtitle: 'Can view all sms, email and job templates.',
            icon: false,
          },
          {
            id: 2,
            title: 'Create templates',
            subtitle: 'Can add a new sms, email or Job template.',
            icon: false,
          },
          {
            id: 3,
            title: 'Manage templates',
            subtitle:
              'Can edit and delete any new sms, email or job template except for standard templates.',
            icon: false,
          },
        ],
      },
      {
        id: 4,
        title: 'Approvals',
        data: [
          {
            id: 1,
            title: 'Accept & reject plans ',
            subtitle: `Can approve, view or reject hiring plans.`,
            icon: false,
          },
        ],
      },
      {
        id: 5,
        title: 'Feeds',
        data: [
          {
            id: 17,
            title: 'View Feeds',
            subtitle: 'Can view or share feeds.',
            icon: false,
          },
        ],
      },
    ],
  },
  {
    name: 'Approver',
    responce: [
      {
        id: 1,
        title: 'Mailbox',
        data: [
          {
            id: 1,
            title: 'View mailbox',
            subtitle: `Can access candidate emails sent to personal and team inboxes, mark emails read/unread, and access emails within a candidate's profile.`,
            icon: false,
          },
          {
            id: 2,
            title: 'Send emails',
            subtitle: `Can send, archive, delete, reply to, and attach files/templates to emails using mailbox and from within a candidate's profile.`,
            icon: false,
          },
        ],
      },
      {
        id: 2,
        title: 'Forms',
        data: [
          {
            id: 1,
            title: 'View forms',
            subtitle:
              'Can view all forms shared to and filled by a candidate for any job they have been added to.',
            icon: false,
          },
          {
            id: 2,
            title: 'Create forms',
            subtitle:
              'Can create forms to be sent out to candidates for any job/application.',
            icon: false,
          },
          {
            id: 3,
            title: 'Manage forms',
            subtitle:
              'Can edit and delete forms to be sent out to candidates for any job/application.',
            icon: false,
          },
        ],
      },
      {
        id: 3,
        title: 'Templates',
        data: [
          {
            id: 1,
            title: 'View templates',
            subtitle: 'Can view all sms, email and job templates.',
            icon: false,
          },
          {
            id: 2,
            title: 'Create templates',
            subtitle: 'Can add a new sms, email or Job template.',
            icon: false,
          },
          {
            id: 3,
            title: 'Manage templates',
            subtitle:
              'Can edit and delete any new sms, email or job template except for standard templates.',
            icon: false,
          },
        ],
      },
      {
        id: 4,
        title: 'Approvals',
        data: [
          {
            id: 1,
            title: 'Accept & reject plans ',
            subtitle: `Can approve, view or reject hiring plans.`,
            icon: true,
          },
        ],
      },
      {
        id: 5,
        title: 'Feeds',
        data: [
          {
            id: 17,
            title: 'View Feeds',
            subtitle: 'Can view or share feeds.',
            icon: false,
          },
        ],
      },
    ],
  },
];

export const notifyType = [
  {
    label: 'Hiring Team',
    value: 'HIRING_TEAM',
  },
  {
    label: 'Candidate',
    value: 'CANDIDATE',
  },
];

export const isApprover = (user)=> user?.accountRoles?.length === 1 && user?.accountRoles?.find(item => item?.role?.title === USERROLES.APPROVER)