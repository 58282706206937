import { apiSlice } from '../../app/services/apiSlice';

const OFFER_AND_AGREEMENT_URL = 'documents/app-sign-documents/';
const OFFER_AND_AGREEMENT_NONE_SIGNE_URL = 'documents/app-non-sign-documents/';
const OFFER_AND_AGREEMENT_PUBLIC_URL = 'public/documents/app-non-sign-documents/';
export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllOffersAndAgreements: builder.query({
      query: (params) => ({
        url: `${OFFER_AND_AGREEMENT_URL}`,
        params,
      }),
    }),
    getOfferorAgreementDetail: builder.query({
      query: (id) => `${OFFER_AND_AGREEMENT_URL}${id}/details`,
    }),
    sendFinalDocument: builder.mutation({
      query: (data) => ({
        url: `${OFFER_AND_AGREEMENT_URL}send`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    sendNonEsigneeDocFinalDocument: builder.mutation({
      query: (data) => ({
        url: `${OFFER_AND_AGREEMENT_NONE_SIGNE_URL}send`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    saveAndDownloadDocument: builder.mutation({
      query: (data) => ({
        url: `${OFFER_AND_AGREEMENT_NONE_SIGNE_URL}`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    getcandidateOfferDetails: builder.query({
      query: (params) => ({
        url: `${OFFER_AND_AGREEMENT_PUBLIC_URL}details?token=${params}`,
      }),
    }),
    acceptOrRejectcandidateOffer: builder.mutation({
      query: (data) => ({
        url:  `${OFFER_AND_AGREEMENT_PUBLIC_URL}accepted-rejected?token=${data?.token}`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    deleteOfferAggreements: builder.mutation({
      query: (id) => ({
        url: `${OFFER_AND_AGREEMENT_URL}${id}`,
        method: 'DELETE',
      }),
    }),
    getAppOfferOrAggreementDetails: builder.query({
      query: (appId) => ({
        url: `documents/application/offers/${appId}`,
      })
    }),
    preSignedOfferDoc: builder.mutation({
      query: (data) => ({
        url: `${OFFER_AND_AGREEMENT_NONE_SIGNE_URL}presigned-upload`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    ReattemptSendingDocument: builder.mutation({
      query: (appDocId) => ({
        url: `documents/re-attempt/${appDocId}`,
        method: 'PUT',
      }),
    }),
  }),
});

export const {
  useGetAllOffersAndAgreementsQuery,
  useGetOfferorAgreementDetailQuery,
  useSendFinalDocumentMutation,
  useGetAppOfferOrAggreementDetailsQuery,
  useDeleteOfferAggreementsMutation,
  useSaveAndDownloadDocumentMutation,
  usePreSignedOfferDocMutation,
  useSendNonEsigneeDocFinalDocumentMutation,
  useGetcandidateOfferDetailsQuery,
  useAcceptOrRejectcandidateOfferMutation,
  useReattemptSendingDocumentMutation
} = extendedApiSlice;
