import { useLocation } from 'react-router-dom';
import { STATUSES } from './Statuses';
import { isArray, isString, startCase } from 'lodash';
import { CURRENCY } from './currency';
import { useEffect, useState } from 'react';
import { BgChecksTypes } from '../features/kanban/BackgroundCheck/assets/utils';
import moment from 'moment';
import { toast } from 'react-toastify';

export const dateTimeSchedued = (date) => new Date(date) > new Date() ? true : false;


export const validOption = (value, option) =>
  new RegExp(`\\b${option}\\b`, 'i').test(value);

export const isValidEmail = (value) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);

export const handleErrorImg = async (e) => {
  e.target.onerror = null;
  e.target.src =
    'https://smartrecroot-prod.s3.eu-west-1.amazonaws.com/public/occy/Avatar.svg';
};

export const uuidv4 = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  );

export const getRandomInteger = (num) => Math.floor(Math.random() * num) + 1;
export const getActiveTabId = (tabs) => tabs?.find((t) => t.active).id;
export const handleChangeTab = (tabs, clicked) =>
  tabs?.map((p) => {
    return p.id === clicked
      ? {
        ...p,
        active: true,
      }
      : {
        ...p,
        active: false,
      };
  });
export const copyText = (text = 'Text Copied') =>
  navigator.clipboard.writeText(text);

export const getStatusFriendly = (statusId = 1, obj = STATUSES) =>
  capitalizeFirstLetter(
    startCase(Object.keys(obj).find((key) => obj[key] === statusId)),
  );

export const getCurrencySign = (name = 'GBP', obj = CURRENCY) => obj[name];

export const capitalizeFirstLetter = (string) =>
string && typeof string === 'string'
    ? string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase()
    : '';

export const getPreviewURL = (url) => {
  const msDocs = ['doc', 'docx', 'ppt', 'pptx'];
  const pdfDocs = ['pdf', 'PDF'];
  const reg = url?.match(/\.([^\\./?]+)($|\?)/);
  const isMsg = reg && reg?.length >= 2 && msDocs?.indexOf(reg[1]) !== -1;
  const isPdf = pdfDocs?.indexOf(isArray(reg) && reg[1]) !== -1;
  return isMsg
    ? `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
      url,
    )}&wdStartOn=1&wdEmbedCode=0&wdPrint=1`
    : isPdf ? url : `https://docs.google.com/viewer?url=${encodeURIComponent(
      url,
    )}&embedded=true&hgd=1`;
};
export const DurationFriendly = (no, flag) => {
  if (flag === true) {
    return ` ${no} days`;
  } else {
    switch (no) {
      case 365:
        return `${no} Annum`;
      case 30:
        return `1 month`;
      case 60:
        return `2 month`;
      case 31:
        return `1 month`;
      case 120:
        return `3 month`;
      case 7:
        return `${no} days`;
      case 15:
        return `${no} days`;
      case 1:
        return `${no} day`;
      default:
        return `${no} days`;
    }
  }
};

export const getFileNameByFileDescriptor = (name) => {
  if (name) {
    var filename = name?.replace(/^.*[\\/]/, '');
    var extension = isArray(filename) ? filename?.match(/\.[0-9a-z]+$/i)[0] : filename?.match(/\.[0-9a-z]+$/i);
    const myRe = new RegExp('\\b(uuid:){0,1}s*([a-f0-9\\-]*){1}\\s*', 'g');
    filename = filename?.replace(myRe, '');
    // filename = filename.exec((/\.[0-9a-z]+$/i)[0]);
    let final = extension ? filename?.substr(0, filename?.lastIndexOf('.')) + extension : filename;
    // const myRe = new RegExp('(?=\\w+\\.\\w{3,4}$).+', 'g');
    // return myRe.exec(name);
    // eslint-disable-next-line
    return final?.replace(/^[^a-zA-Z0-9]+|[^a-zA-Z0-9]+$/g, "");
    ;
  }
};

export const getAppEnvironment = () => {
  const env = process.env.REACT_APP_ENVIRONMENT;

  if (env === 'production') {
    return {
      IsDevelopment: false,
      IsProduction: true,
    };
  } else {
    return {
      IsDevelopment: true,
      IsProduction: false,
    };
  }
};
export const filterHtmlRigix = (data) => {
  const html =`<!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <!-- Load Quicksand font in the parent document -->
    <link href="https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap" rel="stylesheet">
    <style>
    body{
     font-family: Quicksand, sans-serif;
     font-size: .8rem;
     font-weight: 400;
     line-height:1.5;
     color:#535870;
    }
    </style>
  </head>
  <body>
    ${data}
  </body>
  </html>`
   return data ? <iframe srcDoc={html} width="100%" height="auto" title="communication"></iframe> : ''
 };
export const filterHtml = (data) => {
  return <div className="rendered-html" dangerouslySetInnerHTML={{ __html: data }} />;
};

export const filterQuillEditorHtml = (data) => {
  return  <div className="ql-editor-html rendered-html" dangerouslySetInnerHTML={{ __html: `
  <!DOCTYPE html>
  <html lang="en"> 
  <style>
 .ql-container {
   box-sizing: border-box;
   height: 100%;
   margin: 0px;
   position: relative;
 }
 .ql-container.ql-disabled .ql-tooltip {
   visibility: hidden;
 }
 .ql-container.ql-disabled .ql-editor-html ul[data-checked] > li::before {
   pointer-events: none;
 }
 .ql-clipboard {
   left: -100000px;
   height: 1px;
   overflow-y: hidden;
   position: absolute;
   top: 50%;
 }
 .ql-clipboard p {
   margin: 0;
   padding: 0;
 }
 .ql-editor-html {
   text-align: left;
   word-wrap: break-word;
   font-size:14px;
 }
 .ql-editor-html > * {
   cursor: text;
 }
 .ql-editor-html p,
 .ql-editor-html ol,
 .ql-editor-html ul,
 .ql-editor-html pre,
 .ql-editor-html blockquote,
 .ql-editor-html h1,
 .ql-editor-html h2,
 .ql-editor-html h3,
 .ql-editor-html h4,
 .ql-editor-html h5,
 .ql-editor-html h6 {
   margin: 0;
   padding: 0;
   counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
 }
 .ql-editor-html ol,
 .ql-editor-html ul {
   padding-left: 1.5em;
 }
 .ql-editor-html ol > li,
 .ql-editor-html ul > li {
   list-style-type: none;
 }
 .ql-editor-html ul > li::before {
   content: "2022";
 }
 .ql-editor-html ul[data-checked=true],
 .ql-editor-html ul[data-checked=false] {
   pointer-events: none;
 }
 .ql-editor-html ul[data-checked=true] > li *,
 .ql-editor-html ul[data-checked=false] > li * {
   pointer-events: all;
 }
 .ql-editor-html ul[data-checked=true] > li::before,
 .ql-editor-html ul[data-checked=false] > li::before {
   color: #777;
   cursor: pointer;
   pointer-events: all;
 }
 .ql-editor-html ul[data-checked=true] > li::before {
   content: "2611";
 }
 .ql-editor-html ul[data-checked=false] > li::before {
   content: "2610";
 }
 .ql-editor-html li::before {
   display: inline-block;
   white-space: nowrap;
   width: 1.2em;
 }
 .ql-editor-html li:not(.ql-direction-rtl)::before {
   margin-left: -1.5em;
   margin-right: 0.3em;
   text-align: right;
 }
 .ql-editor-html li.ql-direction-rtl::before {
   margin-left: 0.3em;
   margin-right: -1.5em;
 }
 .ql-editor-html ol li:not(.ql-direction-rtl),
 .ql-editor-html ul li:not(.ql-direction-rtl) {
   padding-left: 1.5em;
 }
 .ql-editor-html ol li.ql-direction-rtl,
 .ql-editor-html ul li.ql-direction-rtl {
   padding-right: 1.5em;
 }
 .ql-editor-html ol li {
   counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
   counter-increment: list-0;
 }
 .ql-editor-html ol li:before {
   content: counter(list-0, decimal) '. ';
 }
 .ql-editor-html ol li.ql-indent-1 {
   counter-increment: list-1;
 }
 .ql-editor-html ol li.ql-indent-1:before {
   content: counter(list-1, lower-alpha) '. ';
 }
 .ql-editor-html ol li.ql-indent-1 {
   counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
 }
 .ql-editor-html ol li.ql-indent-2 {
   counter-increment: list-2;
 }
 .ql-editor-html ol li.ql-indent-2:before {
   content: counter(list-2, lower-roman) '. ';
 }
 .ql-editor-html ol li.ql-indent-2 {
   counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
 }
 .ql-editor-html ol li.ql-indent-3 {
   counter-increment: list-3;
 }
 .ql-editor-html ol li.ql-indent-3:before {
   content: counter(list-3, decimal) '. ';
 }
 .ql-editor-html ol li.ql-indent-3 {
   counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
 }
 .ql-editor-html ol li.ql-indent-4 {
   counter-increment: list-4;
 }
 .ql-editor-html ol li.ql-indent-4:before {
   content: counter(list-4, lower-alpha) '. ';
 }
 .ql-editor-html ol li.ql-indent-4 {
   counter-reset: list-5 list-6 list-7 list-8 list-9;
 }
 .ql-editor-html ol li.ql-indent-5 {
   counter-increment: list-5;
 }
 .ql-editor-html ol li.ql-indent-5:before {
   content: counter(list-5, lower-roman) '. ';
 }
 .ql-editor-html ol li.ql-indent-5 {
   counter-reset: list-6 list-7 list-8 list-9;
 }
 .ql-editor-html ol li.ql-indent-6 {
   counter-increment: list-6;
 }
 .ql-editor-html ol li.ql-indent-6:before {
   content: counter(list-6, decimal) '. ';
 }
 .ql-editor-html ol li.ql-indent-6 {
   counter-reset: list-7 list-8 list-9;
 }
 .ql-editor-html ol li.ql-indent-7 {
   counter-increment: list-7;
 }
 .ql-editor-html ol li.ql-indent-7:before {
   content: counter(list-7, lower-alpha) '. ';
 }
 .ql-editor-html ol li.ql-indent-7 {
   counter-reset: list-8 list-9;
 }
 .ql-editor-html ol li.ql-indent-8 {
   counter-increment: list-8;
 }
 .ql-editor-html ol li.ql-indent-8:before {
   content: counter(list-8, lower-roman) '. ';
 }
 .ql-editor-html ol li.ql-indent-8 {
   counter-reset: list-9;
 }
 .ql-editor-html ol li.ql-indent-9 {
   counter-increment: list-9;
 }
 .ql-editor-html ol li.ql-indent-9:before {
   content: counter(list-9, decimal) '. ';
 }
 .ql-editor-html .ql-indent-1:not(.ql-direction-rtl) {
   padding-left: 3em;
 }
 .ql-editor-html li.ql-indent-1:not(.ql-direction-rtl) {
   padding-left: 4.5em;
 }
 .ql-editor-html .ql-indent-1.ql-direction-rtl.ql-align-right {
   padding-right: 3em;
 }
 .ql-editor-html li.ql-indent-1.ql-direction-rtl.ql-align-right {
   padding-right: 4.5em;
 }
 .ql-editor-html .ql-indent-2:not(.ql-direction-rtl) {
   padding-left: 6em;
 }
 .ql-editor-html li.ql-indent-2:not(.ql-direction-rtl) {
   padding-left: 7.5em;
 }
 .ql-editor-html .ql-indent-2.ql-direction-rtl.ql-align-right {
   padding-right: 6em;
 }
 .ql-editor-html li.ql-indent-2.ql-direction-rtl.ql-align-right {
   padding-right: 7.5em;
 }
 .ql-editor-html .ql-indent-3:not(.ql-direction-rtl) {
   padding-left: 9em;
 }
 .ql-editor-html li.ql-indent-3:not(.ql-direction-rtl) {
   padding-left: 10.5em;
 }
 .ql-editor-html .ql-indent-3.ql-direction-rtl.ql-align-right {
   padding-right: 9em;
 }
 .ql-editor-html li.ql-indent-3.ql-direction-rtl.ql-align-right {
   padding-right: 10.5em;
 }
 .ql-editor-html .ql-indent-4:not(.ql-direction-rtl) {
   padding-left: 12em;
 }
 .ql-editor-html li.ql-indent-4:not(.ql-direction-rtl) {
   padding-left: 13.5em;
 }
 .ql-editor-html .ql-indent-4.ql-direction-rtl.ql-align-right {
   padding-right: 12em;
 }
 .ql-editor-html li.ql-indent-4.ql-direction-rtl.ql-align-right {
   padding-right: 13.5em;
 }
 .ql-editor-html .ql-indent-5:not(.ql-direction-rtl) {
   padding-left: 15em;
 }
 .ql-editor-html li.ql-indent-5:not(.ql-direction-rtl) {
   padding-left: 16.5em;
 }
 .ql-editor-html .ql-indent-5.ql-direction-rtl.ql-align-right {
   padding-right: 15em;
 }
 .ql-editor-html li.ql-indent-5.ql-direction-rtl.ql-align-right {
   padding-right: 16.5em;
 }
 .ql-editor-html .ql-indent-6:not(.ql-direction-rtl) {
   padding-left: 18em;
 }
 .ql-editor-html li.ql-indent-6:not(.ql-direction-rtl) {
   padding-left: 19.5em;
 }
 .ql-editor-html .ql-indent-6.ql-direction-rtl.ql-align-right {
   padding-right: 18em;
 }
 .ql-editor-html li.ql-indent-6.ql-direction-rtl.ql-align-right {
   padding-right: 19.5em;
 }
 .ql-editor-html .ql-indent-7:not(.ql-direction-rtl) {
   padding-left: 21em;
 }
 .ql-editor-html li.ql-indent-7:not(.ql-direction-rtl) {
   padding-left: 22.5em;
 }
 .ql-editor-html .ql-indent-7.ql-direction-rtl.ql-align-right {
   padding-right: 21em;
 }
 .ql-editor-html li.ql-indent-7.ql-direction-rtl.ql-align-right {
   padding-right: 22.5em;
 }
 .ql-editor-html .ql-indent-8:not(.ql-direction-rtl) {
   padding-left: 24em;
 }
 .ql-editor-html li.ql-indent-8:not(.ql-direction-rtl) {
   padding-left: 25.5em;
 }
 .ql-editor-html .ql-indent-8.ql-direction-rtl.ql-align-right {
   padding-right: 24em;
 }
 .ql-editor-html li.ql-indent-8.ql-direction-rtl.ql-align-right {
   padding-right: 25.5em;
 }
 .ql-editor-html .ql-indent-9:not(.ql-direction-rtl) {
   padding-left: 27em;
 }
 .ql-editor-html li.ql-indent-9:not(.ql-direction-rtl) {
   padding-left: 28.5em;
 }
 .ql-editor-html .ql-indent-9.ql-direction-rtl.ql-align-right {
   padding-right: 27em;
 }
 .ql-editor-html li.ql-indent-9.ql-direction-rtl.ql-align-right {
   padding-right: 28.5em;
 }
 .ql-editor-html .ql-video {
   display: block;
   max-width: 100%;
 }
 .ql-editor-html .ql-video.ql-align-center {
   margin: 0 auto;
 }
 .ql-editor-html .ql-video.ql-align-right {
   margin: 0 0 0 auto;
 }
 .ql-editor-html .ql-bg-black {
   background-color: #000;
 }
 .ql-editor-html .ql-bg-red {
   background-color: #e60000;
 }
 .ql-editor-html .ql-bg-orange {
   background-color: #f90;
 }
 .ql-editor-html .ql-bg-yellow {
   background-color: #ff0;
 }
 .ql-editor-html .ql-bg-green {
   background-color: #008a00;
 }
 .ql-editor-html .ql-bg-blue {
   background-color: #06c;
 }
 .ql-editor-html .ql-bg-purple {
   background-color: #93f;
 }
 .ql-editor-html .ql-color-white {
   color: #fff;
 }
 .ql-editor-html .ql-color-red {
   color: #e60000;
 }
 .ql-editor-html .ql-color-orange {
   color: #f90;
 }
 .ql-editor-html .ql-color-yellow {
   color: #ff0;
 }
 .ql-editor-html .ql-color-green {
   color: #008a00;
 }
 .ql-editor-html .ql-color-blue {
   color: #06c;
 }
 .ql-editor-html .ql-color-purple {
   color: #93f;
 }
 .ql-editor-html .ql-font-serif {
   font-family: Georgia, Times New Roman, serif;
 }
 .ql-editor-html .ql-font-monospace {
   font-family: Monaco, Courier New, monospace;
 }
 .ql-editor-html .ql-size-small {
   font-size: 0.75em;
 }
 .ql-editor-html .ql-size-large {
   font-size: 1.5em;
 }
 .ql-editor-html .ql-size-huge {
   font-size: 2.5em;
 }
 .ql-editor-html .ql-direction-rtl {
   direction: rtl;
   text-align: inherit;
 }
 .ql-editor-html .ql-align-center {
   text-align: center;
 }
 .ql-editor-html .ql-align-justify {
   text-align: justify;
 }
 .ql-editor-html .ql-align-right {
   text-align: right;
 }
 .ql-editor-html.ql-blank::before {
   color: rgba(0,0,0,0.6);
   content: attr(data-placeholder);
   font-style: italic;
   left: 15px;
   pointer-events: none;
   position: absolute;
   right: 15px;
 }
 .ql-snow.ql-toolbar:after,
 .ql-snow .ql-toolbar:after {
   clear: both;
   content: '';
   display: table;
 }
 .ql-snow.ql-toolbar button,
 .ql-snow .ql-toolbar button {
   background: none;
   border: none;
   cursor: pointer;
   display: inline-block;
   float: left;
   height: 24px;
   padding: 3px 5px;
   width: 28px;
 }
 .ql-snow.ql-toolbar button svg,
 .ql-snow .ql-toolbar button svg {
   float: left;
   height: 100%;
 }
 .ql-snow.ql-toolbar button:active:hover,
 .ql-snow .ql-toolbar button:active:hover {
   outline: none;
 }
 .ql-snow.ql-toolbar input.ql-image[type=file],
 .ql-snow .ql-toolbar input.ql-image[type=file] {
   display: none;
 }
 .ql-snow.ql-toolbar button:hover,
 .ql-snow .ql-toolbar button:hover,
 .ql-snow.ql-toolbar button:focus,
 .ql-snow .ql-toolbar button:focus,
 .ql-snow.ql-toolbar button.ql-active,
 .ql-snow .ql-toolbar button.ql-active,
 .ql-snow.ql-toolbar .ql-picker-label:hover,
 .ql-snow .ql-toolbar .ql-picker-label:hover,
 .ql-snow.ql-toolbar .ql-picker-label.ql-active,
 .ql-snow .ql-toolbar .ql-picker-label.ql-active,
 .ql-snow.ql-toolbar .ql-picker-item:hover,
 .ql-snow .ql-toolbar .ql-picker-item:hover,
 .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
 .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
   color: #06c;
 }
 .ql-snow.ql-toolbar button:hover .ql-fill,
 .ql-snow .ql-toolbar button:hover .ql-fill,
 .ql-snow.ql-toolbar button:focus .ql-fill,
 .ql-snow .ql-toolbar button:focus .ql-fill,
 .ql-snow.ql-toolbar button.ql-active .ql-fill,
 .ql-snow .ql-toolbar button.ql-active .ql-fill,
 .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
 .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
 .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
 .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
 .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
 .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
 .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
 .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
 .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
 .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
 .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
 .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
 .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
 .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
 .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
 .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
 .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
 .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
 .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
 .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
 .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
 .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
   fill: #06c;
 }
 .ql-snow.ql-toolbar button:hover .ql-stroke,
 .ql-snow .ql-toolbar button:hover .ql-stroke,
 .ql-snow.ql-toolbar button:focus .ql-stroke,
 .ql-snow .ql-toolbar button:focus .ql-stroke,
 .ql-snow.ql-toolbar button.ql-active .ql-stroke,
 .ql-snow .ql-toolbar button.ql-active .ql-stroke,
 .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
 .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
 .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
 .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
 .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
 .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
 .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
 .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
 .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
 .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
 .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
 .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
 .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
 .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
 .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
 .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
 .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
 .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
 .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
 .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
 .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
 .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
   stroke: #06c;
 }
 @media (pointer: coarse) {
   .ql-snow.ql-toolbar button:hover:not(.ql-active),
   .ql-snow .ql-toolbar button:hover:not(.ql-active) {
     color: #444;
   }
   .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-fill,
   .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-fill,
   .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill,
   .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill {
     fill: #444;
   }
   .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke,
   .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke,
   .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter,
   .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter {
     stroke: #444;
   }
 }
 .ql-snow {
   box-sizing: border-box;
 }
 .ql-snow * {
   box-sizing: border-box;
 }
 .ql-snow .ql-hidden {
   display: none;
 }
 .ql-snow .ql-out-bottom,
 .ql-snow .ql-out-top {
   visibility: hidden;
 }
 .ql-snow .ql-tooltip {
   position: absolute;
   transform: translateY(10px);
 }
 .ql-snow .ql-tooltip a {
   cursor: pointer;
   text-decoration: none;
 }
 .ql-snow .ql-tooltip.ql-flip {
   transform: translateY(-10px);
 }
 .ql-snow .ql-formats {
   display: inline-block;
   vertical-align: middle;
 }
 .ql-snow .ql-formats:after {
   clear: both;
   content: '';
   display: table;
 }
 .ql-snow .ql-stroke {
   fill: none;
   stroke: #444;
   stroke-linecap: round;
   stroke-linejoin: round;
   stroke-width: 2;
 }
 .ql-snow .ql-stroke-miter {
   fill: none;
   stroke: #444;
   stroke-miterlimit: 10;
   stroke-width: 2;
 }
 .ql-snow .ql-fill,
 .ql-snow .ql-stroke.ql-fill {
   fill: #444;
 }
 .ql-snow .ql-empty {
   fill: none;
 }
 .ql-snow .ql-even {
   fill-rule: evenodd;
 }
 .ql-snow .ql-thin,
 .ql-snow .ql-stroke.ql-thin {
   stroke-width: 1;
 }
 .ql-snow .ql-transparent {
   opacity: 0.4;
 }
 .ql-snow .ql-direction svg:last-child {
   display: none;
 }
 .ql-snow .ql-direction.ql-active svg:last-child {
   display: inline;
 }
 .ql-snow .ql-direction.ql-active svg:first-child {
   display: none;
 }
 .ql-snow .ql-editor-html h1 {
   font-size: 2em;
 }
 .ql-snow .ql-editor-html h2 {
   font-size: 1.5em;
 }
 .ql-snow .ql-editor-html h3 {
   font-size: 1.17em;
 }
 .ql-snow .ql-editor-html h4 {
   font-size: 1em;
 }
 .ql-snow .ql-editor-html h5 {
   font-size: 0.83em;
 }
 .ql-snow .ql-editor-html h6 {
   font-size: 0.67em;
 }
 .ql-snow .ql-editor-html a {
   text-decoration: underline;
 }
 .ql-snow .ql-editor-html blockquote {
   border-left: 4px solid #ccc;
   margin-bottom: 5px;
   margin-top: 5px;
   padding-left: 16px;
 }
 .ql-snow .ql-editor-html code,
 .ql-snow .ql-editor-html pre {
   background-color: #f0f0f0;
   border-radius: 3px;
 }
 .ql-snow .ql-editor-html pre {
   white-space: pre-wrap;
   margin-bottom: 5px;
   margin-top: 5px;
   padding: 5px 10px;
 }
 .ql-snow .ql-editor-html code {
   font-size: 85%;
   padding: 2px 4px;
 }
 .ql-snow .ql-editor-html pre.ql-syntax {
   background-color: #23241f;
   color: #f8f8f2;
   overflow: visible;
 }
 .ql-snow .ql-editor-html img {
   max-width: 100%;
 }
 .ql-snow .ql-picker {
   color: #444;
   display: inline-block;
   float: left;
   font-size: 14px;
   font-weight: 500;
   height: 24px;
   position: relative;
   vertical-align: middle;
 }
 .ql-snow .ql-picker-label {
   cursor: pointer;
   display: inline-block;
   height: 100%;
   padding-left: 8px;
   padding-right: 2px;
   position: relative;
   width: 100%;
 }
 .ql-snow .ql-picker-label::before {
   display: inline-block;
   line-height: 22px;
 }
 .ql-snow .ql-picker-options {
   background-color: #fff;
   display: none;
   min-width: 100%;
   padding: 4px 8px;
   position: absolute;
   white-space: nowrap;
 }
 .ql-snow .ql-picker-options .ql-picker-item {
   cursor: pointer;
   display: block;
   padding-bottom: 5px;
   padding-top: 5px;
 }
 .ql-snow .ql-picker.ql-expanded .ql-picker-label {
   color: #ccc;
   z-index: 2;
 }
 .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
   fill: #ccc;
 }
 .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
   stroke: #ccc;
 }
 .ql-snow .ql-picker.ql-expanded .ql-picker-options {
   display: block;
   margin-top: -1px;
   top: 100%;
   z-index: 1;
 }
 .ql-snow .ql-color-picker,
 .ql-snow .ql-icon-picker {
   width: 28px;
 }
 .ql-snow .ql-color-picker .ql-picker-label,
 .ql-snow .ql-icon-picker .ql-picker-label {
   padding: 2px 4px;
 }
 .ql-snow .ql-color-picker .ql-picker-label svg,
 .ql-snow .ql-icon-picker .ql-picker-label svg {
   right: 4px;
 }
 .ql-snow .ql-icon-picker .ql-picker-options {
   padding: 4px 0px;
 }
 .ql-snow .ql-icon-picker .ql-picker-item {
   height: 24px;
   width: 24px;
   padding: 2px 4px;
 }
 .ql-snow .ql-color-picker .ql-picker-options {
   padding: 3px 5px;
   width: 152px;
 }
 .ql-snow .ql-color-picker .ql-picker-item {
   border: 1px solid transparent;
   float: left;
   height: 16px;
   margin: 2px;
   padding: 0px;
   width: 16px;
 }
 .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
   position: absolute;
   margin-top: -9px;
   right: 0;
   top: 50%;
   width: 18px;
 }
 .ql-snow .ql-picker.ql-header .ql-picker-label[data-label]:not([data-label=''])::before,
 .ql-snow .ql-picker.ql-font .ql-picker-label[data-label]:not([data-label=''])::before,
 .ql-snow .ql-picker.ql-size .ql-picker-label[data-label]:not([data-label=''])::before,
 .ql-snow .ql-picker.ql-header .ql-picker-item[data-label]:not([data-label=''])::before,
 .ql-snow .ql-picker.ql-font .ql-picker-item[data-label]:not([data-label=''])::before,
 .ql-snow .ql-picker.ql-size .ql-picker-item[data-label]:not([data-label=''])::before {
   content: attr(data-label);
 }
 .ql-snow .ql-picker.ql-header {
   width: 98px;
 }
 .ql-snow .ql-picker.ql-header .ql-picker-label::before,
 .ql-snow .ql-picker.ql-header .ql-picker-item::before {
   content: 'Normal';
 }
 .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
 .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
   content: 'Heading 1';
 }
 .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
 .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
   content: 'Heading 2';
 }
 .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
 .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
   content: 'Heading 3';
 }
 .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
 .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
   content: 'Heading 4';
 }
 .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
 .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
   content: 'Heading 5';
 }
 .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
 .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
   content: 'Heading 6';
 }
 .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
   font-size: 2em;
 }
 .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
   font-size: 1.5em;
 }
 .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
   font-size: 1.17em;
 }
 .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
   font-size: 1em;
 }
 .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
   font-size: 0.83em;
 }
 .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
   font-size: 0.67em;
 }
 .ql-snow .ql-picker.ql-font {
   width: 108px;
 }
 .ql-snow .ql-picker.ql-font .ql-picker-label::before,
 .ql-snow .ql-picker.ql-font .ql-picker-item::before {
   content: 'Sans Serif';
 }
 .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]::before,
 .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
   content: 'Serif';
 }
 .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=monospace]::before,
 .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
   content: 'Monospace';
 }
 .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
   font-family: Georgia, Times New Roman, serif;
 }
 .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
   font-family: Monaco, Courier New, monospace;
 }
 .ql-snow .ql-picker.ql-size {
   width: 98px;
 }
 .ql-snow .ql-picker.ql-size .ql-picker-label::before,
 .ql-snow .ql-picker.ql-size .ql-picker-item::before {
   content: 'Normal';
 }
 .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
 .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
   content: 'Small';
 }
 .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
 .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
   content: 'Large';
 }
 .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
 .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
   content: 'Huge';
 }
 .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
   font-size: 10px;
 }
 .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
   font-size: 18px;
 }
 .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
   font-size: 32px;
 }
 .ql-snow .ql-color-picker.ql-background .ql-picker-item {
   background-color: #fff;
 }
 .ql-snow .ql-color-picker.ql-color .ql-picker-item {
   background-color: #000;
 }
 .ql-toolbar.ql-snow {
   border: 1px solid #ccc;
   box-sizing: border-box;
   font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
   padding: 8px;
 }
 .ql-toolbar.ql-snow .ql-formats {
   margin-right: 15px;
 }
 .ql-toolbar.ql-snow .ql-picker-label {
   border: 1px solid transparent;
 }
 .ql-toolbar.ql-snow .ql-picker-options {
   border: 1px solid transparent;
   box-shadow: rgba(0,0,0,0.2) 0 2px 8px;
 }
 .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
   border-color: #ccc;
 }
 .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
   border-color: #ccc;
 }
 .ql-toolbar.ql-snow .ql-color-picker .ql-picker-item.ql-selected,
 .ql-toolbar.ql-snow .ql-color-picker .ql-picker-item:hover {
   border-color: #000;
 }
 .ql-toolbar.ql-snow + .ql-container.ql-snow {
   border-top: 0px;
 }
 .ql-snow .ql-tooltip {
   background-color: #fff;
   border: 1px solid #ccc;
   box-shadow: 0px 0px 5px #ddd;
   color: #444;
   padding: 5px 12px;
   white-space: nowrap;
 }
 .ql-snow .ql-tooltip::before {
   content: "Visit URL:";
   line-height: 26px;
   margin-right: 8px;
 }
 .ql-snow .ql-tooltip input[type=text] {
   display: none;
   border: 1px solid #ccc;
   font-size: 13px;
   height: 26px;
   margin: 0px;
   padding: 3px 5px;
   width: 170px;
 }
 .ql-snow .ql-tooltip a.ql-preview {
   display: inline-block;
   max-width: 200px;
   overflow-x: hidden;
   text-overflow: ellipsis;
   vertical-align: top;
 }
 .ql-snow .ql-tooltip a.ql-action::after {
   border-right: 1px solid #ccc;
   content: 'Edit';
   margin-left: 16px;
   padding-right: 8px;
 }
 .ql-snow .ql-tooltip a.ql-remove::before {
   content: 'Remove';
   margin-left: 8px;
 }
 .ql-snow .ql-tooltip a {
   line-height: 26px;
 }
 .ql-snow .ql-tooltip.ql-editing a.ql-preview,
 .ql-snow .ql-tooltip.ql-editing a.ql-remove {
   display: none;
 }
 .ql-snow .ql-tooltip.ql-editing input[type=text] {
   display: inline-block;
 }
 .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
   border-right: 0px;
   content: 'Save';
   padding-right: 0px;
 }
 .ql-snow .ql-tooltip[data-mode=link]::before {
   content: "Enter link:";
 }
 .ql-snow .ql-tooltip[data-mode=formula]::before {
   content: "Enter formula:";
 }
 .ql-snow .ql-tooltip[data-mode=video]::before {
   content: "Enter video:";
 }
 .ql-snow a {
   color: #06c;
 }
 .ql-container.ql-snow {
   border: 1px solid #ccc;
 }
  </style>
  </head>
  <body>
 ${data} 
  </body>
  </html>
  `}} />;
};
export const addAttributToAnchorTag = (htmlString) => {
  // create a temporary element to parse the HTML string
  const tempElement = document.createElement('div');
  tempElement.innerHTML = htmlString;

  // find all anchor tags inside the temporary element
  const anchorTags = tempElement.getElementsByTagName('a');
  if (isArray(anchorTags) && anchorTags?.length > 0) {
    // loop through the anchor tags and add the attribute
    for (let i = 0; i < anchorTags?.length; i++) {
      anchorTags[i].setAttribute('target', '_blank');
    }
  }

  return <div dangerouslySetInnerHTML={{ __html: tempElement.innerHTML }} />;
};
export const domainSelect = () => {
  const hostname = window.location.hostname?.split(':')[0];
  const domain = hostname?.split('.').slice(-3).join('.');
  return domain;
};
export const useQuery = () => new URLSearchParams(useLocation().search);

export const stripHtmlTags = (html) =>
  isString(html) ? html?.replace(/<[^>]*>/g, '') : html;
//Forms and dynamic fields control types
export const ControlTypes = {
  SECTION: 'section',
  HEADING: 'heading',
  SUB_HEADING: 'sub_heading',
  TEXT_INPUT: 'text',
  NUMBER_INPUT: 'number',
  PARAGRAPH: 'description',
  TEXTAREA: 'textarea',
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  DROPDOWN: 'select',
  EMAIL: 'email',
  DATE: 'date',
  FILE_UPLOAD: 'file',
  EDUCATION: 'education',
  EXPERIENCE: 'experience',
  COUNTRY: 'country',
  REFEREE: 'referee',
};

export function useWindowSize() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  function debounce(func, delay) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  useEffect(() => {
    const handleResize = debounce(() =>
      setIsSmallScreen(window.innerWidth < 768),
    );
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isSmallScreen;
}

export const getLabel = (value) => {
  let val = BgChecksTypes?.find((item) => item?.value === value.toString())?.label;
  return val;
};

export const dragStart = (ev, type = 'text/plain', ref, isFocus = false) => {
  ev.dataTransfer.effectsAllowed = 'none';
  ev.dataTransfer.setData(type, ev.target.getAttribute('id'));
  if (ref?.current) {
    if (isFocus) ref?.current?.focus()
    else ref?.current?.blur()
  }

};

export const getAdvertRedirectUrl = (value, id) => value ? `/p/job/${value?.trim()?.replace(/[^\w\s]/gi, '')?.replace(/ /g, '-')}-jp-${id}` : ''; //remove space and special characters

export const showMomentDate=(date)=>moment(date)?.isValid()?moment(date)?.format('Do MMM YYYY'):''

export const getGraphLabels = (maxDate, minDate, groupedBy) => {

  let endDate = moment(maxDate);
  let startDate =  moment(minDate);
  let labels = []
  switch (groupedBy) {
    case 'daily':
      let currentsDate = startDate.clone();
      while (currentsDate.isSameOrBefore(endDate)) {
        labels.push({ name: currentsDate.format('YYYY/MM/DD'), value: currentsDate.format('YYYY-MM-DD') });
        currentsDate.add(1, 'day');
      }
      break;
    case 'weekly':
      let currentWeekStart = startDate.clone().startOf('week');
      let currentWeekEnd = startDate.clone().endOf('week');
      while (currentWeekEnd.isSameOrBefore(endDate)) {
        labels.push({ name: `Week ${currentWeekStart.week()}`, value: currentWeekStart.week() });
        currentWeekStart.add(1, 'week');
        currentWeekEnd.add(1, 'week');
      }
      break;
    default:
      let currentDate = startDate.clone();
      do {
        labels.push({ name: currentDate.format('MMM'), value: currentDate.format('M'), year: currentDate.format('Y')});
        currentDate.add(1, 'month').date(1);
      }while (currentDate.isSameOrBefore(endDate))
      break;
  }
  return labels;
}


export const formateNumber = (value) =>{
  const dataValue =Number(value);
 return dataValue ? Number.isInteger(dataValue) ? dataValue.toString() : !isNaN(dataValue) && parseFloat(dataValue)?.toFixed(2) : "";

}

export const downloadS3File = async (fileLink) => {
  try {
    const response = await fetch(fileLink);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    // Create a temporary anchor element to trigger the download
    const link = document.createElement('a');
    link.href = url;
    const fileurl = new URL(fileLink);
    // Extract the pathname
    const pathname = fileurl.pathname;
    // Extract the filename from the pathname
    const fileName = pathname.substring(
      pathname.lastIndexOf('/') + 1,
      pathname.indexOf('?') !== -1 ? pathname.indexOf('?') : undefined,
    );

    // const fileName= getFileNameByFileDescriptor(fileLink)
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    toast.error('Error downloading:', error);
  }
};


export const formateLocalNumber = (val) => {
  if (Number?.isInteger(val)) {
    return val?.toLocaleString();
  } 
  return parseFloat(val)?.toFixed(2)?.toLocaleString();
}