import React from 'react';
import StyledDot from '../../app/components/styledComponents/StyledDot';
import OccyLogoBlue1 from '../../app/components/svgIcons/OccyLogoBlue.png';

import theme from '../../utils/themes';
import { StyledLabel, Wrap } from '../../assets/styledComponents/Global';
import PortletBody from '../../app/components/portlet/PortletBody';
import { CookiesNumber, CookiesUsedFor, PutCookies, GeneralPrivacyPolicy, PrivacyPolicyInformation, UpdatingYourInformation, CookiePolicy, CookiesWeUse } from './assets/utills';
import QuestionTile from './components/Tiles/QuestionTile';

const PolicyContainer = () => {

  return (
    <Wrap className="p-4">
      <img
        src={OccyLogoBlue1}
        alt="img"
        height={45}
        width={170}
      />
      <Wrap className="d-flex flex-column align-items-center">
        <PortletBody className="col-7">
          <StyledLabel size="32px">Privacy Policy</StyledLabel>
        </PortletBody>
        <PortletBody className="col-7 mt-3 rounded-3">
          <StyledLabel size="24px">General Privacy Policy</StyledLabel>
          <Wrap>
            {GeneralPrivacyPolicy?.map((item) => (
              <>
                <StyledLabel
                  color={theme?.textColor}
                  className="mt-3"
                  size="16px"
                  weight="500"
                >
                  {item?.title}
                </StyledLabel>
                {item?.data?.map((data) => (
                  <Wrap className="d-flex align-items-center gap-2 ms-2">
                    <StyledDot $size="4px" color={theme?.textColor} />
                    <StyledLabel
                      color={theme?.textColor}
                      size="16px"
                      weight="500"
                    >
                      {data}
                    </StyledLabel>
                  </Wrap>
                ))}
              </>
            ))}
          </Wrap>


          <QuestionTile Information={PrivacyPolicyInformation} />
          <Wrap>
            {PrivacyPolicyInformation?.map((item) => (
              <>
                <StyledLabel
                  color={theme?.textColor}
                  className="mt-3"
                  size="16px"
                  weight="500"
                >
                  {item?.title}
                </StyledLabel>
                {item?.data?.map((data) => (
                  <Wrap className="d-flex align-items-center gap-2 ms-2">
                    <StyledDot $size="4px" color={theme?.textColor} />
                    <StyledLabel
                      color={theme?.textColor}
                      size="16px"
                      weight="500"
                    >
                      {data}
                    </StyledLabel>
                  </Wrap>
                ))}
              </>
            ))}
          </Wrap>
          <QuestionTile Information={UpdatingYourInformation} />
          <Wrap>
            {UpdatingYourInformation?.map((item) => (
              <>
                <StyledLabel
                  color={theme?.textColor}
                  className="mt-3"
                  size="16px"
                  weight="500"
                >
                  {item?.title}
                </StyledLabel>
                {item?.data?.map((data) => (
                  <Wrap className="d-flex align-items-center gap-2 ms-2">
                    <StyledDot $size="4px" color={theme?.textColor} />
                    <StyledLabel
                      color={theme?.textColor}
                      size="16px"
                      weight="500"
                    >
                      {data}
                    </StyledLabel>
                  </Wrap>
                ))}
              </>
            ))}
          </Wrap>

          <QuestionTile Information={CookiePolicy} />
          <Wrap>
            {CookiePolicy?.map((item) => (
              <>
                <StyledLabel
                  color={theme?.textColor}
                  className="mt-3"
                  size="16px"
                  weight="500"
                >
                  {item?.title}
                </StyledLabel>
                {item?.data?.map((data) => (
                  <Wrap className="d-flex align-items-center gap-2 ms-2">
                    <StyledDot $size="4px" color={theme?.textColor} />
                    <StyledLabel
                      color={theme?.textColor}
                      size="16px"
                      weight="500"
                    >
                      {data}
                    </StyledLabel>
                  </Wrap>
                ))}
              </>
            ))}
          </Wrap>


          <QuestionTile Information={CookiesWeUse} />
          <Wrap>
            {CookiesWeUse?.map((item) => (
              <>
                <StyledLabel
                  color={theme?.textColor}
                  className="mt-3"
                  size="16px"
                  weight="500"
                >
                  {item?.title}
                </StyledLabel>
                {item?.data?.map((data) => (
                  <Wrap className="d-flex align-items-center gap-2 ms-2">
                    <StyledDot $size="4px" color={theme?.textColor} />
                    <StyledLabel
                      color={theme?.textColor}
                      size="16px"
                      weight="500"
                    >
                      {data}
                    </StyledLabel>
                  </Wrap>
                ))}
              </>
            ))}
          </Wrap>

          <QuestionTile Information={PutCookies} />
          <Wrap>
            {PutCookies?.map((item) => (
              <>
                <StyledLabel
                  color={theme?.textColor}
                  className="mt-3"
                  size="16px"
                  weight="500"
                >
                  {item?.title}
                </StyledLabel>
                {item?.data?.map((data) => (
                  <Wrap className="d-flex align-items-center gap-2 ms-2">
                    <StyledDot $size="4px" color={theme?.textColor} />
                    <StyledLabel
                      color={theme?.textColor}
                      size="16px"
                      weight="500"
                    >
                      {data}
                    </StyledLabel>
                  </Wrap>
                ))}
              </>
            ))}
          </Wrap>


          <QuestionTile Information={CookiesUsedFor} />
          <Wrap>
            {CookiesUsedFor?.map((item) => (
              <>
                <StyledLabel
                  color={theme?.textColor}
                  className="mt-3"
                  size="16px"
                  weight="500"
                >
                  {item?.title}
                </StyledLabel>
                {item?.data?.map((data) => (
                  <Wrap className="d-flex align-items-center gap-2 ms-2">
                    <StyledDot $size="4px" color={theme?.textColor} />
                    <StyledLabel
                      color={theme?.textColor}
                      size="16px"
                      weight="500"
                    >
                      {data}
                    </StyledLabel>
                  </Wrap>
                ))}
              </>
            ))}
          </Wrap>



          <QuestionTile Information={CookiesNumber} />
          <Wrap>
            {CookiesNumber?.map((item) => (
              <>
                <StyledLabel
                  color={theme?.textColor}
                  className="mt-3"
                  size="16px"
                  weight="500"
                >
                  {item?.title}
                </StyledLabel>
                {item?.data?.map((data) => (
                  <Wrap className="d-flex align-items-center gap-2 ms-2">
                    <StyledDot $size="4px" color={theme?.textColor} />
                    <StyledLabel
                      color={theme?.textColor}
                      size="16px"
                      weight="500"
                    >
                      {data}
                    </StyledLabel>
                  </Wrap>
                ))}
              </>
            ))}
          </Wrap>

        </PortletBody>
      </Wrap>
    </Wrap>
  );
};

export default PolicyContainer;
