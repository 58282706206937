import styled from 'styled-components';
import theme from '../../../utils/themes';

const StyledSelectCss = styled.div`
  // width: 100%;
  .async-select {
    width: 100%;
    [class*='-container'] {
      width: 100%;
    }

    [class$='-menu'] {
      @-moz-document url-prefix() {
        scrollbar-width: thin;
        scrollbar-color: ${theme.scroll} transparent;
      }
      ::-webkit-scrollbar {
        width: 7px;
        background-color: ${({ theme }) => theme.bodyBg};
      }

      ::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.inputBorder};
        border-radius: 5px;
      }

      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px ${({ theme }) => theme.inputColor};
        background-color: ${({ theme }) => `${theme.white}da`};
      }
      background-color: ${({ theme }) => theme.white} !important;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
      border: none !important;
      font-size: 12px;
      font-weight: 600;
      color: ${({ theme }) => theme.headingColor};
      padding: 10px;
      border-radius: 7px;
      &:active,
      &:focus {
        box-shadow: none !important;
      }

      &:focus {
        outline: none;
      }

      &:hover {
        color: ${({ theme }) => theme.headingColor};
      }
    }

    [class$='-control'] {
      border-color: ${({ theme }) => theme.inputBorder} !important;
      box-shadow: none !important;
      border-radius: 8px;
      font-size: 0.9rem;
      font-size: 16px !important;
      -webkit-text-size-adjust: 100% !important;
      height: 100%;
      cursor: pointer;
      padding-block: 2px;
    }

    &.workFlow {
      [class$='-menu'] {
        position: absolute;
        z-index: 9999;
        // background-color:red;
      }
    }

    &.application {
      [class$='-control'] {
        border: none;
      }
    }

    &.analytics {
      [class$='-control'] {
        [class$='-ValueContainer'] {
          &:hover {
            [class$='-singleValue'] {
              color: ${theme.primary};
            }
          }

          [class$='-singleValue'] {
            color: ${theme?.bodyColor} !important;
            font-size: 14px !important;
          }
        }
        cursor: pointer;
        background: transparent;
        font-size: 14px;
        font-weight: 600;
        border: 0;
        padding-block: 0px;
      }

      [class$='-menu'] {
        white-space: nowrap;
        width: fit-content;
      }
      [class$='-indicatorContainer'] {
        color: ${theme?.bodyColor};
        [class$='-Svg'] {
          height: 18px;
          width: 18px;
          stroke: ${theme?.bodyColor};
          fill: ${theme?.bodyColor};
        }
      }
    }

    &.publicForm {
      [class$='-menu'] {
        background: ${({ theme }) =>
          `linear-gradient(to bottom, ${theme?.backgroundColor}, ${theme?.backgroundColor}db)`};

        @include shadow();
        border: none !important;
        font-size: 12px;
        font-weight: 600;

        [class$='-option'] {
          color: ${({ theme }) => theme.primary};
          margin-bottom: 2px;

          &:active,
          &:focus {
            box-shadow: none !important;
          }

          &:focus {
            outline: none;
          }

          &:hover {
            color: ${({ theme }) => theme.primary};
          }
        }
      }

      [class$='-control'] {
        border: none;
        background-color: transparent;
        // color: ${({ theme }) => theme.inputBorder} !important;
        border-bottom: 1px solid;
        border-color: ${({ theme }) => theme.primary} !important;
        border-radius: 0;

        [class$='-indicatorContainer'] {
          color: ${({ theme }) => theme.primary} !important;
        }
        [class$='-singleValue'] {
          color: ${({ theme }) => theme.primary} !important;
          font-weight: 500;
        }
        [class$='-ValueContainer'] {
          padding: 2px 2px 2px 10px;

          [class$='-placeholder'] {
            color: ${({ theme }) => theme.primary} !important;
          }
          [class$='-Input'] {
            color: ${({ theme }) => theme.primary} !important;
          }
        }

        &:hover,
        &:active,
        &:focus {
          border-bottom: 2px solid ${({ theme }) => theme.white};
        }
      }
    }

    &.customBorder {
      [class$='-control'] {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    [class$='-indicatorContainer'] {
      padding: 5px 10px 5px 5px;
    }

    [class$='-ValueContainer'] {
      padding: 2px 2px 2px 10px;
    }

    [class$='-placeholder'] {
      color: ${({ theme }) => theme.inputPlaceHolder};
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 85%;
    }

    [class$='-indicatorSeparator'] {
      display: none;
    }

    [class$='-multiValue'] {
      border-radius: 6px;
      background-color: #e0f2f1;
      padding: 0 4px;
      font-weight: 600;
      font-size: 12px;
      margin-right: 5px;

      [role='button'] {
        padding-right: 2px;

        &:hover {
          color: ${({ theme }) => theme.headingColor};
        }
      }

      div:last-child:hover {
        background-color: transparent;
      }

      &:nth-child(even) {
        background: #fcf1e9;
      }
    }

    &.is-invalid {
      [class$='-control'] {
        border-color: ${({ theme }) => theme.danger} !important;
      }
    }

    //&.is-valid {
    //  [class$='-control'] {
    //    border-color: $success !important;
    //  }
    //}

    &.pay {
      width: max-content;
    }

    &.is-light-pink {
      [class$='-control'] {
        border-color: transparent;
        font-weight: 600;
        background-color: ${({ theme }) => theme.primaryLight} !important;
        color: ${({ theme }) => theme.primary} !important;
      }

      [class$='-indicatorContainer'] {
        color: ${({ theme }) => theme.primary} !important;
      }
    }
  }
  .team_member {
    [class$='-multiValue'] {
      border-radius: 30px;

      background-color: ${({ theme }) => theme.teamLabelbg};

      // padding: 4px 8px;

      font-weight: 600;

      font-size: 12px;

      margin-right: 5px;

      color: ${({ theme }) => theme.textColor};

      [role='button'] {
        padding-right: 2px;

        &:hover {
          color: ${({ theme }) => theme.headingColor};
        }
      }

      div:last-child:hover {
        background-color: transparent;
      }

      &:nth-child(even) {
        background: ${({ theme }) => theme.teamLabelbg};
      }
    }
  }
`;
export default StyledSelectCss;
