import { Form, Formik } from 'formik';
import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import FormikInputField from '../../../app/components/formikComponents/FormikInputField';
import PortletBody from '../../../app/components/portlet/PortletBody';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import BackArrowIcon from '../../../app/components/svgIcons/BackArrowIcon';
import { StyledLabel, Wrap } from '../../../assets/styledComponents/Global';
import theme from '../../../utils/themes';
import { usePostInterviewSlotTimeMutation } from '../../Interviews/InterviewSliceApi';
import ContactusModal from './ContactusModal';

const Contactus = ({
  setContactus,
  token,
  setContactUs,
  contactus,
  rejectOffer,
  setOfferLetter,
  isLoading: loading
}) => {
  const contactusSchema = Yup.object().shape({
    message: Yup.string().required('Required'),
  });

  const [interviewTimeSlot, { isLoading }] = usePostInterviewSlotTimeMutation();

  const [showModal, setShowModal] = useState(false);

  const onSubmit = (value) => {
    let values = {};
    values.token = token;
    if (contactus) values.contact = true;
    values.applicantComment = value?.message;
    let apiCall = contactus ? interviewTimeSlot : rejectOffer;
    apiCall(values).then((res) => {
      if (res?.data?.message) {
        setShowModal(true);
      }
    });
  };


  const hideModal = () => {
    contactus ? setContactus(false) : setContactUs(false);
    !contactus && setOfferLetter('');
    setShowModal(false);
  }

  return (
    <>
      <Wrap className="justify-content-center d-flex">
        <Wrap className="col col-sm-10  col-lg-7 mt-0 mt-sm-5">
          <StyledParentPortlet className="p-0 p-sm-5  mt-0 mt-sm-5">
            <Wrap className="flex-column d-flex align-items-sm-center mb-4">
              <StyledRoundedBorder className="d-flex align-items-center gap-3 d-block d-sm-none bg-white p-4">
                <BackArrowIcon
                  onClick={() => {
                    contactus
                      ? setContactus(false)
                      : setContactUs(false);
                  }}
                />
                <StyledLabel size="20px" className="">
                  Contact Us
                </StyledLabel>
              </StyledRoundedBorder>
              <StyledLabel size="20px" className="d-none d-sm-block">
                Contact Us
              </StyledLabel>
              {contactus ? (
                <StyledLabel weight="500px" className=" d-none d-sm-block">
                  Tell us about your concerns, our support team will get in
                  touch with you.
                </StyledLabel>
              ) : (
                <StyledLabel weight="500px" className=" d-none d-sm-block">
                  Tell us about your suitable joining dates, our hiring team
                  will get in touch with you soon
                </StyledLabel>
              )}
            </Wrap>
            <hr className="d-none d-sm-block" />
            <StyledSubPortlet className="m-4 m-sm-0 p-4 p-sm-0">
              {contactus ? (
                <StyledLabel weight="500px" className="d-block d-sm-none mb-3">
                  Tell us about your concerns, our support team will get in
                  touch with you.
                </StyledLabel>
              ) : (
                <StyledLabel weight="500px" className="d-block d-sm-none mb-3">
                  Tell us about your suitable joining dates, our hiring team
                  will get in touch with you soon
                </StyledLabel>
              )}
              <Formik
                initialValues={{
                  message: '',
                }}
                enableReinitialize
                validationSchema={contactusSchema}
                onSubmit={onSubmit}
              >
                {({ handleSubmit, values, setValues }) => (
                  <Form onSubmit={handleSubmit}>
                    <StyledLabel>Write a message</StyledLabel>
                    <FormikInputField
                      name="message"
                      id="message"
                      as="textArea"
                      placeholder="Type here..."
                      rows={7}
                    />
                    <StyledWrap className="px-2 px-sm-0 py-4 py-sm-0 bg-white">
                      <StyledButtonContainer
                        className="mt-0 mt-sm-3"
                        type="submit"
                        loading={isLoading ? isLoading : loading}
                        disabled={isLoading ? isLoading : loading}
                      >
                        Submit
                      </StyledButtonContainer>
                    </StyledWrap>
                  </Form>
                )}
              </Formik>
            </StyledSubPortlet>
          </StyledParentPortlet>
        </Wrap>
      </Wrap>
      <ContactusModal
        show={showModal}
        onHide={hideModal}
      />
    </>
  );
};

const StyledParentPortlet = styled(PortletBody)`
  background-color: transparent;

  ${(props) => props.theme.gtSm} {
    background-color: ${theme.white};
  }
`;

const StyledSubPortlet = styled(PortletBody)`
  background-color: transparent;

  ${(props) => props.theme.sm} {
    background-color: ${theme.white};
  }
`;

const StyledWrap = styled(Wrap)`
  ${(props) => props.theme.sm} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  z-index: 999;
`;

const StyledButtonContainer = styled(StyledButton)`
  ${(props) => props.theme.sm} {
    width: 100%;
  }
`;

const StyledRoundedBorder = styled(Wrap)`
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;

  ${(props) => props.theme.gtSm} {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;
export default Contactus;
