import { createSlice } from '@reduxjs/toolkit';
import { occyLs } from '../../utils/localStorage';

const lsEmailTemplateData = occyLs.getObject('template_data') || {};

const slice = createSlice({
  name: 'emailTemplate',
  initialState: {
    emailTemplate: { ...lsEmailTemplateData },
  },
  reducers: {
    setEmailTemplate: (state, { payload: { values } }) => {
      state.emailTemplate = { ...values };
      occyLs.setObject('template_data', { ...values });
    },
    resetEmailTemplate: (state) => {
      state.emailTemplate = {};
      occyLs.setObject('template_data', {});
    },
  },
});

export const { setEmailTemplate, resetEmailTemplate } = slice.actions;

export default slice.reducer;

export const getEmailTemplateState = (state) =>
  state.emailTemplate.emailTemplate;
