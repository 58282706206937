import * as Yup from 'yup';
import moment from 'moment';

export const budgetedValues = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  }
];

export const HiringPlanStatus={
  BUDGETED:1,
  UNBUDGETED:0,
}


export const hiringRequirementSchema = {
  location: Yup.object().required('Required')
  .test('location-test-address', 'Address missing', function (value) {
    const { address } = value || {};
    return !!address
  })
  .test('location-test-postcode', 'Postcode missing', function (value) {
    const { postcode} = value || {};
    return !!postcode;
  })
  .test('valid-postcode', 'Invalid postcode', function(value) {
    const { postcode} = value || {};
    return !!postcode && postcode?.length >=4;
})
  .test('location-test-town', 'Town/city missing', function (value) {
    const { town} = value || {};
    return !!town;
  })
  .test('location-test-country', 'Country missing', function (value) {
    const { country} = value || {};
    return !!country;
  }),
  jobType: Yup.string().required('Required'),
  startDate: Yup.date().default(null).nullable().required('Required'),
  endDate: Yup.date()
    .default(null)
    .nullable()
    .when('jobType', (jobType, schema) => {
      return jobType !== 'PERMANENT' ? schema.required('Required').when('startDate', (startDate, schema) => {
        return startDate
          ? schema.min(
            moment(startDate).add(1, 'days'),
            'End date must be after the start date',
          )
          : schema;
      }) : schema;
    })
    ,
  remote: Yup.string().required('Required'),
  positions: Yup.number().required('Required').min(1,'Minimum 1 position is required'),
  budgeted: Yup.string().required('Required'),
  salaryCurrency: Yup.string().required('Required'),
  salaryPeriod:Yup.string().required('Required'),
   salaryFrom: Yup.number().when('isCompetitive', {
    is: true,
    then: Yup.number().nullable(),
    otherwise: Yup.number()
    .min(0, 'Salary cannot be negative')
    .positive('Salary must be a positive number')
    .required('Required')
      .nullable(),
  }),
  salaryTo: Yup.number().when('isCompetitive', {
    is: true,
    then: Yup.number().nullable(),
    otherwise: Yup.number()
      .min(0, 'Salary cannot be negative')
      .positive('Salary must be a number')
      .required('Required')
      .when('salaryFrom', (salaryFrom, schema) => {
        return salaryFrom
          ? schema
            .min(salaryFrom, `Must be equal or greater than ${salaryFrom}`)
            .nullable()
          : schema;
      }),
  }),
  hiringManagerId: Yup.object().required('Required'),
  recruitersIds: Yup.array().required('Required').min(1,'Required'),
  interviewerIds: Yup.array().required('Required').min(1,'Required'),
};

export const hiringRequirementEditSchema = {
  hiringManagerId: Yup.object().required('Required'),
  recruitersIds: Yup.array().required('Required').min(1,'Required'),
  interviewerIds: Yup.array().required('Required').min(1,'Required'),
};

export const currencySigns = {
  USD: "$",   // United States Dollar
  GBP: "£",   // British Pound
  JPY: "¥",   // Japanese Yen
  AUD: "$",   // Australian Dollar
  CHF: "Fr",  // Swiss Franc
  SEK: "kr",  // Swedish Krona
  NZD: "$",   // New Zealand Dollar
  MXN: "Mex$",// Mexican Peso
  BRL: "R$",  // Brazilian Real
  ZAR: "R",   // South African Rand
  INR: "₹",   // Indian Rupee
  RUB: "₽",   // Russian Ruble
  TRY: "₺",   // Turkish Lira
  AED: "د.إ", // UAE Dirham
  SAR: "﷼",   // Saudi Riyal
  SGD: "S$",  // Singapore Dollar
  AFN: "؋",   // Afghan Afghani
  ALL: "Lek", // Albanian Lek
  DZD: "دج",  // Algerian Dinar
  ARS: "$",   // Argentine Peso
  AMD: "֏",   // Armenian Dram
  AWG: "ƒ",   // Aruban Florin
  BBD: "Bds$",// Barbadian Dollar
  BHD: ".د.ب",// Bahraini Dinar
  BDT: "৳",   // Bangladeshi Taka
  BZD: "BZ$", // Belize Dollar
  BTN: "Nu.", // Bhutanese Ngultrum
  BOB: "Bs.", // Bolivian Boliviano
  BWP: "P",   // Botswana Pula
  BND: "B$",  // Brunei Dollar
  BGN: "лв",  // Bulgarian Lev
  XOF: "CFA", // West African CFA Franc
  XAF: "CFA", // Central African CFA Franc
  XPF: "₣",   // CFP Franc
  CVE: "$",   // Cape Verdean Escudo
  KHR: "៛",   // Cambodian Riel
  CAD: "$",   // Canadian Dollar
  KYD: "$",   // Cayman Islands Dollar
  CLP: "$",   // Chilean Peso
  CNY: "¥",   // Chinese Yuan
  COP: "$",   // Colombian Peso
  CRC: "₡",   // Costa Rican Colon
  HRK: "kn",  // Croatian Kuna
  CUP: "₱",   // Cuban Peso
  CZK: "Kč",  // Czech Koruna
  DKK: "kr",  // Danish Krone
  DOP: "RD$", // Dominican Peso
  EGP: "£",   // Egyptian Pound
  SVC: "$",   // El Salvador Colon
  EUR: "€",   // Euro
  FKP: "£",   // Falkland Islands Pound
  FJD: "$",   // Fiji Dollar
  GHS: "¢",   // Ghana Cedi
  GIP: "£",   // Gibraltar Pound
  GTQ: "Q",   // Guatemalan Quetzal
  GGP: "£",   // Guernsey Pound
  GYD: "$",   // Guyanese Dollar
  HNL: "L",   // Honduran Lempira
  HKD: "$",   // Hong Kong Dollar
  HUF: "Ft",  // Hungarian Forint
  ISK: "kr",  // Icelandic Krona
  IDR: "Rp",  // Indonesian Rupiah
  IRR: "﷼",   // Iranian Rial
  IMP: "£",   // Isle of Man Pound
  ILS: "₪",   // Israeli Shekel
  JMD: "J$",  // Jamaican Dollar
  JEP: "£",   // Jersey Pound
  KZT: "лв",  // Kazakhstani Tenge
  KPW: "₩",   // North Korean Won
  KRW: "₩",   // South Korean Won
  KGS: "лв",  // Kyrgyzstani Somh
  LAK: "₭",   // Lao Kip
  LBP: "£",   // Lebanese Pound
  LRD: "$",   // Liberian Dollar
  MKD: "ден", // Macedonian Denar
  MYR: "RM",  // Malaysian Ringgit
  MUR: "₨",   // Mauritian Rupee
  MNT: "₮",   // Mongolian Tugrik
  MAD: "د.إ", // Moroccan Dirham
  MZN: "MT",  // Mozambican Metical
  NAD: "$",   // Namibian Dollar
  NPR: "₨",   // Nepalese Rupee
  ANG: "ƒ",   // Netherlands Antillean Guilder
  NIO: "C$",  // Nicaraguan Cordoba
  NGN: "₦",   // Nigerian Naira
  NOK: "kr",  // Norwegian Krone
  OMR: "﷼",   // Omani Rial
  PKR: "₨",   // Pakistani Rupee
  PAB: "B/.", // Panamanian Balboa
  PYG: "Gs",  // Paraguayan Guarani
  PEN: "S/.", // Peruvian Sol
  PHP: "₱",   // Philippine Peso
  PLN: "zł",  // Polish Zloty
  QAR: "﷼",   // Qatari Riyal
  RON: "lei", // Romanian Leu
  SHP: "£",   // Saint Helena Pound
  RSD: "Дин.",// Serbian Dinar
  SCR: "₨",   // Seychellois Rupee
  SBD: "$",   // Solomon Islands Dollar
  SOS: "S",   // Somali Shilling
  LKR: "₨",   // Sri Lankan Rupee
  SRD: "$",   // Surinamese Dollar
  SYP: "£",   // Syrian Pound
  TWD: "NT$", // Taiwan New Dollar
  THB: "฿",   // Thai Baht
  TTD: "TT$", // Trinidad and Tobago Dollar
  TVD: "$",   // Tuvaluan Dollar
  UAH: "₴",   // Ukrainian Hryvnia
  UYU: "$U",  // Uruguayan Peso
  UZS: "лв",  // Uzbekistani Som
  VEF: "Bs",  // Venezuelan Bolívar
  VND: "₫",   // Vietnamese Dong
  YER: "﷼",   // Yemeni Rial
  ZWD: "Z$"   // Zimbabwe Dollar
};


export const perPerPeriod = [
  {
    label: 'Hourly',
    value: 'hour',
  },
  {
    label: 'Weekly',
    value: 'week',
  },
  {
    label: 'Monthly',
    value: 'month',
  },
  {
    label: 'Yearly',
    value: 'year',
  },
];

export const toTitleCase = (str, splitOn = ' ', joinBy = ' ') => {
  return str?.length > 0 ? str
    .toLowerCase()
    .split(splitOn)
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(joinBy) : '';
};


export const getFullName = (obj) =>
obj? `${obj?.firstName ?? ''}${obj?.lastName ? ' ' + obj?.lastName : ''}`:'';