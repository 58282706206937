import React from 'react';

const SectionIcon = ({ size = 16, ...props}) => {

  return (
<svg width={size} height={size} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M2.33301 14.6666H14.333" stroke="#76999C" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M2.33301 1.33337H14.333" stroke="#76999C" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M8.33301 4V12" stroke="#76999C" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10.2196 5.14668L8.33296 3.26001L6.44629 5.14668" stroke="#76999C" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10.2196 10.5934L8.33296 12.48L6.44629 10.5934" stroke="#76999C" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    

  );
};

export default SectionIcon;
