import React from 'react';
import { useState } from 'react';
import {
  useGetJobOfferQuery,
  useRejectOfferLetterMutation,
} from '../offersApiSlice';
import JobOffer from './JobOffer';
import JobOfferAcception from './JobOfferAcception';
import JobOfferRejection from './JobOfferRejection';
import { useQuery } from '../../../utils/helper';
import { useEffect } from 'react';
const JobOfferContainer = () => {
  const [offerLetter, setOfferLetter] = useState('');
  const query = useQuery();
  const token = query?.get('token');
  const { data: jobOffer, refetch, isLoading: responceLoading } = useGetJobOfferQuery(token);
  const [rejectOffer, { isLoading }] = useRejectOfferLetterMutation();

  useEffect(() => {
    if (offerLetter === '') refetch();
  }, [offerLetter]);

  return offerLetter === 'Reject' ? (
    <JobOfferRejection
      rejectOffer={rejectOffer}
      token={token}
      jobOffer={jobOffer}
      isLoading={isLoading}
      setOfferLetter={setOfferLetter}
    />
  ) : offerLetter === 'Continue' ? (
    <JobOfferAcception
      jobOffer={jobOffer}
      title='Job Offer'
      description={'Please find the details of our job offer below. We can’t wait for you to join the team'}
      responceLoading={responceLoading}
      rejectOffer={rejectOffer}
      token={token}
      setOfferLetter={setOfferLetter}
      isLoading={isLoading}
    />
  ) : (
    <JobOffer
      setOfferLetter={setOfferLetter}
      jobOffer={jobOffer}
      token={token}
      rejectOffer={rejectOffer}
      isLoading={isLoading}
      offerLetter={offerLetter}
      responceLoading={responceLoading}
    />
  );
};

export default JobOfferContainer;
