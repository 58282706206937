import { StyledLabel } from "../../assets/styledComponents/Global";
import theme from "../../utils/themes";

export const WelcomeSidebarMenu = [
    {
        id: 1,
        name: "occy",
        tick: false,
        content: <div className="d-flex">
            <div className="d-flex flex-column">
                <StyledLabel weight='700' size='36px' color={theme?.headingColor}>Welcome to occy!</StyledLabel>
                <StyledLabel weight='600' size='16px' color={theme?.primaryDark} className={'mt-2'} >We're excited to help you find the best talent for your team!</StyledLabel>
                <StyledLabel weight='500' size='16px' color={theme?.textColor} className="mt-3">Our comprehensive setup guide will walk you through the process of configuring a <br /> few essentials you need to kick-start your hiring process🎉</StyledLabel>
                <StyledLabel weight='500' size='16px' color={theme?.textColor} className="mt-3">In <strong> 4 simple steps,</strong> we'll  get started on couple of essentials to get you up and running.</StyledLabel>
                <ul className="text-primary fw-bold mt-3 fs-5 lh-lg">
                    <li>Setting up your company details</li>
                    <li>Configuring job settings</li>
                    <li>Creating reusable templates</li>
                    <li>Updating Account Information</li>
                </ul>
                <StyledLabel weight='500' size='16px' color={theme?.textColor}>Get ready for a hassle-free setup to meet your hiring needs!</StyledLabel>
            </div>
        </div >
    },
    {

        id: 2,
        name: "Company Setup",
        tick: false,
        content: <div className="d-flex">
            <div className="d-flex flex-column">
                <StyledLabel weight='600' size='40px' color={theme?.headingColor}>Company Setup</StyledLabel>
                <StyledLabel weight='500' size='16px' color={theme?.headingColor} className={'mt-2'} >Get your company set up on occy.</StyledLabel>
                <StyledLabel weight='500' size='16px' color={theme?.headingColor} className="mt-5">
                    Start by completing your <span className="fw-bold" color={theme?.headingColor}>Company Profile.</span > Streamline work and responsibilities <br />
                    by <span className="fw-bold" color={theme?.headingColor} >Inviting Team Members.</span> Ensure consistency and compliance in your hiring <br />
                    decisions by <span className="fw-bold" color={theme?.headingColor} > Adding Approvers.</span> Enhance your organization's structure by <span color={theme?.headingColor} className="fw-bold"> Adding <br />Departments.</span> </StyledLabel>

            </div>
        </div >
    },
    {
        id: 3,
        name: "Job Settings",
        tick: false,
        content: <div className="d-flex flex-column">
            <StyledLabel weight='600' size='40px' color={theme?.headingColor}>Job Settings</StyledLabel>
            <StyledLabel weight='500' size='16px' color={theme?.textColor} className={'mt-2'} >Optimise your hiring process with our Job Setup feature!</StyledLabel>
            <StyledLabel weight='500' size='16px' color={theme?.textColor} className="mt-4">
                Accelerate candidate screening by <span className="fw-bold" color={theme?.headingColor}>
                    Configuring Rejection Reasons</span>  to filter out <br />
                applicants. Tailor your candidate evaluation process by <span className="fw-bold" color={theme?.textColor}> Adding Skills.</span> Keep track <br />
                of where your candidates are coming from by <span className="fw-bold" color={theme?.textColor}> Adding Candidate Sources.</span> <br />
                Effortlessly create and customise <span className="fw-bold" color={theme?.textColor}>Form Templates </span> for various stages of the hiring <br />
                process. Streamline your hiring workflow by <span className="fw-bold" color={theme?.textColor}>Creating Pipeline Templates.</span></StyledLabel>

        </div>
    },
    {
        id: 4,
        name: "Communications",
        tick: false,
        content: <div className="d-flex flex-column">
            <StyledLabel weight='600' size='40px' color={theme?.headingColor}>Communications</StyledLabel>
            <StyledLabel weight='500' size='16px' color={theme?.textColor} className={'mt-2'} >Experience seamless candidate engagement with our communications setup!</StyledLabel>
            <StyledLabel weight='500' size='16px' color={theme?.textColor} className="mt-4">
                Save time by creating personalised <span className='fw-bold' color={theme?.headingColor}>Email Templates.</span> Craft pre-defined <span className="fw-bold" color={theme?.headingColor}>SMS <br /> Templates </span>
                for quick communication on mobile devices.</StyledLabel>
        </div>
    },
    {
        id: 5,
        name: "Your Account",
        tick: false,
        content:
            <div className="d-flex flex-column">
                <StyledLabel weight='600' size='40px' color={theme?.headingColor}>Your Account</StyledLabel>
                <StyledLabel weight='500' size='16px' color={theme?.textColor} className={'mt-2'} >With your account setup, you're ready to make the most of our platform's <br /> features.</StyledLabel>
                <StyledLabel weight='500' size='16px' color={theme?.textColor} className="mt-4">
                    <span className='fw-bold' color={theme?.textColor}>Connect Your Calendar</span> to keep track of important interview schedules and <br />
                    recruitment events seamlessly. Personalise your <span className="fw-bold">Notification Settings</span> to receive <br />
                    timely alerts about interview invitations, application statuses, and other important <br /> communications.</StyledLabel>
            </div >
    },
]