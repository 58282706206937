import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PortletBody from '../../../app/components/portlet/PortletBody';
import BriefCaseColorIcon from '../../../app/components/svgIcons/BriefCaseColorIcon';
import Calender365Icon from '../../../app/components/svgIcons/Calender365Icon';
import ClockFillIcon from '../../../app/components/svgIcons/ClockFillIcon';
import FillCalendarIcon from '../../../app/components/svgIcons/FillCalendarIcon';
import GlobalIcon from '../../../app/components/svgIcons/GlobalIcon';
import LocationIcon from '../../../app/components/svgIcons/LocationIcon';
import MeetIcon from '../../../app/components/svgIcons/MeetIcon';
import { StyledLabel, Wrap } from '../../../assets/styledComponents/Global';
import theme from '../../../utils/themes';
import { getAdvertRedirectUrl } from '../../../utils/helper';

const CandidateInterviewConfirm = ({
  confirmedSlot,
  interviewSlots,
  interviewData,
}) => {
  const dateFilter = (date) => {
    let convertedDate = moment(date)?.format('DD MMM YYYY');
    return convertedDate;
  };
  const timeFilter = (time) => {
    let convertedTime = moment(time)?.format('HH:mm');
    return convertedTime;
  };

  const convertIsoToLocal = (date, formate, duration) =>
    duration
      ? moment?.utc(date)?.local()?.add(duration, 'minutes')?.format(formate)
      : moment?.utc(date)?.local()?.format(formate);

  return (
    <Wrap className="justify-content-center d-flex">
      <Wrap className="col-lg-6 col-md-6 col-sm  mt-md-5 mt-lg-5">
        <StyledPortlet className="p-4 p-md-5 p-lg-5 mt-0 mt-md-5 mt-lg-5">
          <Wrap className="border-bottom pb-4 flex-column d-flex align-items-center">
            <StyledLabel size="20px">Confirmed</StyledLabel>
            <StyledLabel weight="500">
              Your meeting is scheduled with{' '}
              {interviewSlots?.interviewDetails?.interviewAccounts?.length
                ? `${interviewSlots?.interviewDetails?.interviewAccounts?.map((interview) => `${interview?.account?.firstName + ' ' + interview?.account?.lastName}`) ?? ''}`
                : confirmedSlot?.data?.name}
            </StyledLabel>
          </Wrap>

          <Wrap className="border-bottom pb-4">
            <Wrap className="my-4">
              <StyledLabel size="24px">
                {interviewSlots?.interviewDetails?.title ??
                  confirmedSlot?.data?.title}
              </StyledLabel>
            </Wrap>
            <Wrap>
              <Link
                className="d-flex align-items-center gap-2 "
                to={getAdvertRedirectUrl(interviewSlots?.interviewDetails?.application?.job?.title, interviewSlots?.interviewDetails?.application?.applicationSourceTracking?.postingId) }
                target="_blank"
              >
                <BriefCaseColorIcon />
                <WrapText className="cursor-pointer" color={theme.primary}>
                  {interviewSlots?.interviewDetails?.application?.job?.title ??
                    confirmedSlot?.data?.role}{' '}
                </WrapText>
              </Link>
              <Wrap className="align-items-center gap-3 flex-wrap">
                <Wrap className="d-flex  gap-2 mt-2 mt-md-3">
                  {interviewData?.interviewDetails?.type?.includes(
                    'Microsoft',
                  ) ? (
                    <Calender365Icon size="17px" />
                  ) : interviewData?.interviewDetails?.type?.includes(
                    'Google',
                  ) ? (
                    <MeetIcon />
                  ) : (
                    <LocationIcon color={theme.formSettingCardDesc} />
                  )}
                  <StyledLabel color={theme.textColor}>
                    {interviewSlots?.interviewDetails?.type ??
                      confirmedSlot?.data?.interviewType}
                    {interviewSlots?.interviewDetails?.location
                      ? ` (${[interviewSlots?.interviewDetails?.location?.postcode,
                      interviewSlots?.interviewDetails?.location?.buildingNo,
                      interviewSlots?.interviewDetails?.location?.town,
                      interviewSlots?.interviewDetails?.location?.country,
                      interviewSlots?.interviewDetails?.location?.county,
                      interviewSlots?.interviewDetails?.location?.address
                      ].filter(Boolean).join(', ')})`
                      : confirmedSlot?.data?.location
                        ? ` (${[
                          confirmedSlot?.data?.location?.postcode,
                          confirmedSlot?.data?.location?.buildingNo,
                          confirmedSlot?.data?.location?.town,
                          confirmedSlot?.data?.location?.country,
                          confirmedSlot?.data?.location?.county,
                          interviewSlots?.interviewDetails?.location?.address,
                        ].join(', ')})`
                        : null}{' '}
                  </StyledLabel>
                </Wrap>
                <Wrap className="d-flex align-items-center gap-2 mt-2 mt-md-3">
                  <FillCalendarIcon
                    color={theme.formSettingCardDesc}
                    size="16"
                  />
                  <StyledLabel color={theme.textColor}>
                    {interviewSlots?.interviewDetails?.selectedDate
                      ? convertIsoToLocal(
                        interviewSlots?.interviewDetails?.selectedDate,
                        'DD MMM YYYY',
                      )
                      : dateFilter(
                        confirmedSlot?.data?.dataValues?.scheduledAt,
                      )}{' '}
                  </StyledLabel>
                </Wrap>
                <Wrap className="d-flex align-items-center gap-2 mt-2 mt-md-3">
                  <ClockFillIcon color={theme.formSettingCardDesc} size="16" />
                  <StyledLabel color={theme.textColor}>
                    {interviewSlots?.interviewDetails?.selectedDate
                      ? `${convertIsoToLocal(
                        interviewSlots?.interviewDetails?.selectedDate,
                        'HH:mm',
                      )} - ${convertIsoToLocal(
                        interviewSlots?.interviewDetails?.selectedDate,
                        'HH:mm',
                        interviewSlots?.interviewDetails?.duration,
                      )}`
                      : `${timeFilter(
                        confirmedSlot?.data?.dataValues?.scheduledAt,
                      )} - ${timeFilter(
                        confirmedSlot?.data?.dataValues?.expiresAt,
                      )}`}
                  </StyledLabel>
                </Wrap>
              </Wrap>
              <Wrap className="d-flex align-items-center gap-2 mt-1 mt-md-2">
                <GlobalIcon color={theme.formSettingCardDesc} size="16" />
                <StyledLabel color={theme.textColor}>
                  {`${moment.tz.guess()} (${moment(
                    interviewSlots?.interviewDetails?.selectedDate,
                  ).format('h:mm a')})`}
                </StyledLabel>
              </Wrap>
            </Wrap>
          </Wrap>
          <Wrap className="d-flex align-items-center justify-content-center pt-4">
            <StyledLabel>
              A calendar invite has been sent to your email address.
            </StyledLabel>
          </Wrap>
        </StyledPortlet>
      </Wrap>
    </Wrap>
  );
};

const StyledPortlet = styled(PortletBody)`
  ${(props) => props.theme.md} {
    height: 100vh;
  }
`;

const WrapText = styled(StyledLabel)`
  whitespace: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.theme.xs} {
    width: 200px;
  }
`;

export default CandidateInterviewConfirm;
