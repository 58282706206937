import { createSlice } from '@reduxjs/toolkit';
import { occyLs } from '../../../utils/localStorage';
import { isObject } from 'lodash';

const lsJobData = occyLs.getObject('job_data') || {};
const lsActiveTab = occyLs.getObject('active_tab') || 0;
const lsJobTemplate = occyLs.getObject('job_Template') || 0;

const slice = createSlice({
  name: 'jobCreation',
  initialState: {
    job: { ...lsJobData },
    jobTemplate: { ...lsJobTemplate },
    activeTab: lsActiveTab,
    history:''
  },
  reducers: {
    setJobData: (state, { payload: { values } }) => {
      state.job = { ...values };
      occyLs.setObject('job_data', { ...values });
    },
    resetJobData: (state) => {
      state.job = {};
      occyLs.setObject('job_data', {});
    },

    resetJobInfo: (state) => {
      state.job = {};
      state.jobData = {};
      occyLs.setObject('job_data', {});
    },
    setPreviousUrl: (state, { payload: { history } }) => {
      if (history) {
        state.history = { ...history };
      }
    },
    setFormData: (state, { payload: { data } }) => {
      if (!isObject(state.jobData)) {
        state.jobData = {};
      }
      if (data) {
        state.jobData = { ...data };
      }
    },
  },
});

export const { setJobData, setActiveTab, resetJobData,resetJobInfo, setFormData, setPreviousUrl } =
  slice.actions;

export default slice.reducer;

export const getJobCreationState = (state) => state.createJob.job;
export const getJobCreationFormState = (state) => state.createJob.jobData;
export const getUrlHistory = (state) => state.createJob.history;
