import React from 'react';
import './assets/styles/auth.scss';

export const ClearStorage = () => {
    function removeCookies() {
        var res = document.cookie;
        var multiple = res.split(";");
        for(var i = 0; i < multiple.length; i++) {
           var key = multiple[i].split("=");
           document.cookie = key[0]+" =; expires = Thu, 01 Jan 2000 00:00:00 UTC";
        }
     }
 localStorage.clear();
 sessionStorage.clear();
 removeCookies();
  window.location.href = '/';
  return <i>processing...</i>;
};
