import React from 'react';
import { useState } from 'react';
import StyledDot from '../../../../app/components/styledComponents/StyledDot';
import DropDownIcon from '../../../../app/components/svgIcons/DropDownIcon';
import { StyledLabel, Wrap } from '../../../../assets/styledComponents/Global';
import theme from '../../../../utils/themes';

const QuestionTile = ({ Information }) => {
  const [showCurrenInfo, setShowCurrenInfo] = useState('');

  const handleVisible = (item) => {
    setShowCurrenInfo(showCurrenInfo === item?.id ? '' : item?.id);
  };

  return Information?.map((item, ind) => (
    <>
      <Wrap
        key={ind}
        className="d-flex justify-content-between mt-3 cursor-pointer"
        onClick={() => handleVisible(item)}
      >
        <StyledLabel size="16px" className="cursor-pointer">
          {item?.question}
        </StyledLabel>
        <DropDownIcon rotate={showCurrenInfo === item?.id ? "up" : "down"} />
      </Wrap>
      {showCurrenInfo === item?.id ? (
        <>
          <StyledLabel
            color={theme?.textColor}
            className="mt-3"
            size="16px"
            weight="500"
          >
            {item?.title}
          </StyledLabel>
          {item?.data?.map((data, ind) => (
            <Wrap className="d-flex align-items-center gap-2 ms-2" key={ind}>
              <StyledDot $size="2px" $padding='3px' className='mt-0' color={theme?.textColor} />
              <StyledLabel color={theme?.textColor} size="16px" weight="500">
                {data}
              </StyledLabel>
            </Wrap>
          ))}
        </>
      ) : null}
      <hr />
    </>
  ));
};

export default QuestionTile;
