import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import './assets/styles/auth.scss';
import useLicence from '../../hooks/useLicence';
import { isApprover } from '../users/creation/assets/utills';

export const Login = () => {
  const { user } = useAuth();
  const { licence } = useLicence();

  const cognitoUrl = new URL(process.env.REACT_APP_COGNITO_URL + '/login');
  cognitoUrl.searchParams.set(
    'client_id',
    process.env.REACT_APP_COGNITO_CLIENT_ID,
  );
  cognitoUrl.searchParams.set(
    'redirect_uri',
    `${window.location.origin}/auth/cb`,
  );
  cognitoUrl.searchParams.set('response_type', 'code');
  cognitoUrl.searchParams.set(
    'scope',
    'email openid aws.cognito.signin.user.admin',
  );
  if (user) {
    if (licence?.data)
      return licence?.data?.accepted === 1 ? (
        <Navigate to={ isApprover(user) ? "/approvers" : "/dashboard"} />
      ) : (
        <Navigate to="/privacy-policy" />
      );
  }
  else {
    window.location.href = cognitoUrl.toString();
  }
  return !user ? (
    ''
  ) : null;
};