import React from 'react';
import './assets/styles/auth.scss';
import { useDispatch } from 'react-redux';
import { removeCredentials, removeLicence } from './authSlice';

export const Logout = () => {
  const dispatch = useDispatch();
  dispatch(removeCredentials());
  dispatch(removeLicence());
  window.location.reload();
  window.location.href = '/';
  return <i>processing...</i>;
};
