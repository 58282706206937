import React from 'react';

const OccyIcon = ({ size=40, color='#C4994A', ...props }) => (
  <svg width={size+4} height={size} viewBox="0 0 44 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <path d="M18.1468 39.6118C19.304 39.8465 20.4976 39.9795 21.7184 39.998C30.2205 40.1181 37.509 34.7659 40.3993 27.1829H40.9407C41.8563 27.1829 42.6775 26.6266 43.0408 25.7727C43.5095 24.6675 44 22.823 44 19.9288C44 17.0346 43.5113 15.1901 43.0408 14.0849C42.6775 13.2311 41.8545 12.6748 40.9407 12.6748H40.4012C37.8233 5.90867 31.7447 0.920483 24.4271 0.00749134C24.0147 -0.0442572 23.6096 0.175674 23.4297 0.556395C23.1736 1.09791 22.6304 1.47124 22 1.47124C21.3696 1.47124 20.8264 1.09791 20.5703 0.556395C20.3904 0.175674 19.9853 -0.0442572 19.5729 0.00749134C12.2571 0.920483 6.17853 5.90867 3.60066 12.6729H3.05929C2.14368 12.6729 1.32254 13.2292 0.959207 14.0831C0.490504 15.1883 0 17.0328 0 19.927C0 22.8212 0.488687 24.6657 0.959207 25.7709C1.32254 26.6247 2.1455 27.181 3.05929 27.181H3.61519C6.03501 33.4703 11.4941 38.257 18.145 39.6099H18.1468V39.6118Z" fill={color}/>
  <path d="M22.0305 35.6145C30.0642 35.6145 36.5767 30.1533 36.5767 23.4166C36.5767 16.6799 30.0642 11.2188 22.0305 11.2188C13.9969 11.2188 7.48438 16.6799 7.48438 23.4166C7.48438 30.1533 13.9969 35.6145 22.0305 35.6145Z" fill="white"/>
  <path d="M14.6286 20.5352C12.6865 20.5352 11.1133 22.1357 11.1133 24.1113C11.1133 26.087 12.6865 27.6875 14.6286 27.6875C16.5706 27.6875 18.1438 26.087 18.1438 24.1113C18.1438 22.1357 16.5706 20.5352 14.6286 20.5352ZM15.8185 23.7657C15.3171 23.7657 14.912 23.3518 14.912 22.8435C14.912 22.3353 15.3189 21.9213 15.8185 21.9213C16.3181 21.9213 16.725 22.3353 16.725 22.8435C16.725 23.3518 16.3181 23.7657 15.8185 23.7657Z" fill={color}/>
  <path d="M29.7106 20.5352C27.7686 20.5352 26.1953 22.1357 26.1953 24.1113C26.1953 26.087 27.7686 27.6875 29.7106 27.6875C31.6526 27.6875 33.2259 26.087 33.2259 24.1113C33.2259 22.1357 31.6526 20.5352 29.7106 20.5352ZM31.015 23.7657C30.5136 23.7657 30.1084 23.3518 30.1084 22.8435C30.1084 22.3353 30.5154 21.9213 31.015 21.9213C31.5146 21.9213 31.9215 22.3353 31.9215 22.8435C31.9215 23.3518 31.5146 23.7657 31.015 23.7657Z" fill={color}/>
  </svg>    
);


export default OccyIcon;
