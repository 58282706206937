import React from 'react';

const StandardUserIcon = ({ size = 44 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 45 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.333008" width="44" height="44" rx="22" fill="#FCEDE3" />
    <path
      d="M19.333 12C16.713 12 14.583 14.13 14.583 16.75C14.583 19.32 16.593 21.4 19.213 21.49C19.293 21.48 19.373 21.48 19.433 21.49C19.453 21.49 19.463 21.49 19.483 21.49C19.493 21.49 19.493 21.49 19.503 21.49C22.063 21.4 24.073 19.32 24.083 16.75C24.083 14.13 21.953 12 19.333 12Z"
      fill="#C67E4F"
    />
    <path
      d="M24.413 24.15C21.623 22.29 17.073 22.29 14.263 24.15C12.993 25 12.293 26.15 12.293 27.38C12.293 28.61 12.993 29.75 14.253 30.59C15.653 31.53 17.493 32 19.333 32C21.173 32 23.013 31.53 24.413 30.59C25.673 29.74 26.373 28.6 26.373 27.36C26.363 26.13 25.673 24.99 24.413 24.15Z"
      fill="#C67E4F"
    />
    <path
      d="M30.3234 17.34C30.4834 19.28 29.1034 20.98 27.1934 21.21C27.1834 21.21 27.1834 21.21 27.1734 21.21H27.1434C27.0834 21.21 27.0234 21.21 26.9734 21.23C26.0034 21.28 25.1134 20.97 24.4434 20.4C25.4734 19.48 26.0634 18.1 25.9434 16.6C25.8734 15.79 25.5934 15.05 25.1734 14.42C25.5534 14.23 25.9934 14.11 26.4434 14.07C28.4034 13.9 30.1534 15.36 30.3234 17.34Z"
      fill="#C67E4F"
    />
    <path
      d="M32.3232 26.59C32.2432 27.56 31.6232 28.4 30.5832 28.97C29.5832 29.52 28.3232 29.78 27.0732 29.75C27.7932 29.1 28.2132 28.29 28.2932 27.43C28.3932 26.19 27.8032 25 26.6232 24.05C25.9532 23.52 25.1732 23.1 24.3232 22.79C26.5332 22.15 29.3132 22.58 31.0232 23.96C31.9432 24.7 32.4132 25.63 32.3232 26.59Z"
      fill="#C67E4F"
    />
  </svg>
);

export default StandardUserIcon;
