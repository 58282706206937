import { Modal } from 'react-bootstrap';
import React from 'react';
import PortletBody from '../../../../app/components/portlet/PortletBody';
import StyledButton from '../../../../app/components/styledComponents/StyledButton';

const DeleteCompanyModal = ({ show, onHide, onSubmit, title, description, pipeline, edit, loading, submitButtonLoading='' }) => {
  return (
    <Modal show={show} backdrop="static" centered onHide={onHide} size={'lg'}>
      <Modal.Header
        closeButton
        className="border-bottom-0 fs-5 align-items-center mb-2 px-4 "
      >
        <Modal.Title as="h4" className="pt-2 fs-2 cursor-pointer">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column bg-body rounded">
        <PortletBody className={`p-0 w-100 ${pipeline && 'bg-body'} `}>
          <div className="p-1 p-lg-2">
            <div className="p-2 fs-5">
              {description}
            </div>
          </div>
        </PortletBody>
        <div className="d-flex justify-content-end mt-2">
          <div className="d-flex gap-3">
            <StyledButton
              variant={'transparent'}
              className="mb-2 border-0 text-black fw-bold"
              onClick={onHide}
              disabled={loading}

            >
              Cancel
            </StyledButton>
            {edit ?
              <StyledButton
                variant={'primary'}
                onClick={onSubmit}
                type="submit"
                loading={loading}
                disabled={loading}
              >
                Save
              </StyledButton>
              : <StyledButton
                onClick={onSubmit}
                disabled={loading}
                loading={loading}
                className="mb-1 bg-danger fw-bold border-0"
                type="submit"
              >
                {submitButtonLoading ? submitButtonLoading:'Delete'}
              </StyledButton>}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteCompanyModal;
