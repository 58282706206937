import React from 'react';

const WelcomeOccyIcon = ({ size=40, color='#C4994A', ...props }) => (
<svg width="271" height="170" viewBox="0 0 271 170" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clipPath="url(#clip0_424_60897)">
<path d="M61.8025 165.195C61.8025 165.195 60.2382 168.927 75.4267 168.301C76.8094 168.241 77.7383 166.843 77.2792 165.525C76.1794 162.371 73.6969 157.542 68.8174 157.807C61.6049 158.196 61.7971 165.195 61.7971 165.195H61.8025Z" fill="url(#paint0_linear_424_60897)"/>
<path d="M100.71 165.865C100.71 165.865 99.1455 169.597 114.334 168.971C115.717 168.911 116.646 167.512 116.186 166.195C115.087 163.04 112.604 158.212 107.725 158.477C100.512 158.866 100.704 165.865 100.704 165.865H100.71Z" fill="url(#paint1_linear_424_60897)"/>
<path d="M169.972 166.825C169.972 166.825 171.536 170.557 156.348 169.93C154.965 169.871 154.036 168.472 154.495 167.154C155.595 164 158.078 159.172 162.957 159.436C170.17 159.825 169.978 166.825 169.978 166.825H169.972Z" fill="url(#paint2_linear_424_60897)"/>
<path d="M214.279 166.825C214.279 166.825 215.843 170.557 200.655 169.93C199.272 169.871 198.343 168.472 198.802 167.154C199.902 164 202.384 159.172 207.264 159.436C214.476 159.825 214.284 166.825 214.284 166.825H214.279Z" fill="url(#paint3_linear_424_60897)"/>
<path d="M70.3965 95.4716C70.3965 95.4716 80.4331 103.908 93.6356 92.9926L70.3965 95.4716Z" fill="#BA157F"/>
<path d="M128.509 114.112C118.077 120.485 107.49 117.309 103.225 115.543C101.97 115.025 101.26 114.625 101.26 114.625C101.26 114.625 118.579 169.234 104.01 163.855C89.4406 158.47 82.9381 146.825 82.9381 146.825C71.2358 162.024 62.3043 175.159 61.0337 148.629C60.4998 137.579 61.2099 126.534 62.2135 117.401C60.8468 117.844 59.368 118.308 57.8252 118.778H57.8198C44.3665 122.861 26.1511 127.15 35.5684 116.942C40.7522 111.32 46.4592 106.961 51.28 103.823L51.3014 103.812C57.6917 99.6482 62.5178 97.6228 62.5178 97.6228L65.6143 95.5651L96.4876 92.9888C96.4876 92.9888 101.549 98.8921 106.193 102.111C107.037 102.7 107.864 103.191 108.649 103.548C113.737 105.865 142.005 105.865 128.509 114.112Z" fill="#67A2FF"/>
<path d="M57.8412 118.763L57.8252 118.774H57.8199C44.3665 122.857 26.1511 127.145 35.5684 116.937C40.7522 111.315 46.4592 106.956 51.28 103.818L51.3014 103.808C56.592 109.306 57.8412 118.763 57.8412 118.763Z" fill="#BA157F"/>
<path d="M128.508 114.115C118.076 120.489 107.49 117.313 103.224 115.547C103.224 115.547 102.733 108.039 106.192 102.115C107.036 102.703 107.863 103.195 108.648 103.551C113.736 105.868 142.004 105.868 128.508 114.115Z" fill="#BA157F"/>
<path d="M125.662 63.741C131.91 62.7116 135.482 52.6026 133.64 41.1619C131.799 29.7213 125.241 21.2813 118.993 22.3107C112.745 23.3402 109.173 33.4492 111.014 44.8898C112.856 56.3305 119.414 64.7704 125.662 63.741Z" fill="#DE0287"/>
<path d="M93.635 92.9894C91.4942 93.5511 89.252 94.0264 86.903 94.4153C85.8513 94.5881 84.7996 94.7339 83.7532 94.869C54.4067 98.5308 28.5197 85.2823 24.2968 59.0823C20.1701 33.4387 38.9407 8.72399 66.8511 1.82699C76.9892 4.01437 77.8433 0.104087 77.8433 0.104087C105.017 -1.61881 122.33 16.9334 126.409 42.2584C130.44 67.3188 119.453 86.2653 93.635 92.9894Z" fill="url(#paint4_linear_424_60897)"/>
<path d="M93.6365 92.9963C91.4957 93.558 89.2534 94.0333 86.9044 94.4221C85.8527 94.595 84.801 94.7408 83.7547 94.8758C95.3555 74.2064 98.8416 55.0709 94.1436 37.9607C88.5594 17.6694 72.7464 5.69547 66.8525 1.8338C76.9906 4.02118 77.8448 0.110901 77.8448 0.110901C85.9168 6.75406 96.8824 18.4741 101.655 35.7301C106.529 53.3264 103.828 72.5537 93.6365 92.9963Z" fill="#BA157F"/>
<path d="M45.3525 55.6016C47.0929 66.4467 55.8109 74.8829 67.8389 79.0741C57.1936 74.6183 49.6128 66.6465 47.9952 56.617C45.0642 38.3996 62.9166 20.2956 87.8801 16.1855C96.6675 14.7327 104.398 15.343 110.858 17.5304C103.901 14.5328 95.2421 13.5175 85.2215 15.1647C60.2687 19.2749 42.4163 37.3788 45.3472 55.5962L45.3525 55.6016Z" fill="#AD93A4"/>
<path d="M47.9955 56.6143C49.6132 66.6385 57.194 74.6157 67.8393 79.0715C76.0234 81.9231 85.7397 82.8143 95.8511 81.1454C120.804 77.0353 132.517 59.9413 129.586 41.724C127.835 30.8357 121.247 21.9997 110.89 17.544C110.885 17.5385 110.869 17.5277 110.858 17.5277C104.393 15.3404 96.6625 14.7301 87.8805 16.1829C62.9224 20.293 45.0646 38.397 47.9955 56.6143Z" fill="url(#paint5_linear_424_60897)"/>
<path d="M47.9955 56.6143C49.6132 66.6385 57.194 74.6157 67.8393 79.0715C76.0234 81.9231 85.7397 82.8143 95.8511 81.1454C120.804 77.0353 132.517 59.9413 129.586 41.724C127.835 30.8357 121.247 21.9997 110.89 17.544C110.885 17.5385 110.869 17.5277 110.858 17.5277C104.393 15.3404 96.6625 14.7301 87.8805 16.1829C62.9224 20.293 45.0646 38.397 47.9955 56.6143Z" fill="url(#paint6_linear_424_60897)"/>
<path d="M116.736 59.8737C121.203 59.1377 124.168 54.4646 123.359 49.4362C122.549 44.4077 118.272 40.928 113.805 41.664C109.338 42.3999 106.373 47.073 107.183 52.1014C107.992 57.1299 112.269 60.6096 116.736 59.8737Z" fill="#ED018C"/>
<path d="M79.5139 65.8914C84.5498 65.8914 88.6323 61.7613 88.6323 56.6666C88.6323 51.5719 84.5498 47.4418 79.5139 47.4418C74.478 47.4418 70.3955 51.5719 70.3955 56.6666C70.3955 61.7613 74.478 65.8914 79.5139 65.8914Z" fill="#ED018C"/>
<path d="M119.828 55.1017C121.146 54.8845 122.021 53.5055 121.783 52.0217C121.544 50.5379 120.281 49.5111 118.963 49.7283C117.645 49.9455 116.77 51.3244 117.009 52.8083C117.248 54.2921 118.51 55.3189 119.828 55.1017Z" fill="white"/>
<path d="M82.9377 60.6175C84.4238 60.6175 85.6284 59.3988 85.6284 57.8954C85.6284 56.3921 84.4238 55.1733 82.9377 55.1733C81.4517 55.1733 80.2471 56.3921 80.2471 57.8954C80.2471 59.3988 81.4517 60.6175 82.9377 60.6175Z" fill="white"/>
<path d="M33.3516 78.9506C39.5996 77.9212 43.1716 67.8122 41.3299 56.3715C39.4882 44.9309 32.9301 36.4909 26.6821 37.5203C20.4341 38.5498 16.8622 48.6588 18.7039 60.0994C20.5456 71.5401 27.1037 79.98 33.3516 78.9506Z" fill="#BA157F"/>
<path d="M29.0283 76.6925C34.4062 75.8065 37.4806 67.1049 35.8953 57.257C34.31 47.409 28.6652 40.144 23.2873 41.0301C17.9094 41.9162 14.835 50.6178 16.4203 60.4657C18.0056 70.3136 23.6504 77.5786 29.0283 76.6925Z" fill="#DE0287"/>
<path d="M212.071 128.19C216.38 125.975 222.679 121.962 221.462 117.917C219.641 111.863 210.315 94.5473 210.315 94.5473L174.071 91.2905C174.071 91.2905 165.23 100.845 156.891 102.114C148.552 103.383 136.674 105.684 140.219 110.016C143.763 114.347 156.929 116.243 169.207 113.926C169.207 113.926 160.724 156.518 162.353 162.842C163.981 169.167 187.588 147.45 187.588 147.45C187.588 147.45 193.311 160.563 206.364 164.23C219.417 167.903 206.284 149.556 210.245 135.854C210.972 133.331 211.585 130.771 212.071 128.19Z" fill="url(#paint7_linear_424_60897)"/>
<path d="M197.797 108.32L202.964 117.923C202.964 117.923 181.754 125.354 186.527 128.617C191.299 131.879 206.071 129.951 206.071 129.951C206.071 129.951 210.332 129.41 213.044 128.001" stroke="white" strokeWidth="0.894055" strokeMiterlimit="10"/>
<path d="M159.989 44.8946C161.831 33.4539 158.259 23.3449 152.011 22.3155C145.763 21.2861 139.205 29.7261 137.363 41.1667C135.521 52.6074 139.093 62.7164 145.341 63.7458C151.589 64.7752 158.147 56.3352 159.989 44.8946Z" fill="url(#paint8_linear_424_60897)"/>
<path d="M177.364 92.9894C179.505 93.5511 181.747 94.0264 184.096 94.4153C185.148 94.5881 186.199 94.7339 187.246 94.869C216.592 98.5308 242.479 85.2823 246.702 59.0823C250.829 33.4387 232.058 8.72399 204.148 1.82699C194.01 4.01437 193.156 0.104087 193.156 0.104087C165.982 -1.61881 148.669 16.9334 144.59 42.2584C140.559 67.3188 151.546 86.2653 177.364 92.9894Z" fill="url(#paint9_linear_424_60897)"/>
<path d="M177.365 92.9963C179.506 93.558 181.748 94.0333 184.097 94.4221C185.148 94.595 186.2 94.7408 187.247 94.8758C175.646 74.2064 172.16 55.0709 176.858 37.9607C182.442 17.6694 198.255 5.69547 204.149 1.8338C194.011 4.02118 193.156 0.110901 193.156 0.110901C185.084 6.75406 174.119 18.4741 169.346 35.7301C164.472 53.3264 167.173 72.5537 177.365 92.9963Z" fill="#5A00A8"/>
<path d="M225.647 55.6016C223.906 66.4467 215.188 74.8829 203.16 79.0741C213.806 74.6183 221.386 66.6465 223.004 56.617C225.935 38.3996 208.083 20.2956 183.119 16.1855C174.332 14.7327 166.601 15.343 160.142 17.5304C167.098 14.5328 175.757 13.5175 185.778 15.1647C210.73 19.2749 228.583 37.3788 225.652 55.5962L225.647 55.6016Z" fill="#AD93A4"/>
<path d="M222.999 56.6143C221.382 66.6385 213.801 74.6157 203.156 79.0715C194.972 81.9231 185.255 82.8143 175.144 81.1454C150.191 77.0353 138.478 59.9413 141.409 41.724C143.16 30.8357 149.748 21.9997 160.105 17.544C160.11 17.5385 160.126 17.5277 160.137 17.5277C166.602 15.3404 174.333 14.7301 183.115 16.1829C208.073 20.293 225.93 38.397 222.999 56.6143Z" fill="url(#paint10_linear_424_60897)"/>
<path d="M222.999 56.6143C221.382 66.6385 213.801 74.6157 203.156 79.0715C194.972 81.9231 185.255 82.8143 175.144 81.1454C150.191 77.0353 138.478 59.9413 141.409 41.724C143.16 30.8357 149.748 21.9997 160.105 17.544C160.11 17.5385 160.126 17.5277 160.137 17.5277C166.602 15.3404 174.333 14.7301 183.115 16.1829C208.073 20.293 225.93 38.397 222.999 56.6143Z" fill="url(#paint11_linear_424_60897)"/>
<path d="M163.817 52.1106C164.626 47.0822 161.661 42.4091 157.194 41.6732C152.727 40.9372 148.45 44.4169 147.641 49.4454C146.831 54.4738 149.796 59.1469 154.263 59.8829C158.73 60.6188 163.008 57.1391 163.817 52.1106Z" fill="#5A00A8"/>
<path d="M191.48 65.8914C196.516 65.8914 200.598 61.7613 200.598 56.6666C200.598 51.5719 196.516 47.4418 191.48 47.4418C186.444 47.4418 182.361 51.5719 182.361 56.6666C182.361 61.7613 186.444 65.8914 191.48 65.8914Z" fill="#5A00A8"/>
<path d="M153.994 52.8025C154.232 51.3186 153.357 49.9397 152.039 49.7225C150.721 49.5053 149.458 50.5321 149.22 52.0159C148.981 53.4997 149.856 54.8787 151.174 55.0959C152.492 55.3131 153.755 54.2863 153.994 52.8025Z" fill="white"/>
<path d="M188.061 60.6175C189.547 60.6175 190.751 59.3988 190.751 57.8954C190.751 56.3921 189.547 55.1733 188.061 55.1733C186.575 55.1733 185.37 56.3921 185.37 57.8954C185.37 59.3988 186.575 60.6175 188.061 60.6175Z" fill="white"/>
<path d="M252.298 60.0861C254.139 48.6455 250.567 38.5365 244.319 37.507C238.071 36.4776 231.513 44.9176 229.672 56.3582C227.83 67.7989 231.402 77.9079 237.65 78.9373C243.898 79.9667 250.456 71.5268 252.298 60.0861Z" fill="#5A00A8"/>
<path d="M254.586 60.4772C256.171 50.6293 253.097 41.9277 247.719 41.0417C242.341 40.1556 236.696 47.4206 235.111 57.2685C233.525 67.1164 236.6 75.818 241.978 76.7041C247.356 77.5902 253 70.3251 254.586 60.4772Z" fill="#1D31CD"/>
<path d="M122.461 100.742C122.461 100.742 127.634 93.9963 122.856 88.4387C122.856 88.4387 131.206 95.4869 137.009 89.8916C137.009 89.8916 133.41 99.2622 140.089 102.848C140.089 102.848 135.081 104.323 135.412 108.73C135.412 108.73 134.345 100.305 122.461 100.742Z" fill="#FFDC78"/>
</g>
<defs>
<linearGradient id="paint0_linear_424_60897" x1="61.7544" y1="163.083" x2="77.3966" y2="163.083" gradientUnits="userSpaceOnUse">
<stop stopColor="#ED018C"/>
<stop offset="1" stopColor="#C70180"/>
</linearGradient>
<linearGradient id="paint1_linear_424_60897" x1="100.667" y1="163.753" x2="116.309" y2="163.753" gradientUnits="userSpaceOnUse">
<stop stopColor="#ED018C"/>
<stop offset="1" stopColor="#C70180"/>
</linearGradient>
<linearGradient id="paint2_linear_424_60897" x1="170.02" y1="164.713" x2="154.378" y2="164.713" gradientUnits="userSpaceOnUse">
<stop stopColor="#8000D4"/>
<stop offset="1" stopColor="#7A00D4"/>
</linearGradient>
<linearGradient id="paint3_linear_424_60897" x1="214.327" y1="164.713" x2="198.679" y2="164.713" gradientUnits="userSpaceOnUse">
<stop stopColor="#8000D4"/>
<stop offset="1" stopColor="#7A00D4"/>
</linearGradient>
<linearGradient id="paint4_linear_424_60897" x1="23.7256" y1="47.7296" x2="127.22" y2="47.7296" gradientUnits="userSpaceOnUse">
<stop stopColor="#ED018C"/>
<stop offset="1" stopColor="#C70180"/>
</linearGradient>
<linearGradient id="paint5_linear_424_60897" x1="130.029" y1="48.6912" x2="47.6859" y2="48.6912" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="#FFEEFF"/>
</linearGradient>
<linearGradient id="paint6_linear_424_60897" x1="72.6867" y1="20.3794" x2="106.855" y2="78.8793" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="0.46" stopColor="#FDFCFD"/>
<stop offset="0.79" stopColor="#F8F2FA"/>
<stop offset="1" stopColor="#F3E8F7"/>
</linearGradient>
<linearGradient id="paint7_linear_424_60897" x1="139.573" y1="128.001" x2="221.617" y2="128.001" gradientUnits="userSpaceOnUse">
<stop stopColor="#BE01ED"/>
<stop offset="1" stopColor="#0138C7"/>
</linearGradient>
<linearGradient id="paint8_linear_424_60897" x1="136.881" y1="43.0291" x2="160.467" y2="43.0291" gradientUnits="userSpaceOnUse">
<stop stopColor="#8000D4"/>
<stop offset="1" stopColor="#7A00D4"/>
</linearGradient>
<linearGradient id="paint9_linear_424_60897" x1="247.268" y1="47.7296" x2="143.779" y2="47.7296" gradientUnits="userSpaceOnUse">
<stop stopColor="#8000D4"/>
<stop offset="1" stopColor="#7A00D4"/>
</linearGradient>
<linearGradient id="paint10_linear_424_60897" x1="140.966" y1="48.6912" x2="223.314" y2="48.6912" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="1" stopColor="#FFEEFF"/>
</linearGradient>
<linearGradient id="paint11_linear_424_60897" x1="198.308" y1="20.3794" x2="164.14" y2="78.8793" gradientUnits="userSpaceOnUse">
<stop stopColor="white"/>
<stop offset="0.46" stopColor="#FDFCFD"/>
<stop offset="0.79" stopColor="#F8F2FA"/>
<stop offset="1" stopColor="#F3E8F7"/>
</linearGradient>
<clipPath id="clip0_424_60897">
<rect width="239" height="170" fill="white" transform="translate(16)"/>
</clipPath>
</defs>
</svg>

   
);


export default WelcomeOccyIcon;
