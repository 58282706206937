import React from 'react';

const ExportIcon = ({ size = 14, color = "#585558", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}

    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M8.66699 7.33337L14.1337 1.8667"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6668 4.53337V1.33337H11.4668"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.33301 1.33337H5.99967C2.66634 1.33337 1.33301 2.66671 1.33301 6.00004V10C1.33301 13.3334 2.66634 14.6667 5.99967 14.6667H9.99967C13.333 14.6667 14.6663 13.3334 14.6663 10V8.66671"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ExportIcon;
