import React from 'react';

const UniHatIcon = ({ size = 50, color = 'currentColor', ...props }) => {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_183_6325)">
        <path d="M22.0568 5.22985L14.6828 1.71685C13.0596 0.747292 11.0393 0.730089 9.39979 1.67185L1.94282 5.22985C1.91483 5.24387 1.88582 5.25887 1.85882 5.27485C0.0891931 6.2867 -0.525151 8.54153 0.486693 10.3112C0.829677 10.9111 1.33419 11.4026 1.94282 11.7299L3.99983 12.7099V17.6099C4.00105 19.8009 5.42676 21.7367 7.51883 22.3879C8.97472 22.8091 10.4843 23.0153 11.9998 22.9999C13.5151 23.0169 15.0247 22.8124 16.4809 22.3929C18.5729 21.7418 19.9986 19.806 19.9999 17.6149V12.7079L21.9999 11.7519V19.9998C21.9999 20.5521 22.4476 20.9998 22.9999 20.9998C23.5521 20.9998 23.9998 20.5521 23.9998 19.9998V7.99984C24.0065 6.82557 23.0793 5.74079 22.0568 5.22985ZM17.9998 17.6149C18.0003 18.9255 17.15 20.0847 15.8998 20.4779C14.632 20.8401 13.3183 21.0159 11.9998 20.9999C10.6813 21.0159 9.36758 20.8401 8.0998 20.4779C6.8496 20.0847 5.99929 18.9255 5.9998 17.6149V13.6629L9.31682 15.2429C10.1353 15.7289 11.07 15.9843 12.0218 15.9819C12.9278 15.9883 13.8186 15.7485 14.5988 15.2879L17.9998 13.6629V17.6149ZM21.1998 9.92486L13.6578 13.5249C12.6062 14.1372 11.3028 14.1198 10.2678 13.4799L2.8888 9.96985C2.06629 9.52632 1.75907 8.5 2.2026 7.67753C2.3526 7.39937 2.57751 7.16879 2.85182 7.01186L10.3468 3.43187C11.3987 2.8209 12.7015 2.8382 13.7368 3.47687L21.1108 6.98987C21.6531 7.291 21.9924 7.85964 21.9998 8.47989C22.0008 9.06742 21.6982 9.61379 21.1998 9.92486Z" fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_183_6325">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>


  );
};

export default UniHatIcon;
