import React, { useState } from 'react';
import { StyledLabel, Wrap } from '../../../assets/styledComponents/Global';
import theme from '../../../utils/themes';
import DropDownIcon from '../svgIcons/DropDownIcon';

function StyledAccordion({ title, children, isSmallScreen, className }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Wrap className="d-flex flex-column d-md-none d-sm-block">
        <Wrap
          className="d-flex justify-content-between align-items-center"
          onClick={() => setIsOpen(!isOpen)}
        >
          <StyledLabel size="16px" color={theme.headingColor}>{title}</StyledLabel>
          <DropDownIcon rotate={isOpen ? 'up' : 'down'} />
        </Wrap>

        {isOpen && (
          <>
            <hr />

            <StyledLabel size="12px" color={theme.textColor}>
              {children}
            </StyledLabel>
          </>
        )}
      </Wrap>
      <Wrap className="d-md-flex flex-md-column d-none d-md-block">
        <StyledLabel size="24px">{title}</StyledLabel>
        <StyledLabel size="16px" color={theme.textColor}>
          {children}
        </StyledLabel>
      </Wrap>
    </>
  );
}

export default StyledAccordion;
