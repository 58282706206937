import React from 'react';

const PeopleIcons = ({size = 20}) => (
 <svg width={size} height={size+1} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.6084 6.97484C14.55 6.9665 14.4917 6.9665 14.4334 6.97484C13.1417 6.93317 12.1167 5.87484 12.1167 4.57484C12.1167 3.24984 13.1917 2.1665 14.525 2.1665C15.85 2.1665 16.9334 3.2415 16.9334 4.57484C16.925 5.87484 15.9 6.93317 14.6084 6.97484Z" fill="#366294"/>
<path d="M17.3252 12.7498C16.3918 13.3748 15.0835 13.6082 13.8752 13.4498C14.1918 12.7665 14.3585 12.0082 14.3668 11.2082C14.3668 10.3748 14.1835 9.58318 13.8335 8.89151C15.0668 8.72485 16.3752 8.95818 17.3168 9.58318C18.6335 10.4498 18.6335 11.8748 17.3252 12.7498Z" fill="#366294"/>
<path d="M5.36647 6.97484C5.4248 6.9665 5.48314 6.9665 5.54147 6.97484C6.83314 6.93317 7.85814 5.87484 7.85814 4.57484C7.85814 3.2415 6.78314 2.1665 5.4498 2.1665C4.1248 2.1665 3.0498 3.2415 3.0498 4.57484C3.0498 5.87484 4.0748 6.93317 5.36647 6.97484Z" fill="#366294"/>
<path d="M5.45843 11.2083C5.45843 12.0167 5.63343 12.7833 5.9501 13.475C4.7751 13.6 3.5501 13.35 2.6501 12.7583C1.33343 11.8833 1.33343 10.4583 2.6501 9.58334C3.54176 8.98334 4.8001 8.74167 5.98343 8.87501C5.64176 9.57501 5.45843 10.3667 5.45843 11.2083Z" fill="#366294"/>
<path d="M10.1 13.725C10.0333 13.7167 9.95833 13.7167 9.88333 13.725C8.35 13.675 7.125 12.4167 7.125 10.8667C7.13333 9.28333 8.40833 8 10 8C11.5833 8 12.8667 9.28333 12.8667 10.8667C12.8583 12.4167 11.6417 13.675 10.1 13.725Z" fill="#366294"/>
<path d="M7.3915 15.4502C6.13317 16.2919 6.13317 17.6752 7.3915 18.5085C8.82484 19.4669 11.1748 19.4669 12.6082 18.5085C13.8665 17.6669 13.8665 16.2835 12.6082 15.4502C11.1832 14.4919 8.83317 14.4919 7.3915 15.4502Z" fill="#366294"/>
</svg>


);

export default PeopleIcons;
