import React from 'react';
import { Modal } from 'react-bootstrap';
import CrossIcon from '../../../app/components/svgIcons/CrossIcon';
import RejectedIcon from '../../../app/components/svgIcons/RejectedIcon';
import { StyledLabel, Wrap } from '../../../assets/styledComponents/Global';

const FeedBackModal = ({ show, onHide, setOfferLetter, jobOffer }) => {
  return (
    <Modal
      backdrop="static"
      show={show}
      centered
      onHide={onHide}
      size={'lg'}
      className="bg-black bg-opacity-50"
    >
      <Modal.Header className=" bg-body d-flex align-items-end justify-content-end border-0">
        <CrossIcon onClick={() => setOfferLetter('')} />
      </Modal.Header>
      <Modal.Body className="d-flex  flex-column bg-body rounded">
        <Wrap className="d-flex align-items-center justify-content-center flex-column">
          <RejectedIcon />
          <StyledLabel size="20px" className='my-3'>Offer Rejected</StyledLabel>
          <StyledLabel weight="500px" className='text-center'>
            We’re sad to see you go. We wish you all the best for your future
            endeavours.
          </StyledLabel>
        </Wrap>
      </Modal.Body>
    </Modal>
  );
};

export default FeedBackModal;
