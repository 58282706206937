import * as Yup from 'yup';
import { isValidEmail } from '../../../utils/helper';

export const publicPageSchema = {
    cv: Yup.object().required('Required'),
    firstName: Yup.string().required('Required'),
    email: Yup.string().required('Required').test('is-valid-email', 'Invalid email', (value) => isValidEmail(value)),
    // cv: Yup.mixed().required('CV is required'),
    isAgency: Yup.boolean(),
    agencySource: Yup.string().when('isAgency', {
      is: true,
      then: Yup.string().required('Required'),
      otherwise: Yup.string(),
    }),
    agencyEmail: Yup.string().when('isAgency', {
      is: true,
      then: Yup.string().required('Required').test('is-valid-email', 'Invalid email', (value) => isValidEmail(value)),
      otherwise: Yup.string(),
    }),
};
export const jobTypes = [
    {
        label: 'Permanent',
        value: 'permanent',
    },
    {
        label: 'Contract',
        value: 'contract',
    },
    {
        label: 'Temporary',
        value: 'temporary',
    }
];
