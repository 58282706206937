import React from 'react';
import { StyledLabel } from '../../../assets/styledComponents/Global';
import PortletHeaderTabs from './PortletHeaderTabs';
import theme from '../../../../src/utils/themes';

const PortletHeader = ({
  title = '',
  headerClassName = '',
  containerClassName = '',
  actions,
  subTitle,
  borderBottom,
  tabs,
  titleDetails,
  filters,
  onChangeTabs,
  breadCrumb,
  counts,
  count,
  tag,
  id,
  labelClassName = '',
  wizard,
  wizardTemp,
}) => (
  <div
    className={`portlet__header-container rounded ${wizard ? '_1-step' : ''} ${!tabs ? 'isHeadingOnly' : ''
      } ${containerClassName}`}
  >
    {counts && <div className="mb-1">{counts}</div>}
    <div className="flex-center flex-wrap">
      {title && (
        <h2 className="flex-grow-1 m-0 ">
          <span className={`${tag && 'd-flex align-items-center'} `}>
            <span>
              {title ?? ''}
            </span>
            {tag && <span>{tag}</span>}
          </span>
          {subTitle && <span className="fw-light fs-4">{subTitle}</span>}
          {count && <span>{' ' + count}</span>}
        </h2>
      )}
      {breadCrumb && <div className="flex-grow-1 ">{breadCrumb}</div>}
      {actions && <div>{actions}</div>}
    </div>

    {titleDetails && (
      <StyledLabel
        size="14px"
        weight="500"
        color={theme.borderColor}
        className={labelClassName}
      >
        {titleDetails}
      </StyledLabel>
    )}

    <div
      className={
        breadCrumb ? 'd-flex justify-content-center ' : 'flex-center flex-wrap'
      }
    >
      <div className={breadCrumb ? '' : 'flex-grow-1'}>
        {tabs && (
          <div
            className={`portlet__header pb-0 ${headerClassName} ${borderBottom ? 'portlet__header-border' : ''
              }`}
          >
            <div className={breadCrumb ? '' : 'flex-grow-1'}>
              {tabs && (
                <PortletHeaderTabs tabs={tabs} onChangeTabs={onChangeTabs} title={title} wizardTemp={wizardTemp} />
              )}
            </div>
          </div>
        )}
      </div>
      <div className="display-flex">{filters && <div>{filters}</div>}</div>
    </div>
  </div>
);

export default PortletHeader;
