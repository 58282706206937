import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'messageCreation',
  initialState: {
    composeMessageView: '',
    totalMessageCounts: '',
  },
  reducers: {
    setComposeMessageView: (state, { payload: { composeMessage } }) => {
      state.composeMessageView = { composeMessage };
    },
    setTotalMessageCounts: (state, { payload: { messageCounts } }) => {
      state.totalMessageCounts = messageCounts;
    },
  },
});

export const {
  setComposeMessageView,
  resetComposeMessageView,
  setTotalMessageCounts,
} = slice.actions;

export default slice.reducer;

export const getMessageCreationState = (state) =>
  state.createMessage.composeMessageView;
export const getMessageCounts = (state) => state.createMessage.totalMessageCounts;
