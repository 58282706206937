import React, { useEffect, useState } from 'react';
import { useGetPublicFormByTokenQuery } from '../formApiSlice';
import { useQuery } from '../../../utils/helper';
import PublicForm from './PublicForm';

const PublicFormContainer = () => {
  const query = useQuery();
  const [publicForm, setPublicForm] = useState({});
  const token = query?.get('token');

  let { data: _formData } = useGetPublicFormByTokenQuery(token, {
    skip: !token,
  });

  useEffect(() => {
    setPublicForm({
      ..._formData,
      compFormSetting: { ..._formData?.compFormSettingId },
    });
  }, [_formData]);

  return <PublicForm formData={publicForm} />;
};

export default PublicFormContainer;
