import React from 'react';
import CustomDropdown from '../dropdown/CustomDropDown';
import DropdownItem from 'react-bootstrap/DropdownItem';
import styled from 'styled-components';
import DropDownIcon from '../svgIcons/DropDownIcon';
import { handleErrorImg } from '../../../utils/helper';
import { useNavigate } from 'react-router-dom';

const StyledProfileDropDown = ({
  toggleClassName,
  menuItems,
  menuClassName,
  profile,
}) => {
  const navigate = useNavigate();

return(
  <ProfileDropDownStyle className="d-flex align-items-center gap-4">
    <div className={`account-img  cursor-pointer ${!profile?.image ? 'default' : ''}`} onClick={()=>navigate('settings/accounts/profile')}>
      {profile?.image ? (
        <img
          src={profile?.signedUrl}
          alt={profile?.firstName || 'image'}
          onError={handleErrorImg}
        />
      ) : (
        profile?.firstName?.charAt(0).toUpperCase()
      )}
    </div>
    <CustomDropdown
      toggleClassName={toggleClassName}
      menuClassName={menuClassName}
      dropdownToggle={
        <div className={`account-menu ${toggleClassName} `}>
          {/*<div className={`account-img default`}>*/}

          <h6>{profile?.firstName || ''}</h6>

          <DropDownIcon rotate="down" className="ms-1" />
        </div>
      }
      dropdownMenu={
        <span>
          {menuItems?.map((item, index) => (
            <DropdownItem onClick={item?.action} key={index}>
              <span className="dark-text">{item.icon}</span>
              <span className="ms-3">{item?.name}</span>
            </DropdownItem>
          ))}
        </span>
      }
    />
  </ProfileDropDownStyle>
)}
StyledProfileDropDown.defaultProps = {
  toggleClassName: '',
  menuItems: [],
  menuClassName: '',
  profile: {
    firstName: '',
  },
};
const ProfileDropDownStyle = styled.div`
  .menu-toggle {
    padding-block: 0;
  }

  .account-menu {
    display: flex;
    align-items: center;

    h6 {
      font-size: 14px;
      font-weight: 600;
      margin: 0;
    }
  }

  .account-img {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 8px;

    &.default {
      background-color: ${({ theme }) => theme.defaultImage};
      border-radius: 50%;
      color: ${({ theme }) => theme.white};
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
`;
export default StyledProfileDropDown;
