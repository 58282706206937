import React from 'react';

const FillBatteryIcon = ({ size = 20, color = 'currentColor' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5425 2.79395H4.2875C1.89583 2.79395 1.16667 3.52311 1.16667 5.91478V8.08478C1.16667 10.4764 1.89583 11.2056 4.2875 11.2056H7.5425C9.93417 11.2056 10.6633 10.4764 10.6633 8.08478V5.91478C10.6633 3.52311 9.93417 2.79395 7.5425 2.79395Z"
        fill={color}
      />
      <path
        d="M11.9117 5.24995C11.83 5.24411 11.725 5.23828 11.6083 5.23828C11.3808 5.23828 11.2 5.41912 11.2 5.64662V8.35911C11.2 8.58661 11.3808 8.76745 11.6083 8.76745C11.725 8.76745 11.8242 8.76161 11.9233 8.75578C12.8333 8.65078 12.8333 8.00911 12.8333 7.54245V6.45745C12.8333 5.99078 12.8333 5.34911 11.9117 5.24995Z"
        fill={color}
      />
    </svg>
  );
};

export default FillBatteryIcon;
