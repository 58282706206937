import React from 'react';

const DownloadIcon3 = ({
  size = 17,
  color = '#767476',
  rotate = 'down',
  ...props
}) => {
  const getRotateDeg = (val) => {
    switch (val) {
      case 'left':
        return '90';
      case 'up':
        return '180';
      case 'right':
        return '270';
      default:
        return '0';
    }
  };
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${getRotateDeg(rotate)}deg)` }}
      {...props}
    >
      <path
        d="M11.46 10.6582C13.86 10.4515 14.84 9.2182 14.84 6.5182V6.43154C14.84 3.45154 13.6467 2.2582 10.6667 2.2582H6.32665C3.34665 2.2582 2.15332 3.45154 2.15332 6.43154V6.5182C2.15332 9.1982 3.11999 10.4315 5.47999 10.6515"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 6.59107V14.1777"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7333 12.6924L8.5 14.9258L6.26666 12.6924"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownloadIcon3;
