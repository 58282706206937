import React from 'react';

const OfferAcceptedIcon = ({ size = 32, ...props }) => {
  return (
    <svg
      width="205"
      height="244"
      viewBox="0 0 205 244"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_648_51152)">
        <path
          d="M79.4549 200.216C75.3858 196.016 69.4891 193.606 63.2767 193.606L63.0371 193.607C58.9452 193.609 54.9263 192.522 51.3915 190.459L51.0236 190.245L51.3199 189.939C52.9238 188.278 54.263 186.38 55.2907 184.312C54.5609 184.993 53.6903 185.505 52.7407 185.812C51.7911 186.119 50.7857 186.214 49.7957 186.089C48.7504 185.964 47.7469 185.604 46.8605 185.036C45.9741 184.467 45.2277 183.705 44.6775 182.807C42.5062 179.281 39.918 174.277 39.918 170.466C39.9213 167.244 40.5953 164.058 41.8969 161.111C43.1985 158.164 45.0991 155.521 47.4777 153.351C48.9836 151.992 50.1863 150.33 51.0077 148.474C51.829 146.619 52.2503 144.611 52.2443 142.581L52.2431 142.293C52.2427 139.621 52.5689 136.958 53.2145 134.366L53.2801 134.104L53.5492 134.113C55.2846 134.173 57.0209 134.027 58.7223 133.68C57.1264 133.484 55.5652 133.068 54.0829 132.444L53.7934 132.327L53.8916 132.03C60.6645 127.833 75.9528 130.006 84.8266 109.684C92.022 93.206 117.41 124.312 117.41 142.293C117.41 144.127 115.596 146.552 116.954 147.764C131.633 160.873 121.505 169.29 118.311 177.76C117.554 179.77 119.197 181.976 119.197 184.15C119.197 184.519 119.187 184.9 119.169 185.282L119.14 185.87L118.651 185.544C117.683 184.909 116.785 184.172 115.974 183.346C116.815 185.285 117.212 187.388 117.135 189.501C117.057 191.614 116.507 193.682 115.526 195.554C113.987 198.461 112.499 200.541 111.102 201.738C106.628 205.571 100.859 207.548 94.9767 207.265C89.0942 206.982 83.5418 204.461 79.4549 200.216H79.4549Z"
          fill="#E6E6E6"
        />
        <path
          d="M104.03 243.939C104.13 243.939 104.226 243.903 104.302 243.837C104.378 243.771 104.427 243.681 104.441 243.581C104.459 243.457 106.189 230.967 104.635 214.366C103.2 199.036 98.5913 176.954 84.7859 158.819C84.7528 158.775 84.7115 158.738 84.6642 158.711C84.617 158.683 84.5649 158.665 84.5107 158.658C84.4566 158.651 84.4015 158.654 84.3487 158.668C84.2959 158.682 84.2463 158.706 84.2028 158.739C84.1594 158.773 84.1229 158.814 84.0954 158.861C84.0679 158.909 84.0501 158.961 84.0428 159.015C84.0355 159.069 84.0389 159.124 84.053 159.177C84.067 159.23 84.0913 159.28 84.1244 159.323C97.8106 177.302 102.382 199.222 103.807 214.444C105.352 230.948 103.635 243.341 103.618 243.464C103.609 243.523 103.614 243.583 103.631 243.64C103.648 243.698 103.676 243.751 103.716 243.796C103.755 243.841 103.803 243.877 103.857 243.901C103.911 243.926 103.97 243.939 104.03 243.939V243.939Z"
          fill="#3F3D56"
        />
        <path
          d="M92.7673 184.401C92.8552 184.401 92.9408 184.373 93.0118 184.321C93.0829 184.27 93.1358 184.197 93.163 184.113C93.1902 184.03 93.1902 183.94 93.1631 183.856C93.136 183.772 93.0832 183.699 93.0121 183.648C89.0784 180.983 84.8288 178.817 80.3613 177.202C73.3816 174.644 62.7679 172.325 51.8413 175.806C51.7892 175.822 51.7408 175.849 51.699 175.884C51.6571 175.92 51.6226 175.963 51.5975 176.011C51.5723 176.06 51.5569 176.113 51.5523 176.167C51.5476 176.222 51.5538 176.277 51.5704 176.329C51.5869 176.381 51.6136 176.43 51.6489 176.471C51.6842 176.513 51.7274 176.548 51.776 176.573C51.8246 176.598 51.8776 176.613 51.9321 176.618C51.9866 176.622 52.0414 176.616 52.0935 176.599C62.7962 173.19 73.2175 175.47 80.0754 177.983C84.4711 179.571 88.6525 181.7 92.5232 184.321C92.5941 184.373 92.6796 184.401 92.7673 184.401Z"
          fill="#3F3D56"
        />
        <path
          d="M111.796 136.959C111.803 136.871 111.781 136.784 111.735 136.709C111.688 136.635 111.62 136.577 111.538 136.543C111.457 136.51 111.367 136.504 111.282 136.525C111.197 136.546 111.12 136.593 111.064 136.661C108.125 140.396 105.661 144.483 103.729 148.826C100.678 155.61 97.6031 166.04 100.285 177.199C100.297 177.252 100.321 177.302 100.353 177.347C100.385 177.391 100.425 177.428 100.472 177.457C100.518 177.485 100.57 177.505 100.624 177.513C100.678 177.522 100.733 177.52 100.786 177.507C100.839 177.494 100.89 177.471 100.934 177.439C100.978 177.406 101.015 177.366 101.044 177.319C101.072 177.273 101.091 177.221 101.1 177.167C101.108 177.113 101.106 177.057 101.093 177.004C98.4667 166.074 101.49 155.834 104.488 149.168C106.386 144.894 108.809 140.873 111.699 137.197C111.756 137.13 111.79 137.047 111.796 136.959Z"
          fill="#3F3D56"
        />
        <path
          d="M191.99 243.498H158.891L158.749 243.24C158.326 242.472 157.917 241.657 157.535 240.821C154.126 233.515 152.685 225.159 151.414 217.787L150.456 212.23C150.34 211.557 150.428 210.865 150.707 210.242C150.986 209.619 151.445 209.093 152.025 208.733C152.604 208.372 153.278 208.193 153.96 208.218C154.642 208.243 155.3 208.471 155.852 208.873C160.882 212.537 165.914 216.197 170.948 219.854C172.854 221.242 175.031 222.849 177.116 224.585C177.317 223.607 177.527 222.626 177.738 221.659C177.883 220.992 178.224 220.382 178.717 219.91C179.21 219.437 179.832 219.122 180.505 219.006C181.178 218.889 181.87 218.976 182.493 219.256C183.116 219.536 183.641 219.995 184.002 220.575L187.874 226.803C190.699 231.351 193.192 235.832 192.684 240.666C192.686 240.725 192.682 240.784 192.671 240.841C192.569 241.635 192.381 242.416 192.109 243.169L191.99 243.498Z"
          fill="#F0F0F0"
        />
        <path
          d="M176.572 113.251L170.184 89.5854C169.911 88.5835 169.254 87.7295 168.357 87.2077C167.46 86.6858 166.394 86.5379 165.389 86.7959C164.869 86.9294 164.382 87.1674 163.957 87.4956C163.532 87.8237 163.178 88.2351 162.917 88.7046C162.656 89.174 162.493 89.6918 162.438 90.2263C162.384 90.7608 162.439 91.3008 162.599 91.8135L169.988 116.193L173.853 131.196C173.29 131.626 172.873 132.219 172.659 132.895C172.444 133.571 172.443 134.296 172.655 134.973C172.867 135.65 173.282 136.244 173.843 136.677C174.404 137.109 175.085 137.358 175.793 137.39C176.5 137.421 177.201 137.234 177.798 136.853C178.396 136.473 178.862 135.917 179.133 135.262C179.405 134.607 179.468 133.885 179.314 133.192C179.161 132.5 178.798 131.872 178.276 131.393L176.572 113.251Z"
          fill="#A0616A"
        />
        <path
          d="M122.821 108.155L142.23 93.2036C143.05 92.5677 143.588 91.6345 143.727 90.6052C143.866 89.576 143.595 88.5333 142.973 87.7022C142.651 87.2722 142.245 86.9124 141.78 86.6446C141.314 86.3768 140.799 86.2067 140.266 86.1447C139.733 86.0826 139.193 86.1299 138.678 86.2836C138.164 86.4373 137.686 86.6943 137.274 87.0388L117.589 103.188L105.215 112.496C104.602 112.14 103.895 111.981 103.189 112.041C102.484 112.1 101.813 112.376 101.27 112.831C100.726 113.285 100.335 113.896 100.151 114.581C99.9659 115.265 99.9962 115.99 100.237 116.657C100.479 117.323 100.919 117.9 101.499 118.307C102.078 118.714 102.769 118.933 103.478 118.934C104.186 118.935 104.877 118.717 105.458 118.311C106.038 117.904 106.48 117.329 106.722 116.663L122.821 108.155Z"
          fill="#A0616A"
        />
        <path
          d="M138.637 111.592L137.781 130.717L164.643 124.873C162.357 117.188 160.802 115.877 162.52 110.529L138.637 111.592Z"
          fill="#A0616A"
        />
        <path
          d="M155.154 76.5293H146.204L142.36 83.113L140.529 83.0118C139.633 82.9623 138.736 83.0962 137.894 83.4053C137.052 83.7143 136.281 84.1921 135.629 84.8093C134.978 85.4266 134.459 86.1703 134.104 86.9952C133.749 87.82 133.566 88.7086 133.566 89.6066L135.823 93.1077C135.823 93.1077 131.787 100.148 137.601 103.612C137.601 103.612 136.564 110.137 137.601 110.067C138.638 109.997 141.026 108.669 138.638 109.997C138.5 112.227 138.329 111.917 138.638 112.227L138 126L144.5 128.5L151.5 128L164 123C164 123 162.894 111.47 162.973 110.601C163.052 109.731 162.521 111.856 163.052 109.731C163.583 107.606 164.379 108.403 163.583 107.606C162.787 106.81 168.741 96.9371 166.295 93.1077L170.217 89.2782C170.217 85.6049 164.323 82.6271 160.653 82.6271H160.183L155.154 76.5293Z"
          fill="#E6E6E6"
        />
        <path
          d="M133.589 236.125H129.966L128.242 222.139L133.589 222.139L133.589 236.125Z"
          fill="#A0616A"
        />
        <path
          d="M122.655 241.476C122.657 241.882 122.819 242.272 123.105 242.559C123.392 242.847 123.78 243.009 124.186 243.012H131.006L131.217 242.574L132.182 240.581L132.555 242.574L132.638 243.012H135.21L135.174 242.571L134.488 234.337L133.59 234.282L129.714 234.052L128.761 233.994V236.452C128.039 237.222 122.367 240.131 122.655 241.476Z"
          fill="#2F2E41"
        />
        <path
          d="M187.431 226.628L184.384 228.59L175.375 217.76L179.872 214.865L187.431 226.628Z"
          fill="#A0616A"
        />
        <path
          d="M181.128 237.049C181.349 237.39 181.695 237.63 182.092 237.716C182.488 237.803 182.903 237.729 183.246 237.512L188.981 233.818L188.922 233.335L188.656 231.137L190.047 232.611L190.354 232.935L192.517 231.542L192.247 231.19L187.22 224.638L186.436 225.077L183.051 226.983L182.219 227.45L183.548 229.518C183.356 230.556 180.158 236.074 181.128 237.049Z"
          fill="#2F2E41"
        />
        <path
          d="M204.328 243.754L92.1519 243.864C92.0399 243.863 91.9327 243.818 91.8538 243.739C91.7748 243.659 91.7305 243.552 91.7305 243.44C91.7305 243.328 91.7748 243.22 91.8538 243.141C91.9327 243.061 92.0399 243.016 92.1519 243.016L204.328 242.906C204.44 242.907 204.547 242.952 204.626 243.031C204.705 243.111 204.75 243.218 204.75 243.33C204.75 243.442 204.705 243.55 204.626 243.629C204.547 243.709 204.44 243.754 204.328 243.754Z"
          fill="#CACACA"
        />
        <path
          d="M164.221 122.711C164.221 122.711 147.051 131.802 137.969 125.6C137.969 125.6 135.982 135.231 135.186 136.028C134.39 136.825 133.122 137.172 134.021 138.061C134.921 138.95 135.422 140.15 133.712 141.675C132.002 143.2 130.297 142.716 131.945 144.286C133.594 145.856 126.96 184.371 126.96 184.371C126.96 184.371 128.021 220.189 126.96 221.273C125.898 222.356 124.479 222.598 125.852 223.938C127.225 225.278 127.225 224.239 127.225 226.086V227.934L127.853 229.77H136.469C136.469 229.77 138.549 227.353 136.469 225.253C134.39 223.153 137.102 222.578 137.736 220.608C138.371 218.637 139.432 220.762 138.371 218.637C137.309 216.512 136.799 216.916 136.789 215.519C136.778 214.122 152.435 159.403 152.435 159.403L154.332 189.689L177.142 222.857L183.856 217.817C183.856 217.817 178.967 210.929 180.111 209.982C181.254 209.035 176.742 207.847 178.164 206.779C179.587 205.711 176.162 204.635 177.453 203.219C178.744 201.803 175.674 198.234 173.896 198.234C172.117 198.234 169.621 192.285 169.621 192.285L173.347 145.289L164.221 122.711Z"
          fill="#2F2E41"
        />
        <path
          d="M146.024 57.1973C145.903 57.357 144.935 57.271 143.771 57.1009C141.469 56.7644 141.155 57.1642 139.434 58.731C136.942 61.0005 138.359 74.8433 137.134 77.8427L136.738 78.1346C137.523 77.9845 138.294 77.7686 139.043 77.489C139.466 76.1533 139.89 74.8133 140.317 73.4786C140.72 74.517 140.973 75.6076 141.069 76.7175C141.662 76.4837 142.251 75.9119 144.727 75.145C145.974 74.2984 154.993 73.4472 156.153 73.0747C159.096 63.0524 154.353 58.9993 146.024 57.1973Z"
          fill="#2F2E41"
        />
        <path
          d="M149.069 72.6526C153.829 72.6526 157.688 68.7899 157.688 64.025C157.688 59.2602 153.829 55.3975 149.069 55.3975C144.308 55.3975 140.449 59.2602 140.449 64.025C140.449 68.7899 144.308 72.6526 149.069 72.6526Z"
          fill="#A0616A"
        />
        <path
          d="M154.494 56.438C154.009 56.3663 153.541 56.2022 153.117 55.9546C152.907 55.8275 152.736 55.6438 152.625 55.4244C152.515 55.205 152.468 54.9588 152.49 54.714C152.596 54.1009 153.288 53.7349 153.909 53.7722C154.527 53.8613 155.113 54.0991 155.619 54.4651C155.564 53.8726 155.311 53.3161 154.901 52.8848C154.492 52.4536 153.949 52.1726 153.361 52.087C154.177 52.043 155.025 52.0042 155.78 52.3184C156.534 52.6325 157.163 53.4104 157.027 54.217C158.296 54.0534 159.547 54.9381 160.071 56.1062C160.594 57.2742 160.49 58.6459 160.072 59.8558C160.031 60.0411 159.95 60.2153 159.836 60.3667C159.721 60.5181 159.576 60.6431 159.409 60.7332C158.982 60.8954 158.522 60.6234 158.152 60.3554C156.22 58.9549 154.461 57.3289 152.913 55.5119"
          fill="#2F2E41"
        />
        <path
          d="M158.254 73.1972C157.889 73.3694 158.079 74.01 157.795 74.2969C157.672 74.4183 157.523 74.5109 157.36 74.5678C157.317 74.0331 157.193 73.5082 156.991 73.0113C156.954 72.9209 156.893 72.82 156.797 72.828C156.686 72.8412 156.656 72.9873 156.654 73.0962C156.631 73.6365 156.512 74.1683 156.301 74.6661C156.298 74.6714 156.295 74.6794 156.293 74.6847C156.211 74.682 156.126 74.6794 156.046 74.6767C155.43 74.6528 154.066 73.0697 153.764 73.6063C154.831 71.7124 156.131 68.8648 156.33 66.7C156.399 66.2468 156.365 65.7838 156.232 65.3453C156.162 65.1271 156.031 64.9336 155.855 64.7876C155.678 64.6417 155.464 64.5494 155.237 64.5219C155.125 64.5167 155.013 64.5365 154.909 64.5797C154.806 64.6229 154.713 64.6884 154.638 64.7717C154.562 64.8549 154.506 64.9538 154.474 65.0612C154.441 65.1687 154.432 65.282 154.449 65.3931L154.295 65.3719C152.867 64.2084 154.098 62.187 152.437 61.3875C151.874 61.1165 148.43 62.0223 147.822 61.863C147.809 61.2222 147.679 60.5892 147.437 59.9956C147.4 59.9053 147.339 59.8044 147.244 59.8123C147.132 59.8256 147.103 59.9717 147.1 60.0806C147.078 60.6209 146.958 61.1526 146.747 61.6505C145.906 61.5309 145.048 61.576 144.224 61.7833C143.497 61.9745 142.807 62.3039 142.077 62.4792C141.716 62.5899 141.337 62.6257 140.962 62.5846C140.587 62.5435 140.224 62.4263 139.895 62.2401C138.969 61.6186 138.807 60.2426 139.269 59.2279C139.603 58.5768 140.055 57.9933 140.601 57.5067C141.018 57.1029 141.451 56.7151 141.883 56.3273C142.271 55.9793 142.658 55.6314 143.045 55.2834C143.533 54.8239 144.053 54.3996 144.601 54.0137C145.475 53.44 146.457 53.0512 147.487 52.8712C148.517 52.6913 149.573 52.7239 150.59 52.9671C151.811 53.2593 153.443 52.8476 154.56 53.4187C155.144 53.7189 156.436 54.869 156.948 55.2781C158.259 56.322 157.372 55.4714 158.806 56.075C162.225 57.5141 160.713 72.0384 158.254 73.1972Z"
          fill="#2F2E41"
        />
        <path
          d="M62.817 136.17C92.3387 136.17 116.271 112.215 116.271 82.6649C116.271 53.115 92.3387 29.1602 62.817 29.1602C33.2953 29.1602 9.36328 53.115 9.36328 82.6649C9.36328 112.215 33.2953 136.17 62.817 136.17Z"
          fill="white"
        />
        <path
          d="M62.8193 136.703C33.0507 136.703 8.83203 112.461 8.83203 82.6638C8.83203 52.8668 33.0507 28.625 62.8193 28.625C92.5879 28.625 116.807 52.8668 116.807 82.6638C116.807 112.461 92.5879 136.703 62.8193 136.703ZM62.8193 29.6932C33.6391 29.6932 9.89917 53.4558 9.89917 82.6638C9.89917 111.872 33.6391 135.634 62.8193 135.634C91.9995 135.634 115.739 111.872 115.739 82.6638C115.739 53.4558 91.9995 29.6932 62.8193 29.6932Z"
          fill="#4A154B"
        />
        <path
          d="M55.5245 110.511C54.6014 110.511 53.691 110.296 52.8654 109.882C52.0399 109.469 51.3218 108.869 50.7681 108.13L36.1852 88.6667C35.7167 88.0415 35.3758 87.3299 35.1821 86.5728C34.9883 85.8157 34.9454 85.0278 35.0559 84.2541C35.1663 83.4804 35.4279 82.736 35.8258 82.0635C36.2236 81.391 36.7499 80.8035 37.3746 80.3346C37.9993 79.8657 38.7101 79.5245 39.4665 79.3306C40.2229 79.1366 41.0101 79.0937 41.783 79.2043C42.556 79.3148 43.2996 79.5767 43.9715 79.9749C44.6434 80.3731 45.2303 80.8999 45.6988 81.5252L55.2394 94.2573L79.7435 57.4666C80.6187 56.1543 81.9787 55.2435 83.5246 54.9346C85.0704 54.6256 86.6756 54.9437 87.9872 55.819C89.2988 56.6942 90.2096 58.055 90.5192 59.6021C90.8288 61.1492 90.512 62.7561 89.6384 64.0695L60.4724 107.86C59.9448 108.652 59.2352 109.306 58.4031 109.767C57.5709 110.228 56.6406 110.482 55.69 110.509C55.6348 110.51 55.5796 110.511 55.5245 110.511Z"
          fill="#4A154B"
        />
        <path
          d="M117.894 32.5036C118.043 31.4807 116.972 30.478 115.503 30.2641C114.034 30.0501 112.723 30.7059 112.574 31.7289C112.425 32.7518 113.496 33.7545 114.965 33.9684C116.434 34.1824 117.745 33.5266 117.894 32.5036Z"
          fill="#E6E6E6"
        />
        <path
          d="M135.382 30.7947C135.531 29.7717 134.46 28.769 132.991 28.5551C131.522 28.3411 130.211 28.997 130.062 30.0199C129.914 31.0428 130.984 32.0455 132.453 32.2595C133.922 32.4734 135.233 31.8176 135.382 30.7947Z"
          fill="#3F3D56"
        />
        <path
          d="M113.101 18.3288C113.25 17.3059 112.179 16.3032 110.71 16.0893C109.241 15.8753 107.93 16.5311 107.781 17.554C107.632 18.577 108.703 19.5797 110.172 19.7936C111.641 20.0076 112.952 19.3518 113.101 18.3288Z"
          fill="#FF6584"
        />
        <path
          d="M105.501 12.5236C105.508 11.5845 104.985 10.8192 104.332 10.8142C103.68 10.8093 103.145 11.5666 103.138 12.5057C103.131 13.4448 103.654 14.2101 104.307 14.215C104.959 14.2199 105.494 13.4627 105.501 12.5236Z"
          fill="#E6E6E6"
        />
        <path
          d="M102.923 1.71008C102.93 0.770981 102.407 0.00568527 101.754 0.000745271C101.102 -0.00419472 100.567 0.753092 100.56 1.69219C100.553 2.63129 101.076 3.39658 101.729 3.40152C102.381 3.40646 102.916 2.64918 102.923 1.71008Z"
          fill="#3F3D56"
        />
        <path
          d="M122.966 20.7404C122.973 19.8013 122.45 19.036 121.797 19.031C121.145 19.0261 120.61 19.7834 120.603 20.7225C120.596 21.6616 121.119 22.4269 121.772 22.4318C122.424 22.4367 122.959 21.6794 122.966 20.7404Z"
          fill="#E6E6E6"
        />
        <path
          d="M114.517 24.966C114.524 24.0269 114.001 23.2616 113.348 23.2566C112.695 23.2517 112.161 24.009 112.153 24.9481C112.146 25.8872 112.67 26.6524 113.322 26.6574C113.975 26.6623 114.51 25.9051 114.517 24.966Z"
          fill="#FF6584"
        />
        <path
          d="M126.283 32.7726C126.29 31.8335 125.766 31.0682 125.114 31.0633C124.461 31.0583 123.926 31.8156 123.919 32.7547C123.912 33.6938 124.435 34.4591 125.088 34.464C125.741 34.469 126.275 33.7117 126.283 32.7726Z"
          fill="#3F3D56"
        />
        <path
          d="M17.5814 159.258C17.7301 158.235 16.6597 157.232 15.1906 157.018C13.7216 156.804 12.4101 157.46 12.2614 158.483C12.1127 159.506 13.1831 160.508 14.6522 160.722C16.1212 160.936 17.4327 160.28 17.5814 159.258Z"
          fill="#E6E6E6"
        />
        <path
          d="M35.0736 157.549C35.2223 156.526 34.1519 155.523 32.6828 155.309C31.2137 155.095 29.9023 155.751 29.7536 156.774C29.6049 157.797 30.6753 158.799 32.1444 159.013C33.6134 159.227 34.9249 158.571 35.0736 157.549Z"
          fill="#3F3D56"
        />
        <path
          d="M12.7923 145.084C12.941 144.061 11.8706 143.058 10.4016 142.844C8.93251 142.63 7.62105 143.286 7.47236 144.309C7.32366 145.332 8.39405 146.335 9.86312 146.549C11.3322 146.762 12.6436 146.107 12.7923 145.084Z"
          fill="#FF6584"
        />
        <path
          d="M5.19276 139.277C5.19985 138.338 4.67652 137.573 4.02386 137.568C3.37119 137.563 2.83635 138.32 2.82925 139.26C2.82216 140.199 3.3455 140.964 3.99816 140.969C4.65083 140.974 5.18566 140.217 5.19276 139.277Z"
          fill="#E6E6E6"
        />
        <path
          d="M2.61462 128.464C2.62171 127.525 2.09838 126.76 1.44572 126.755C0.793058 126.75 0.258221 127.507 0.251127 128.446C0.244032 129.385 0.767366 130.15 1.42003 130.155C2.07269 130.16 2.60753 129.403 2.61462 128.464Z"
          fill="#3F3D56"
        />
        <path
          d="M22.6537 147.496C22.6608 146.557 22.1374 145.792 21.4848 145.787C20.8321 145.782 20.2973 146.539 20.2902 147.478C20.2831 148.417 20.8064 149.183 21.4591 149.188C22.1117 149.193 22.6466 148.435 22.6537 147.496Z"
          fill="#E6E6E6"
        />
        <path
          d="M14.2045 151.72C14.2116 150.781 13.6882 150.015 13.0356 150.011C12.3829 150.006 11.8481 150.763 11.841 151.702C11.8339 152.641 12.3572 153.406 13.0099 153.411C13.6625 153.416 14.1974 152.659 14.2045 151.72Z"
          fill="#FF6584"
        />
        <path
          d="M25.9701 159.526C25.9772 158.587 25.4538 157.822 24.8012 157.817C24.1485 157.812 23.6137 158.569 23.6066 159.509C23.5995 160.448 24.1228 161.213 24.7755 161.218C25.4281 161.223 25.963 160.466 25.9701 159.526Z"
          fill="#3F3D56"
        />
      </g>
      <defs>
        <clipPath id="clip0_648_51152">
          <rect
            width="204.5"
            height="243.939"
            fill="white"
            transform="translate(0.25)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OfferAcceptedIcon;
