import React from 'react';
import { Modal } from 'react-bootstrap';
import CrossIcon from '../../app/components/svgIcons/CrossIcon';
import OfferAcceptedIcon from '../../app/components/svgIcons/OfferAcceptedIcon';
import { StyledLabel, Wrap } from '../../assets/styledComponents/Global';

const ApplicationSubmittedModal = ({
  jobOffer,
  show,
  onHide,
  setOfferLetter,
  state,
  pickDate,
  title = '',
  description = '',
}) => {
 
  return (
    <Modal
      show={show}
      centered
      onHide={onHide}
      size={'lg'}
      className="bg-black bg-opacity-50"
      backdrop="static"
    >
      <Modal.Body className="d-flex  flex-column bg-body rounded">
        <Wrap className="d-flex align-items-end justify-content-end ">
          <CrossIcon onClick={onHide} className="cursor-pointer me-2 mt-2" size={'24'}/>
        </Wrap>
        <Wrap className="col-8 d-flex align-items-center justify-content-center flex-column my-4  align-self-center">
          <OfferAcceptedIcon />
          <StyledLabel size="20px" className="mt-3 mb-1">
            Application Submitted
          </StyledLabel>
          <StyledLabel weight="500px" className="text-center">
            Thank you for your interest!
            <br />We’ll keep you updated on the status of your application.
          </StyledLabel>
        </Wrap>
      </Modal.Body>
    </Modal>
  );
};

export default ApplicationSubmittedModal;
