import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
const StyledToolTip = ({
  children,
  title,
  placement,
  className,
  childrenClass,
  applyStyle = true,
  condition,
}) => {
  return (
    <OverlayTrigger
      overlay={title ? <Tooltip className={className || ''}>{title}</Tooltip> : <></>}
      placement={placement}
    >
      <span
        className={`${applyStyle ? 'd-flex align-items-center cursor-pointer' :''} ${
          childrenClass ?? 'ms-2'
        } `}
      >
        {children}
      </span>
    </OverlayTrigger>
  );
};

export default StyledToolTip;
