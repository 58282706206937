import { groupBy } from 'lodash';
import moment from 'moment';
import React, { Fragment, useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PortletBody from '../../../../app/components/portlet/PortletBody';
import StyledButton from '../../../../app/components/styledComponents/StyledButton';
import StyledImage from '../../../../app/components/styledComponents/StyledImage';
import {
  StyledLabel,
  StyledSpan,
  Wrap,
} from '../../../../assets/styledComponents/Global';
import { handleErrorImg } from '../../../../utils/helper';
import theme from '../../../../utils/themes';
import {
  useGetAllNotificationsQuery,
  useMarkAllNotifAsReadMutation,
} from '../Apis/notificationApiSlice';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ViewAllNotifications = () => {
  const [markAllAsRead, { isLoading: loading }] =
    useMarkAllNotifAsReadMutation();
  const initialFilters = {
    perPage: 10,
    page: 1,
  };
  const [notificationState, setNotificationState] = useState([]);

  const [listing, setListing] = useState({ ...initialFilters });
  const { data: notificationRes } = useGetAllNotificationsQuery({
    perPage: listing.perPage,
    page: listing.page,
  });
  const { notifictionData } = useSelector(
    (notificationState) => notificationState,
  );
  const transformData = (notificationState) => {
    const tranformData = notificationState?.map((item) => ({
      ...item,
      date: moment(item.created_at).format('DD MMM YYYY'),
    }));
    const groupedByTitle = groupBy(tranformData, 'date');
    const groupedData = [];
    for (const key of Object.keys(groupedByTitle)) {
      const values = groupedByTitle[key].map((value) => ({
        message: value.message,
        image: value.image,
        createdAt: value.created_at,
      }));
      const group = { title: key, data: values };
      groupedData.push(group);
    }
    return groupedData;
  };

  const formattedTime = (date) => {
    return moment(date).format('LT');
  };
  const markAllNotifAsRead = () => {
    markAllAsRead()
      .unwrap()
      .then((res) => {
        toast.success(res?.message || 'Marked as read successfully');
        notifictionData?.payload();
      });
  };
  const divRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = divRef.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        setListing((prevState) => ({ ...prevState, page: prevState.page + 1 }));
      }
      // }
    };
    divRef?.current?.addEventListener('scroll', handleScroll);
    return () => {
      divRef?.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    if (notificationRes?.notifications) {
      setNotificationState([
        ...notificationState,
        ...notificationRes?.notifications,
      ]);
    }
  }, [notificationRes]);

  const nameUpdate = (name) => {
    const upName = name.split(' ');
    return upName[1] === 'null' ? upName[0] : name;
  };
  return (
    <>
      <Wrap className="w-100 bg-white px-4 py-2">
        <div className="d-flex justify-content-between align-items-center">
          <StyledLabel size="30px">Notifications</StyledLabel>
          <StyledButton
            variant="white"
            onClick={() => markAllNotifAsRead()}
            loading={loading}
            disabled={loading}
          >
            Mark all as read
          </StyledButton>
        </div>
      </Wrap>
      <Wrap className="justify-content-center d-flex">
        <Wrap className="col-10">
          <PortletBody className="mx-5 mt-3 rounded-4 pt-4 styledContainer">
            <StyledDivContainer ref={divRef}>
              {transformData(notificationState)?.map((section, index) => (
                <Fragment key={Math.random()}>
                  <StyledLabel
                    color={theme.lightBoldBlack}
                    className="mb-4"
                     key={'title' + section?.id}
                  >
                    {moment(section?.title).format('DD MMM YYYY')}
                  </StyledLabel>
                  <div>
                    {section?.data?.map((data,ind) => (
                      <div
                        className="row mb-4 align-items-center"
                        key={Math.random() + ind}
                      >
                        <div className="col-11 d-flex align-items-center gap-3">
                          <div className="">
                            <StyledImage
                              height={'56px'}
                              width={'56px'}
                              className="rounded-circle"
                            >
                              <img
                                src={data?.image ?? ''}
                                alt="img"
                                onError={handleErrorImg}
                              />
                            </StyledImage>
                          </div>
                          <div className="p-0 ">
                            {data?.message?.map((item,indx) => (
                              <span  key={`datatwo_${indx}`}>
                                <StyledSpan
                                  size="16px"
                                  color={theme.textColor}
                                  weight="400"
                                  className={`me-1 ${
                                    item?.bold ? 'fw-bold' : ''
                                  }`}
                                 
                                  {...(item?.link
                                    ? {
                                        to: `/${item?.link}`,
                                        as: Link,
                                      }
                                    : {})}
                                >
                                  {item?.bold
                                    ? nameUpdate(item?.text)
                                    : item?.text}
                                </StyledSpan>
                              </span>
                            ))}
                          </div>
                        </div>

                        <div className="col-1">
                          <StyledLabel
                            size="14px"
                            color={theme.inputPlaceHolder}
                          >
                            {formattedTime(data?.createdAt)}
                          </StyledLabel>
                        </div>
                      </div>
                    ))}
                  </div>
                </Fragment>
              ))}
            </StyledDivContainer>
          </PortletBody>
        </Wrap>
      </Wrap>
    </>
  );
};
const StyledDivContainer = styled.div`
  height: 82vh;
  overflow-y: auto;
  overflow-x: hidden;
`;
export default ViewAllNotifications;
