import React from 'react';

const WelcomeSideLayer = ({ size=40, color='#C4994A', ...props }) => (
<svg width="683" height="665" viewBox="0 0 683 665" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path opacity="0.04" d="M209.788 507.102C14.4347 559.104 -11.8575 804.331 3.87716 861.913L959.281 968.152L1064.65 20.5584C1051.59 11.2976 1000.15 -5.35478 898.858 2.12238C772.237 11.4688 589.248 85.4067 546.636 210.587C447.403 502.101 467.053 438.619 209.788 507.102Z" fill="#7D15E1"/>
</svg>
   
);


export default WelcomeSideLayer;
