import React from 'react';

const RejectedIcon = ({size = 16}) => (
<svg width="83" height="93" viewBox="0 0 83 93" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_79_2747)">
<path d="M46.9863 71.6581C66.6 71.6581 82.5 55.6169 82.5 35.829C82.5 16.0412 66.6 0 46.9863 0C27.3727 0 11.4727 16.0412 11.4727 35.829C11.4727 55.6169 27.3727 71.6581 46.9863 71.6581Z" fill="#F8F2F7"/>
<path d="M70.194 16.0226H5.13788C4.99838 16.0196 4.8877 15.9031 4.89068 15.7623C4.89358 15.6258 5.00257 15.5159 5.13788 15.5129H70.194C70.3335 15.5159 70.4442 15.6325 70.4412 15.7732C70.4383 15.9097 70.3293 16.0197 70.194 16.0226Z" fill="#CACACA"/>
<path d="M48.0229 24.6111H30.0389C28.6881 24.6111 27.593 25.7159 27.593 27.0787V27.0787C27.593 28.4415 28.6881 29.5462 30.0389 29.5462H48.0229C49.3737 29.5462 50.4688 28.4415 50.4688 27.0787C50.4688 25.7159 49.3737 24.6111 48.0229 24.6111Z" fill="white"/>
<path d="M18.4211 34.4812H59.6408C60.9916 34.4812 62.0866 35.586 62.0866 36.9488C62.0866 38.3116 60.9916 39.4164 59.6408 39.4164H18.4211C17.0703 39.4164 15.9752 38.3116 15.9752 36.9488C15.9752 35.586 17.0703 34.4812 18.4211 34.4812Z" fill="white"/>
<path d="M18.4211 44.3516H59.6408C60.9916 44.3516 62.0866 45.4563 62.0866 46.8191C62.0866 48.1819 60.9916 49.2867 59.6408 49.2867H18.4211C17.0703 49.2867 15.9752 48.1819 15.9752 46.8191C15.9752 45.4563 17.0703 44.3516 18.4211 44.3516Z" fill="white"/>
<path d="M13.3129 92.7831L13.2159 92.7735C9.92453 92.4309 6.94912 90.645 5.08017 87.8903C4.56824 87.1227 4.15416 86.2931 3.84768 85.4212L3.81822 85.338L3.90543 85.3309C4.94665 85.2463 6.0163 85.0681 6.49147 84.9833L3.42741 83.9359L3.40846 83.8442C3.2268 82.9831 3.58269 82.0972 4.30728 81.6067C5.03383 81.0926 5.9973 81.0762 6.74067 81.5652C7.07428 81.7801 7.42321 81.9931 7.76063 82.199C8.92016 82.9066 10.1192 83.6383 11.0181 84.6395C12.3813 86.188 12.9788 88.2746 12.6442 90.318L13.3129 92.7831Z" fill="#F2F2F2"/>
<path d="M24.4507 91.2456L25.9608 91.2455L26.6793 85.3687L24.4503 85.3688L24.4507 91.2456Z" fill="#A0616A"/>
<path d="M24.3975 92.835L29.0415 92.8348V92.7756C29.0414 91.7685 28.2322 90.952 27.2339 90.952L26.3855 90.3027L24.8028 90.9521L24.3975 90.9521L24.3975 92.835Z" fill="#2F2E41"/>
<path d="M12.3483 87.0256L13.5562 87.9399L17.6267 83.6741L15.8437 82.3247L12.3483 87.0256Z" fill="#A0616A"/>
<path d="M11.3604 88.265L15.0752 91.0765L15.1104 91.0292C15.7094 90.2235 15.5478 89.0805 14.7493 88.4761L14.4569 87.4431L12.8046 87.0042L12.4803 86.7588L11.3604 88.265Z" fill="#2F2E41"/>
<path d="M17.3682 63.6674C17.3682 63.6674 17.4516 65.9616 17.5567 67.8014C17.574 68.1032 16.8733 68.431 16.8914 68.7592C16.9035 68.977 17.0352 69.1887 17.0474 69.4158C17.0603 69.6555 16.8785 69.8676 16.8914 70.1154C16.9041 70.3576 17.1115 70.6353 17.124 70.8832C17.2587 73.5505 17.7469 76.7437 17.258 77.6363C17.0171 78.0762 13.9464 84.1708 13.9464 84.1708C13.9464 84.1708 15.6573 86.7599 16.1461 85.8969C16.7918 84.757 21.7677 79.424 21.7677 78.4056C21.7677 77.3884 22.9188 69.7653 22.9188 69.7653L23.7215 74.2766L24.0896 75.1704L23.9739 75.6952L24.2118 76.4033L24.2247 77.1042L24.4562 78.4056C24.4562 78.4056 23.7596 88.5452 24.1519 88.8275C24.5454 89.1111 26.4506 89.6203 26.6755 89.1111C26.8991 88.6019 28.3548 78.5289 28.3548 78.5289C28.3548 78.5289 28.5894 73.9128 28.8436 69.6223C28.8583 69.3732 29.0319 69.0724 29.0453 68.827C29.0612 68.5422 28.9512 68.1724 28.9659 67.8962C28.9817 67.5793 29.1211 67.3562 29.1345 67.0528C29.2384 64.7091 28.6553 61.8549 28.4695 61.5738C27.9086 60.7256 27.4039 60.0475 27.4039 60.0475C27.4039 60.0475 19.8487 57.5431 17.8299 60.0879L17.3682 63.6674Z" fill="#2F2E41"/>
<path d="M24.9033 40.0755L21.9083 39.4712L20.5107 41.3849C17.9027 43.972 17.8634 46.2553 18.329 49.9153V55.052L17.992 58.4955C17.992 58.4955 16.7992 61.0128 18.0295 61.5988C19.2598 62.1849 27.3411 62.135 28.1127 61.901C28.8843 61.667 28.2155 61.3798 28.0129 60.2895C27.6323 58.2414 27.8868 59.2293 27.913 58.5772C28.2886 49.2231 27.4139 45.3828 27.325 44.3734L25.8019 41.5863L24.9033 40.0755Z" fill="#3F3D56"/>
<path d="M40.6891 47.6174C40.377 48.2141 39.6445 48.4425 39.0531 48.1276C38.99 48.094 38.93 48.0549 38.8738 48.0107L32.4058 52.154L32.4244 49.8982L38.7076 46.2196C39.1551 45.7133 39.9247 45.669 40.4265 46.1205C40.8464 46.4983 40.955 47.1172 40.6891 47.6174Z" fill="#A0616A"/>
<path d="M22.5388 42.6879L21.8024 42.6091C21.122 42.5379 20.4545 42.8319 20.0436 43.3837C19.8862 43.593 19.7732 43.8326 19.7115 44.0878L19.7111 44.0899C19.5264 44.858 19.8161 45.663 20.4463 46.1326L22.9896 48.0243C24.7744 50.4574 28.1288 52.0717 32.0635 53.3831L38.1795 49.551L36.0163 46.9078L31.8213 49.176L25.5856 44.3061L25.5821 44.3032L23.2088 42.7605L22.5388 42.6879Z" fill="#3F3D56"/>
<path d="M23.8915 38.5917C25.8205 38.5917 27.3842 37.0141 27.3842 35.0681C27.3842 33.122 25.8205 31.5444 23.8915 31.5444C21.9626 31.5444 20.3989 33.122 20.3989 35.0681C20.3989 37.0141 21.9626 38.5917 23.8915 38.5917Z" fill="#A0616A"/>
<path d="M23.9722 38.5925C23.944 38.5935 23.9158 38.5941 23.8876 38.5949C23.8756 38.6264 23.8626 38.6578 23.8475 38.6885L23.9722 38.5925Z" fill="#2F2E41"/>
<path d="M24.9307 35.1658C24.9361 35.2005 24.9445 35.2347 24.9557 35.268C24.9511 35.2331 24.9427 35.1988 24.9307 35.1658Z" fill="#2F2E41"/>
<path d="M27.4287 31.6952C27.2849 32.1456 27.1566 31.5743 26.6837 31.6771C26.1148 31.8007 25.4528 31.7582 24.9869 31.4064C24.2927 30.8926 23.3931 30.7543 22.5787 31.0361C21.7816 31.3206 20.446 31.5213 20.2383 32.3482C20.1661 32.6357 20.1374 32.9476 19.9624 33.1859C19.8094 33.3942 19.5679 33.5129 19.366 33.6732C18.684 34.2145 19.2052 35.7524 19.528 36.565C19.8507 37.3776 20.5911 37.9696 21.4051 38.2728C22.1926 38.5661 23.0474 38.6179 23.8876 38.5948C24.0337 38.2125 23.9706 37.7709 23.85 37.3745C23.7195 36.9454 23.5262 36.5312 23.4717 36.0858C23.4171 35.6403 23.5369 35.1343 23.9071 34.8854C24.2474 34.6566 24.8012 34.796 24.9307 35.1659C24.8551 34.7034 25.3195 34.2559 25.7983 34.1772C26.3123 34.0927 26.8263 34.2799 27.3389 34.3731C27.8514 34.4663 27.6695 32.2893 27.4287 31.6952Z" fill="#2F2E41"/>
<path d="M46.962 40.8969C47.2876 36.928 44.3624 33.4443 40.4284 33.1158C36.4944 32.7873 33.0413 35.7385 32.7157 39.7074C32.3902 43.6763 35.3154 47.1601 39.2493 47.4886C43.1833 47.817 46.6364 44.8659 46.962 40.8969Z" fill="#4A154B"/>
<path d="M41.7163 37.2976L39.8388 39.1918L37.9612 37.2976C37.6573 36.991 37.1646 36.991 36.8606 37.2976C36.5567 37.6042 36.5567 38.1014 36.8606 38.408L38.7381 40.3022L36.8606 42.1963C36.557 42.5033 36.5575 43.0004 36.8617 43.3067C37.1655 43.6127 37.6574 43.6127 37.9612 43.3067L39.8388 41.4125L41.7163 43.3067C42.0205 43.6131 42.5132 43.6126 42.8169 43.3057C43.1201 42.9992 43.1201 42.5029 42.8169 42.1963L40.9394 40.3022L42.8169 38.408C43.1208 38.1014 43.1208 37.6042 42.8169 37.2976C42.513 36.991 42.0202 36.991 41.7163 37.2976Z" fill="white"/>
<path d="M37.0223 34.2412C37.233 34.8815 36.8892 35.5729 36.2546 35.7855C36.1869 35.8082 36.1174 35.8248 36.0468 35.8351L34.5325 43.4123L32.9319 41.837L34.6506 34.7161C34.5984 34.0398 35.0994 33.4488 35.7698 33.3961C36.3307 33.352 36.8485 33.7014 37.0223 34.2412Z" fill="#A0616A"/>
<path d="M20.9309 44.0422L20.3648 44.5238C19.8428 44.9698 19.5909 45.6594 19.7012 46.3405C19.7419 46.5999 19.8351 46.8481 19.9749 47.0697L19.976 47.0714C20.3976 47.7377 21.1739 48.084 21.946 47.9504L25.0599 47.4085C28.0359 47.7936 31.5127 46.4691 35.1747 44.5124L36.6679 37.4069L33.2797 37.1519L31.9979 41.7762L24.1977 42.9443L24.1931 42.9449L21.4467 43.6048L20.9309 44.0422Z" fill="#3F3D56"/>
<path d="M38.6614 92.9568L0.665941 93.0001C0.573998 92.9998 0.499703 92.9244 0.500001 92.8316C0.500296 92.7393 0.574416 92.6645 0.665941 92.6642L38.6614 92.6208C38.7533 92.6212 38.8276 92.6966 38.8273 92.7893C38.827 92.8817 38.7529 92.9565 38.6614 92.9568Z" fill="#CACACA"/>
<path d="M8.43836 12.7946C9.09138 12.7946 9.62075 12.2487 9.62075 11.5752C9.62075 10.9017 9.09138 10.3557 8.43836 10.3557C7.78535 10.3557 7.25598 10.9017 7.25598 11.5752C7.25598 12.2487 7.78535 12.7946 8.43836 12.7946Z" fill="#3F3D56"/>
<path d="M12.5231 12.7946C13.1761 12.7946 13.7055 12.2487 13.7055 11.5752C13.7055 10.9017 13.1761 10.3557 12.5231 10.3557C11.8701 10.3557 11.3407 10.9017 11.3407 11.5752C11.3407 12.2487 11.8701 12.7946 12.5231 12.7946Z" fill="#3F3D56"/>
<path d="M16.6077 12.7946C17.2607 12.7946 17.7901 12.2487 17.7901 11.5752C17.7901 10.9017 17.2607 10.3557 16.6077 10.3557C15.9547 10.3557 15.4253 10.9017 15.4253 11.5752C15.4253 12.2487 15.9547 12.7946 16.6077 12.7946Z" fill="#3F3D56"/>
<path d="M21.2652 12.5392C21.2288 12.5392 21.1926 12.525 21.1652 12.4967L20.3674 11.6739C20.3142 11.6191 20.3142 11.5315 20.3674 11.4767L21.1652 10.6539C21.2193 10.5983 21.308 10.5974 21.3629 10.6517C21.4181 10.7062 21.4191 10.7955 21.3651 10.8512L20.6629 11.5753L21.3651 12.2995C21.4191 12.3551 21.4181 12.4444 21.3629 12.4989C21.3357 12.5258 21.3005 12.5392 21.2652 12.5392Z" fill="#3F3D56"/>
<path d="M22.6059 12.539C22.5706 12.539 22.5353 12.5256 22.5081 12.4988C22.4529 12.4443 22.4519 12.355 22.5059 12.2993L23.208 11.5752L22.5059 10.8511C22.4519 10.7954 22.4529 10.7061 22.5081 10.6516C22.5632 10.597 22.6518 10.5981 22.7058 10.6538L23.5036 11.4765C23.5568 11.5313 23.5568 11.619 23.5036 11.6738L22.7058 12.4966C22.6785 12.5248 22.6422 12.539 22.6059 12.539Z" fill="#3F3D56"/>
<path d="M64.3428 10.4971H62.8593C62.6901 10.4971 62.5531 10.6353 62.5531 10.806V12.304C62.5531 12.4747 62.6901 12.613 62.8593 12.613H64.3428C64.5119 12.613 64.6504 12.4747 64.6504 12.304V10.806C64.6504 10.6353 64.5119 10.4971 64.3428 10.4971Z" fill="#3F3D56"/>
<path d="M60.7075 10.4971H59.224C59.0549 10.4971 58.9178 10.6353 58.9178 10.806V12.304C58.9178 12.4747 59.0549 12.613 59.224 12.613H60.7075C60.8767 12.613 61.0151 12.4747 61.0151 12.304V10.806C61.0151 10.6353 60.8767 10.4971 60.7075 10.4971Z" fill="#3F3D56"/>
<path d="M67.7683 10.5676H66.2848C66.1157 10.5676 65.9786 10.7059 65.9786 10.8765V12.3746C65.9786 12.5453 66.1157 12.6835 66.2848 12.6835H67.7683C67.9375 12.6835 68.0759 12.5453 68.0759 12.3746V10.8765C68.0759 10.7059 67.9375 10.5676 67.7683 10.5676Z" fill="#3F3D56"/>
<path d="M45.4084 11.1375H33.5504C33.3435 11.1375 33.1771 11.3067 33.1771 11.5141C33.1771 11.7214 33.3435 11.8907 33.5504 11.8907H45.4084C45.6139 11.8907 45.7817 11.7214 45.7817 11.5141C45.7817 11.3067 45.6139 11.1375 45.4084 11.1375Z" fill="#3F3D56"/>
</g>
<defs>
<clipPath id="clip0_79_2747">
<rect width="82" height="93" fill="white" transform="translate(0.5)"/>
</clipPath>
</defs>
</svg>




);

export default RejectedIcon;
