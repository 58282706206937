import React from 'react';

const PersonFillIcon = ({size = 16, ...props}) => (
<svg width={size} height={size+1} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M7.99967 1.8335C6.25301 1.8335 4.83301 3.2535 4.83301 5.00016C4.83301 6.7135 6.17301 8.10016 7.91967 8.16016C7.97301 8.1535 8.02634 8.1535 8.06634 8.16016C8.07967 8.16016 8.08634 8.16016 8.09967 8.16016C8.10634 8.16016 8.10634 8.16016 8.11301 8.16016C9.81967 8.10016 11.1597 6.7135 11.1663 5.00016C11.1663 3.2535 9.74634 1.8335 7.99967 1.8335Z" fill="currentColor"/>
<path d="M11.3866 9.93342C9.52664 8.69342 6.49331 8.69342 4.61997 9.93342C3.77331 10.5001 3.30664 11.2668 3.30664 12.0868C3.30664 12.9068 3.77331 13.6668 4.61331 14.2268C5.54664 14.8534 6.77331 15.1668 7.99997 15.1668C9.22664 15.1668 10.4533 14.8534 11.3866 14.2268C12.2266 13.6601 12.6933 12.9001 12.6933 12.0734C12.6866 11.2534 12.2266 10.4934 11.3866 9.93342Z" fill="currentColor"/>
</svg>


);

export default PersonFillIcon;
