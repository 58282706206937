import { createSlice } from '@reduxjs/toolkit';


const slice = createSlice({
    name: 'wizardData',
    initialState: {
        wizardData: {
fetchWizardState:false,
        },
        isThrWizard:{
            isWizard:false,
        },
        wizardKey:{
            accessKey:''
        },
        wizardHistory:{
            wizardUrl:''
        }
    },
    reducers: {
        setWizardState: (state, { payload }) => {
            state.wizardData = { ...state?.wizardData, ...payload };
        },
        setIsThroughWizard: (state, { payload }) => {
            state.isThrWizard = { ...state?.isThrWizard, ...payload };
        },
        setWizardKey: (state, { payload }) => {
            state.wizardKey = { ...state?.wizardKey, ...payload };
        },
        setWizardUrl: (state, { payload }) => {
            state.wizardHistory = { ...state?.wizardHistory, ...payload };
        },
    },
});

export const { setWizardState, setIsThroughWizard, setWizardKey, setWizardUrl } = slice?.actions;

export default slice?.reducer;

export const getHeaderWizardState = (state) => state?.WizardDataSlice?.wizardData;
export const getIsWizard = (state) => state?.WizardDataSlice?.isThrWizard;
export const getWizardKey = (state) => state?.WizardDataSlice?.wizardKey;
export const getWizardHistory= (state) => state?.WizardDataSlice?.wizardHistory;