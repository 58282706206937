import React from 'react';

const SpeakerSoundIcon = ({ size = 16, color = "#767476", ...props }) => (
  <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M9.00014 8.37514C8.92014 8.37514 8.84514 8.35014 8.77514 8.30014C8.61014 8.17514 8.57514 7.94014 8.70014 7.77514C9.48514 6.73014 9.48514 5.27014 8.70014 4.22514C8.57514 4.06014 8.61014 3.82514 8.77514 3.70014C8.94014 3.57514 9.17514 3.61014 9.30014 3.77514C10.2801 5.08514 10.2801 6.91514 9.30014 8.22514C9.22514 8.32514 9.11514 8.37514 9.00014 8.37514Z" fill={color} />
    <path d="M9.9142 9.62514C9.8342 9.62514 9.7592 9.60014 9.6892 9.55014C9.5242 9.42514 9.4892 9.19014 9.6142 9.02514C10.9492 7.24514 10.9492 4.75514 9.6142 2.97514C9.4892 2.81014 9.5242 2.57514 9.6892 2.45014C9.8542 2.32514 10.0892 2.36014 10.2142 2.52514C11.7492 4.57014 11.7492 7.43014 10.2142 9.47514C10.1442 9.57514 10.0292 9.62514 9.9142 9.62514Z" fill={color} />
    <path d="M7.01 1.88986C6.45 1.57986 5.735 1.65986 5.005 2.11486L3.545 3.02986C3.445 3.08986 3.33 3.12486 3.215 3.12486H2.75H2.5C1.29 3.12486 0.625 3.78986 0.625 4.99986V6.99986C0.625 8.20986 1.29 8.87486 2.5 8.87486H2.75H3.215C3.33 8.87486 3.445 8.90986 3.545 8.96986L5.005 9.88486C5.445 10.1599 5.875 10.2949 6.275 10.2949C6.535 10.2949 6.785 10.2349 7.01 10.1099C7.565 9.79986 7.875 9.15486 7.875 8.29486V3.70486C7.875 2.84486 7.565 2.19986 7.01 1.88986Z" fill={color} />
  </svg>


);

export default SpeakerSoundIcon;
