export const STATUSES = {
  COMPLETED: 45,
  NULL: 0,
  DECLINED: 12,
  PENDING: 1,
  NEW: 1,
  LIVE: 2,
  DRAFT: 6,
  ARCHIVED: 7,
  DELETE: 4,
  // For account
  ACTIVE: 16,
  //For OFFER
  REJECTED: 15,
  ACCEPTED: 5,
  PASS: 55,
  AWAITING_FEEDBACK: 41,
  SUSPENDED: 3,
  SCHEDULED: 101,
  PARTIALLY_LIVE: 102,
  INACTIVE: 17,
  APPROVED: 14,
  // For Job
  CLOSED: 21,
  FAILED: 19,
  PARSED: 53,
  JOB_TEMPLATE: 52,
  COPYWRITING: 104,
  SENT:22,
  EXPIRED:63,
  CANCELLED:36
};
export const QUALIFY_STATUSES = {
  DISQUALIFY: 1,
}
export const TEMPLATES = {
  NULL: 0,
  draft: 6,
  standard: 7,
  custom: 8,
};
export const MESSAGE_TYPES = {
  SMS: 1,
  EMAIL: 2,
};

export const EventAttendanceStatus = {
  ACCEPTED: 107,
  TENTATIVELY: 108,
  DECLINED: 109,
  NO_RESPONSE: 110,
}
export const FORM_TYPES = {
  CANDIDATE: 1,
  INTERNAL: 4,
};

export const MEDIA_FILE_TYPES = {
  VIDEO: 1,
  DOCUMENTS: 2,
  ATTACHMENTS: 3,
};

export const JOB_COPY_WRITING = {
  REQUESTED: 'REQUESTED',
  IN_PROGRESS: 'INPROGRESS',
  IN_REVIEW: 'INREVIEW',
  COMPLETED: 'COMPLETED',
  REVISION_REQUESTED: 'REVISIONREQUESTED',
};


export const EVENT_SOURCE = {
  OCCY: "OCCY",
  GOOGLE: "GOOGLE",
  OUTLOOK: "OUTLOOK",
}

export const EVENT_PROVIDER = {
  GOOGLE: "Google Meet",
  OUTLOOK: "Microsoft Office",
}