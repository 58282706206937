export const BgChecksTypes = [
  { label: 'Aviation ', value: '505' },
  { label: 'BS7858 Indirect No SIA', value: '503' },
  { label: 'BS7858 Indirect SIA & CRB', value: '504' },
  { label: 'BS7858 Indirect SIA', value: '502' },
  { label: 'BS7858 Direct No SIA', value: '500' },
  { label: 'BS7858 Direct SIA & CRB', value: '501' },
  { label: 'BS7858 Direct SIA', value: '499' },
  { label: 'Internal', value: '539' },
  { label: 'Internal DV', value: '544' },
  { label: 'External DV', value: '545' },
  { label: 'Serco Enhanced', value: '547' },
];
