import React from 'react';

const DashboardIcon = ({ size = 18 }) => {
  return (
   <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.5 19.795V3.99502C9.5 2.49502 8.86 1.89502 7.27 1.89502H3.23C1.64 1.89502 1 2.49502 1 3.99502V19.795C1 21.295 1.64 21.895 3.23 21.895H7.27C8.86 21.895 9.5 21.295 9.5 19.795Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M21 8.41502V3.87502C21 2.46502 20.36 1.89502 18.77 1.89502H14.73C13.14 1.89502 12.5 2.46502 12.5 3.87502V8.40502C12.5 9.82502 13.14 10.385 14.73 10.385H18.77C20.36 10.395 21 9.82502 21 8.41502Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M21 19.665V15.625C21 14.035 20.36 13.395 18.77 13.395H14.73C13.14 13.395 12.5 14.035 12.5 15.625V19.665C12.5 21.255 13.14 21.895 14.73 21.895H18.77C20.36 21.895 21 21.255 21 19.665Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  );
};

export default DashboardIcon;
