import React, { Suspense, useEffect, useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import useLicence from '../hooks/useLicence';
import AppOutlet from './AppOutlet';
import { Helmet } from 'react-helmet';
import LazyScreenLoader from './components/loader/LazyScreenLoader';
import { occyLs } from '../utils/localStorage';
import jwtDecode from 'jwt-decode';
import moment from 'moment';


function ProtectedRoute() {
  const { user } = useAuth();
  const { licence } = useLicence();

  useEffect(() => {

    window?.FreshworksWidget && window.FreshworksWidget('hide', 'launcher');

  }, [window?.FreshworksWidget])


  const tokenNotExpired = useMemo(() => {
    const token = occyLs.getObject('token');
    if (!token) {window.location.href = '/logout'; return false;}
    try {
      const tokenObj = jwtDecode(token);
      const activeTokenExpiry = moment.unix(tokenObj.exp).diff(moment(), 'minutes'); // token expiry in minutes
      if (activeTokenExpiry <= 0) {
        window.location.href = '/logout';
        return false;
      }
      return true;
    } catch (error) {
      console.error('Invalid token:', error);
      window.location.href = '/logout';
      return false;
    }
  }, [])

  if (user && tokenNotExpired) {
    if (licence?.data) {
      return (
        <>
          <Helmet>
            <script>
              {`
             window.fwSettings={
              'widget_id':101000007943
              };
              !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
            `}
            </script>
            <script type='text/javascript' src='https://euc-widget.freshworks.com/widgets/101000007943.js' async defer></script>
          </Helmet>
          {
            licence?.data?.accepted === 1 ? (
              <AppOutlet>
                <Suspense fallback={<LazyScreenLoader></LazyScreenLoader>}>
                  <Outlet />
                </Suspense>
              </AppOutlet>
            ) : (
              <Navigate to="/privacy-policy" />
            )
          }
        </>
      )
    }
  }

  return <Navigate to="/login" />;
}

export default ProtectedRoute;
