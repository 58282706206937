import React from 'react';

const CampaignsIcon = ({ size = 18 }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 10.895V14.895C5.5 16.895 6.5 17.895 8.5 17.895H9.93C10.3 17.895 10.67 18.005 10.99 18.195L13.91 20.025C16.43 21.605 18.5 20.455 18.5 17.485V8.30502C18.5 5.32502 16.43 4.18502 13.91 5.76502L10.99 7.59502C10.67 7.78502 10.3 7.89502 9.93 7.89502H8.5C6.5 7.89502 5.5 8.89502 5.5 10.895Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
};

export default CampaignsIcon;
