import React, { useState } from 'react';
import OccyLogoBlue1 from '../../app/components/svgIcons/OccyLogoBlue.png';
import { StyledLabel, Wrap } from '../../assets/styledComponents/Global';
import PortletBody from '../../app/components/portlet/PortletBody';
import CrossIcon from '../../app/components/svgIcons/CrossIcon';
import StyledButton from '../../app/components/styledComponents/StyledButton';
import { useUpdateUnsubscribtionMutation,useGetCompanyDetailsQuery } from './components/tosApiSlice';
import { useParams } from 'react-router-dom';
const UnsubscribeContainer = () => {
    const param = useParams();
    const {data:details,isLoading:dataLoading} = useGetCompanyDetailsQuery(param?.token,{
        skip: !param?.token,
      });
    const [unsubscribeSuccess, setUnsubscribeSUccess] = useState(false);
    const [unsubscibe,{isLoading:loading}] = useUpdateUnsubscribtionMutation();
    const handleUnsubscribe = () => {
        unsubscibe(param?.token).unwrap().then(() => {
            setUnsubscribeSUccess(true);
        });
    };
    return (
        <>
        {!dataLoading ? (
        <Wrap className="p-4">
            {(!unsubscribeSuccess && details?.candDetails?.isSubscribed) ? (
                <Wrap className="d-flex flex-column align-items-center justify-content-center">
                    <PortletBody className="col-7 d-flex flex-column align-items-center justify-content-center p-5 border">
                        <img
                            src={OccyLogoBlue1}
                            alt="img"
                            height={45}
                            width={170}
                        />
                        <PortletBody className="col-12 d-flex flex-column align-items-start border justify-content-between p-0 m-5">
                            <div className='d-flex align-items-center justify-content-between p-3 w-100'>
                                <StyledLabel size="32px" weight={'600'} >Unsubscribe</StyledLabel>
                                <div className='cursor-pointer' onClick={()=>{ window.location.href= window?.location?.origin}}><CrossIcon size={'28px'} /></div>
                            </div>

                            <PortletBody className="col-12 d-flex flex-column align-items-center justify-content-center bg-body rounded-0">
                                <div className='bg-white rounded p-3 w-100'>
                                    <StyledLabel weight={'400'} size={'16px'}>{`Are you sure you want to stop receiving job-related emails from ${details?.candDetails?.company?.name || ''} via occy.com?`} <br></br>
                                        Please note: if you have an active application, you will continue to receive emails. Confirm your choice by selecting the button below.</StyledLabel>
                                </div>
                                <Wrap className="d-flex justify-content-end align-items-end w-100 pt-4">
                                    <StyledButton
                                        variant="white"
                                        className="fw-bold text-secondary me-4"
                                        onClick={()=>{ window.location.href= window?.location?.origin}}
                                        disabled={loading}
                                    >
                                        Cancel
                                    </StyledButton>
                                    <StyledButton
                                        onClick={() => handleUnsubscribe()}
                                        disabled={loading}
                                        loading={loading}
                                    >
                                        Unsubscribe
                                    </StyledButton>
                                </Wrap>

                            </PortletBody>
                        </PortletBody>
                    </PortletBody>
                </Wrap>)
                : (
                    <Wrap className="d-flex flex-column align-items-center justify-content-center">
                        <PortletBody className="col-7 d-flex flex-column align-items-center justify-content-center p-0 border bg-transparent">
                            <PortletBody className="col-6 d-flex flex-column align-items-center justify-content-between">
                                <Wrap className='d-flex align-items-end justify-content-end w-100 cursor-pointer'>
                                    <Wrap onClick={()=>{ window.location.href= window?.location?.origin}}><CrossIcon size={'24px'} /></Wrap>
                                </Wrap>
                                <Wrap className='d-flex align-items-center justify-content-center p-3 w-100'>
                                    <StyledLabel size="24px" weight={'600'}>Unsubscribed!</StyledLabel>
                                </Wrap>
                                <StyledLabel size="16px" weight={'400'} className='pb-5'>{!details?.candDetails?.isSubscribed ? 'You have already unsubscribed' : 'We will miss you in our community!'} </StyledLabel>

                            </PortletBody>
                        </PortletBody>
                    </Wrap>
                )}
        </Wrap>)
        :
   (    <Wrap className="p-4">
       <Wrap className="d-flex flex-column align-items-center justify-content-center">
       <PortletBody className="col-7 d-flex flex-column align-items-center justify-content-center p-5 border">
                        <img
                            src={OccyLogoBlue1}
                            alt="img"
                            height={45}
                            width={170}
                        />
                        <PortletBody className="col-12 d-flex flex-column align-items-start border justify-content-between p-0 m-5">
                            <div className='d-flex align-items-center justify-content-center p-3 w-100 text-bg-primary fw-bold'>
                                Loading...
                                </div>
                                </PortletBody>
                                </PortletBody>
        </Wrap>
        </Wrap>)
    }
    </>
    );
};

export default UnsubscribeContainer;
