import StyledColoredTag from "../../../app/components/styledComponents/StyledColoredTag";
import { STATUSES } from "../../../utils/Statuses"
import theme from "../../../utils/themes";
import { currencySigns } from "../../kanban/components/hiringListing/hiringUtils";

export const HiringRequestTabs = [
    {
      id: 1,
      title: 'All Requests',
      active: true,
    //   status: undefined,
    },
    {
      id: 2,
      title: 'Pending',
      active: false,
      status: STATUSES.PENDING,
    },
    {
      id: 3,
      title: 'Approved',
      active: false,
      status: STATUSES.APPROVED,
    },
    {
      id: 4,
      title: 'Rejected',
      active: false,
      status: STATUSES.REJECTED,
    },
  ]


  export function getSalaryPeriod(period) {
    if (period === 'hour') {
      return 'hr';
    } else if (period === 'year') {
      return 'yr';
    } else if (period === 'annual') {
      return 'ann';
    } else if (period === 'month') {
      return 'mo';
    }
    else if (period === 'Annum') {
      return 'ann';
    } 
    else if (period === 'week') {
      return 'w';
    } else {
      return '';
    }
  }

  export const getTagColor = (status) => {
    if (status === STATUSES.LIVE) {
      return 'success';
    } else if (status === STATUSES.ACTIVE) {
      return 'success';
    } else if (status === STATUSES.PENDING) {
      return 'gold';
    } else if (status === STATUSES.COPYWRITING) {
      return 'gold';
    } else if (status === STATUSES.PARTIALLY_LIVE) {
      return 'tagBlueColor';
    } else if (status === STATUSES.SCHEDULED) {
      return 'pyramidOffered';
    } else if (status === STATUSES.FAILED) {
      return 'danger';
    } else if (status === STATUSES.REJECTED) {
      return 'danger';
    } else if (status === STATUSES.DRAFT) {
      return 'blue';
    } else if (status === STATUSES.CLOSED) {
      return 'defaultImage';
    } else return 'lightBoldBlack';
  };


  export const getTagColorBorder = (status) => {
    if (status === STATUSES.LIVE) {
      return 'success';
    } else if (status === STATUSES.ACTIVE) {
      return 'success';
    } else if (status === STATUSES.PENDING) {
      return 'gold';
    } else if (status === STATUSES.COPYWRITING) {
      return 'gold';
    } else if (status === STATUSES.PARTIALLY_LIVE) {
      return 'tagBlueColor';
    } else if (status === STATUSES.SCHEDULED) {
      return 'pyramidOffered';
    } else if (status === STATUSES.FAILED) {
      return 'danger';
    } else if (status === STATUSES.REJECTED) {
      return 'danger';
    } else if (status === STATUSES.DRAFT) {
      return 'darkBlue';
    } else if (status === STATUSES.CLOSED) {
      return 'danger';
    } else return 'lightBoldBlack';
  };

  export const formateSalary = (salary) =>  salary ? salary.toLocaleString('en-GB') : 0


  export const getSalaryInformation = (data) =>
    data?.salaryFrom || data?.salaryTo
      ? `${data?.salaryCurrency ? currencySigns[data?.salaryCurrency] : ''} ${
        formateSalary(data?.salaryFrom)
        }${`-${formateSalary(data?.salaryTo)}`} ${
          data?.salaryPeriod ? `/ ${getSalaryPeriod(data?.salaryPeriod)}` : ''
        }`
      : '-';


      export const getHiringStatusProperties = (status, standardLabels=false) => {
        switch (status) {
          case STATUSES.PENDING:
          case STATUSES.EXPIRED:
            return {
              label: standardLabels? 'Lapsed': 'Pending',
              textColor: theme.darkYellow,
              bgColor: theme.lightYellow,
            };
          case STATUSES.REJECTED:
            return {
              label: 'Rejected',
              textColor: theme.darkRed,
              bgColor: theme.evaluationBannerColor,
            };
          case STATUSES.ACCEPTED:
          case STATUSES.LIVE:
          case STATUSES.ACTIVE:
          case STATUSES.APPROVED:
          case STATUSES.PASS:
            return {
              label: standardLabels? 'Accepted': 'Approved',
              textColor: theme.green,
              bgColor: theme.skygreen,
            };
          case STATUSES.SENT:
            return {
              label: 'Sent',
              textColor: theme.darkYellow,
              bgColor: theme.lightYellow,
            };
          default:
            return {
              label: '-',
              textColor: '-',
              bgColor: '-',
            };
        }
      };


      export const hiringStatus = (data) => {
        const { label, textColor, bgColor } = getHiringStatusProperties(data?.hiringPlan?.statusId);
        return (
          <div className="d-flex align-items-center justify-content-center">
            <StyledColoredTag backgroundColor={bgColor} color={textColor} $width='max-content' $marginX='0'>
              {label}
            </StyledColoredTag>
          </div>
        );
      }



      export const hiringStatuses = [
        {
          label: 'Approved',
          value: STATUSES.LIVE,
        },
        {
          label: 'Pending',
          value: STATUSES.PENDING,
        },
        {
          label: 'Closed',
          value: STATUSES.CLOSED,
        },
      ];



      export const hiringSort = [
        {
          id: 1,
          label: 'None',
          value: 'createdAt',
          dir: 'DESC',
          match: "checked1"
        },
        {
          id: 2,
          label: 'Created (Oldest to Newest)',
          value: 'createdAt',
          dir: 'ASC',
          match: "checked2"
        },
        {
          id: 3,
          label: 'Created (Newest to Oldest)',
          value: 'createdAt',
          dir: 'DESC',
          match: "checked3"
        },
      ];


     export const getAccountByRole = (accounts, role) =>
        accounts?.length
          ? accounts?.filter((account) => account?.role?.toUpperCase() === role?.toUpperCase())
          : [];
    
  