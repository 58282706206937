import React, { useEffect,  Suspense } from 'react';
import './_sidebar.scss';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { SideMenuAccess } from '../../../midleware/midleware';
import { sideMenuState } from '../../../features/auth/authSlice';
import { occyLs } from '../../../utils/localStorage';
import { useDispatch } from 'react-redux';
// import ChartIcon from '../../components/svgIcons/ChartIcon';
import AnalyticsIcon from '../../components/svgIcons/AnalyticsIcon';
import CopyrightIcon from '../../components/svgIcons/CopyrightIcon';
import HelpIcon from '../../components/svgIcons/QuestionMark';
import HiringRequestIcon from '../../components/svgIcons/HiringRequestIcon';
import SlotsIcon from '../../components/svgIcons/SlotsIcon';
import { setEventType } from '../../../features/Setting/AccountCalender/AccountSlice';
import { useGetAvailabilityQuery } from '../../../features/Setting/AccountCalender/CalenderApiSlice';
import { USERROLES } from '../../../features/users/creation/assets/utills';
import OccyLogoTransparent from '../../components/svgIcons/OccyLogoTransparent.png';
import DashboardIcon from '../../components/svgIcons/DashboardIcon';
import JobsIcon from '../../components/svgIcons/JobsIcon';
import CampaignsIcon from '../../components/svgIcons/CampaignsIcon';
import CandidatesIcon from '../../components/svgIcons/CandidatesIcon';
import SettingsIcon from '../../components/svgIcons/SettingsIcon';
import OpenSidebarIcon from '../../components/svgIcons/OpenSidebarIcon';
import CalendarFillIcon from '../../components/svgIcons/CalenderFillIcon';
import SideBarIcon from '../../components/svgIcons/SidebarIcon';
import useAuth from '../../../hooks/useAuth';
import UniHatIcon from '../../components/svgIcons/UniHatIcon';
import OfferAgreementDashboardIcon from '../../components/svgIcons/OfferAgreementDashboardIcon';
// import GlobeUnFilledIcon from '../../components/svgIcons/GlobeUnFilledIcon';
const SideBar = ({ openSideMenu, setOpenSideMenu }) => {
  const {user} = useAuth();
  const { data: availability } =useGetAvailabilityQuery();
  useEffect(()=>{availability&&dispatch(setEventType(availability?.autoAvailability===false?2:1))},[availability])
  const sidebarVisible = !!occyLs.getObject('APP_SIDEBAR');
  const dispatch = useDispatch();
  const MenuItems = [
    {
      name: 'Dashboard',
      icon: <Suspense fallback={<div>Loading...</div>}> <DashboardIcon /></Suspense>,
      to: 'dashboard',
      authorizedRoles: [USERROLES.ADMIN, USERROLES.RECRUITER,USERROLES.INTERVIEWER,USERROLES.HIRINGMANAGER,USERROLES.BILLINGADMIN, USERROLES.APPROVER],
      childUser: true,
    },
    {
      name: 'Jobs',
      icon: <Suspense fallback={<div>Loading...</div>}> <JobsIcon /></Suspense>,
      to: 'jobs',
      authorizedRoles: [USERROLES.ADMIN, USERROLES.RECRUITER,USERROLES.HIRINGMANAGER,USERROLES.INTERVIEWER],
      childUser: false,
    },
    {
      name: 'Hiring Request',
      icon: <Suspense fallback={<div>Loading...</div>}> <HiringRequestIcon /></Suspense>,
      to: 'approvers',
      authorizedRoles: [USERROLES.APPROVER],
      nobasicAllowed: true
    },
    {
      name: 'Offers & Agreements',
      icon: <Suspense fallback={<div>Loading...</div>}> <OfferAgreementDashboardIcon /></Suspense>,
      to: 'offers-and-agreements',
      authorizedRoles: [USERROLES.ADMIN, USERROLES.RECRUITER],
      nobasicAllowed: true
    },
    {
      name: 'Campaigns',
      icon: <Suspense fallback={<div>Loading...</div>}> <CampaignsIcon /></Suspense>,
      to: 'campaigns',
      authorizedRoles: (user?.company?.superCompanyId === process.env.REACT_APP_SECURITAS_ID) ? [USERROLES.ADMIN, USERROLES.RECRUITER,USERROLES.HIRINGMANAGER] : [USERROLES.ADMIN, USERROLES.RECRUITER],
      nobasicAllowed: true
      
    },
    {
      name: 'Candidates',
      icon: <Suspense fallback={<div>Loading...</div>}> <CandidatesIcon size={23}/></Suspense>,
      to: 'candidates', 
      authorizedRoles: [USERROLES.ADMIN, USERROLES.RECRUITER, USERROLES.HIRINGMANAGER],
      nobasicAllowed: true
      
    },
    // {
    //   name: 'Mailbox',
    //   icon: <Suspense fallback={<div>Loading...</div>}> <MailBoxIcon />,
    //   to: 'mails',
    //   authorizedRoles: [USERROLES.ADMIN, USERROLES.RECRUITER],
    //   
    // },
    {
      name: 'Calendar',
      icon: <Suspense fallback={<div>Loading...</div>}> <CalendarFillIcon /></Suspense>,
      to: 'calendar',
      authorizedRoles: [USERROLES.ADMIN, USERROLES.RECRUITER,USERROLES.INTERVIEWER,USERROLES.HIRINGMANAGER],
      
    },
    {
      name: 'Copywriting',
      icon: <Suspense fallback={<div>Loading...</div>}> <CopyrightIcon /></Suspense>,
      to: 'copywrite',
      authorizedRoles: [USERROLES.ADMIN, USERROLES.RECRUITER],
      nobasicAllowed: true
      
    },
    {
      name: 'Analytics',
      icon: <Suspense fallback={<div>Loading...</div>}> <AnalyticsIcon /></Suspense>,
      to: 'analytics',
      authorizedRoles: [USERROLES.ADMIN, USERROLES.RECRUITER],
      nobasicAllowed: true
      
    },
    {
      name: 'Settings',
      icon: <Suspense fallback={<div>Loading...</div>}> <SettingsIcon size="24px" /></Suspense>,
      to: 'settings',
      childUser: true,
    },
   {
      name: 'Slots',
      icon: <Suspense fallback={<div>Loading...</div>}> <SlotsIcon size="24px" /></Suspense>,
      to: 'slots',
      authorizedRoles: [USERROLES.ADMIN, USERROLES.RECRUITER],
    },
    {
      name: 'University',
      icon: <Suspense fallback={<div>Loading...</div>}> <UniHatIcon size="24px" /></Suspense>,
      to: 'https://occy-university.northpass.com',
    },
    {
      name: 'Help',
      icon: <Suspense fallback={<div>Loading...</div>}> <HelpIcon size="24px" /></Suspense>,
      to: '',
      action: () => {
        openWidget();
      }
    },
  ];
    const  openWidget = (event) => {
      window.FreshworksWidget("open");   
      }

  return (
    <>
        <div
          className={` ${sidebarVisible ? 'openSidebar' : 'closeSidebar'} vh-100 bg-primary p-0 position-fixed`}
        >
          <div
            className={`${sidebarVisible
              ? 'd-flex justify-content-between align-items-center px-4 py-4'
              : 'justify-content-center d-flex pt-4 pb-4'}`}
          >
            {sidebarVisible ?  
            // <OccyLogoWhite/>
            <img
                              src={OccyLogoTransparent}
                              alt="img"
                              height={40}
                              width={150}
                              />
                            : null}
            <div
              className="menuButton p-1 rounded-circle cursor-pointer"
              onClick={() => {
                dispatch(sideMenuState(!sidebarVisible));
                setOpenSideMenu(!openSideMenu);
              } }
            >
              {sidebarVisible ? <OpenSidebarIcon /> : <SideBarIcon />}
            </div>
          </div>
          <hr className={sidebarVisible ? 'mx-4 pb-1 ' : 'mx-2 pb-1'} />
          <StyledSideMenu>
            {MenuItems.map((item, index) => {
              return (
                SideMenuAccess(item) &&
                <NavLink
                  key={index}
                  target={item?.name === 'University' ? '_blank' : undefined}
                  to={(item?.name === 'Help') ? undefined : item?.to}
                  className={({ isActive }) => `${(isActive && (item?.name !== 'Help' && item?.name !== 'University')) ? 'text-white' : 'tab-link'} ${sidebarVisible
                    ? 'd-flex align-items-center px-4 ms-2'
                    : 'justify-content-center d-flex py-4 '} text-decoration-none space `}
                 onClick={item?.action}
                >
                  <div>{item.icon}</div>
                  {sidebarVisible ? (
                    <p className="ms-3 mt-1 MenuText mb-0">{item.name}</p>
                  ) : null}
                </NavLink>
              );
            })}
          </StyledSideMenu>
        </div>
      </>
  );
};
const StyledSideMenu = styled.div`
  .tab-link {
    color: ${({ theme }) => theme.primaryLight2};
  }
  .space{
    padding:20px 0 20px 0;
  }
  max-height: 80%;
  overflow: auto;
  position: relative;
  @-moz-document url-prefix() {
    scrollbar-width: thin;
    scrollbar-color: #4a154b transparent;
  }
  ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb:hover,  ::-webkit-scrollbar {
    background: #4a154b; 
}
`;
export default SideBar;
