import React from 'react';
import styled from 'styled-components';
import Proptypes from 'prop-types';

const StyledText = styled(({ children, as: Component, ...props }) => (
  <Component {...props}>{children}</Component>
))`
  font-size: ${(props) => props?.assessment ? "14px" : props.$fontSize};
  color: ${(props) =>
    props.value
      ? props?.theme?.backgroundColor
      : props?.assessment ? props.theme.textColor : props.theme.primary};

  font-weight: ${(props) => props.$fontWeight};
  opacity: ${(props) => props.opacity || 1};

  &:hover {
    color: ${(props) =>
      props.value
        ? props?.theme?.backgroundColor
        : props?.assessment ? props.theme.textColor : props.theme.primary};
  }
`;

StyledText.propTypes = {
  as: Proptypes.oneOfType([
    Proptypes.string,
    Proptypes.node,
    Proptypes.element,
    Proptypes.object,
  ]),
  $fontSize: Proptypes.string,
  $fontWeight: Proptypes.string,
  opacity: Proptypes.string,
};
export default StyledText;
