import React from 'react';

const MailBoxIcon = ({ size = 18 }) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22 11.395V16.395C22 19.895 20 21.395 17 21.395H7C4 21.395 2 19.895 2 16.395V9.39502C2 5.89502 4 4.39502 7 4.39502H14" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7 9.89502L10.13 12.395C11.16 13.215 12.85 13.215 13.88 12.395L15.06 11.455" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M19.5 8.89502C20.8807 8.89502 22 7.77573 22 6.39502C22 5.01431 20.8807 3.89502 19.5 3.89502C18.1193 3.89502 17 5.01431 17 6.39502C17 7.77573 18.1193 8.89502 19.5 8.89502Z" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  );
};

export default MailBoxIcon;
