import React from 'react';
import { ErrorMessage, Field } from 'formik';
import Proptypes from 'prop-types';
import StyledTextEditor from '../textEditor/StyledTextEditor';
import { formErrorMessage } from './FormErrorMessage';

const FormikTextEditor = ({
  name,
  className,
  isReadOnly,
  noToolBar,
  showError = true,
  ...props
}) => (
  <Field name={name}>
    {({ field, form: { setFieldValue, touched, errors } }) => (
      <>
        <StyledTextEditor
          {...field}
          onChange={(values) => {
            if (values === '<p><br></p>') {
              values = '';
            }
            setFieldValue(name, values);
          }}
          onPaste={(values) => {
            // if (values === '<p><br></p>') {
            //   values = '';
            // }
            // setFieldValue(name, values);
          }}
          isValid={touched[field.name] && !errors[field.name]}
          isInvalid={touched[field.name] && errors[field.name]}
          isReadOnly={isReadOnly}
          noToolBar={noToolBar}
          {...props}
        />
        {showError && (
          <ErrorMessage name={field.name} render={formErrorMessage} />
        )}
      </>
    )}
  </Field>
);

FormikTextEditor.propTypes = {
  name: Proptypes.string.isRequired,
  placeholder: Proptypes.string,
  type: Proptypes.string,
  as: Proptypes.string,
  rows: Proptypes.string,
};
export default React.memo(FormikTextEditor);
