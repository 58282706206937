import React from 'react';

const NotificationBellIcon = ({ size = 25, ...props }) => (
  <svg width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0201 2.91003C8.71009 2.91003 6.02009 5.60003 6.02009 8.91003V11.8C6.02009 12.41 5.76009 13.34 5.45009 13.86L4.30009 15.77C3.59009 16.95 4.08009 18.26 5.38009 18.7C9.69009 20.14 14.3401 20.14 18.6501 18.7C19.8601 18.3 20.3901 16.87 19.7301 15.77L18.5801 13.86C18.2801 13.34 18.0201 12.41 18.0201 11.8V8.91003C18.0201 5.61003 15.3201 2.91003 12.0201 2.91003Z" stroke="#5D6473" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M15.02 20.0605C15.02 21.7105 13.67 23.0605 12.02 23.0605C11.2 23.0605 10.44 22.7205 9.90002 22.1805C9.36002 21.6405 9.02002 20.8805 9.02002 20.0605" stroke="#5D6473" strokeWidth="2" strokeMiterlimit="10" />
  </svg>

);

export default NotificationBellIcon;
