import React, { useState } from 'react';
import Portlet from '../../app/components/portlet/Portlet';
import PortletBody from '../../app/components/portlet/PortletBody';
import StyledIconInput from '../../app/components/styledComponents/StyledIconInput';
import SearchIcon from '../../app/components/svgIcons/SearchIcon';
import { jobTypes } from './CareerPageUtils/careerPageUtils';
import FormikSelect from '../../app/components/formikComponents/FormikSelect';
import {  Container, Row } from 'react-bootstrap';
import { Wrap, StyledLabel } from '../../assets/styledComponents/Global';
import { Form, Formik } from 'formik';
import StyledSwitch from '../../app/components/styledComponents/StyledSwitch';
import CareerPageTable from './Components/CareerPageTable';
import { useParams } from 'react-router-dom';
import { useGetAllPublicJobsQuery } from './PublicPageApi';
import PaginationPerPage from '../../app/components/pagination/PaginationPerPage';
import CompanyAvatar from '../../app/components/svgIcons/CompanyAvatar.svg';
import { Helmet } from 'react-helmet';
import RedirectIcon from '../../app/components/svgIcons/RedirectIcon';
import { useWindowSize } from '../../utils/helper';
import OccyLogoBlue1 from '../../app/components/svgIcons/OccyLogoBlue.png';
import DotIcon from '../../app/components/svgIcons/DotIcon';
import styled from 'styled-components';
import theme from '../../utils/themes';
import { getPagination } from '../../app/components/pagination/paginationSlice';
import { useSelector } from 'react-redux';
import { LISTING_PER_PAGE } from '../../config';

const CareerPublicPreview = () => {
  const initialFilters = {
    jobs: [],
    perPage: LISTING_PER_PAGE,
    page: 1,
    sortedColumn: { path: 'createdAt', order: 'DESC' },
    query: '',
  };
  const isSmallScreen = useWindowSize();
  const { companyId } = useParams();
  const paginate = useSelector(getPagination)?.find(i => i?.access_key === 'careerPublicPreviewPg')
  const [listing, setListing] = useState({ ...initialFilters, ...paginate });
  const { data: publicJobs, isFetching } = useGetAllPublicJobsQuery({
    perPage: listing.perPage,
    page: listing.page,
    query: listing.query && listing.query,
    sort: listing.sortedColumn.path,
    sortDir: listing.sortedColumn.order,
    companyId: companyId,
  });
  const [timer, setSearchTimer] = useState(null);
  const handleSearch = (evt) => {
    clearTimeout(timer);
    const query = evt.target.value;
    const newTimer = setTimeout(() => {
      setListing({ ...listing, page: 1, query: query });
    }, 2000);
    setSearchTimer(newTimer);
  };
  const handlePerPageChange = (perPage) => {
    setListing((prevState) => ({
      ...prevState,
      perPage: perPage.target.innerText,
      page: 1,
    }));
  };

  const handlePageChange = ({ selected }) => {
    setListing((prevState) => ({
      ...prevState,
      page: selected + 1,
    }));
  };

  return (
    // showFilter ? (
    //   <MobileFilterView setShowFilter={setShowFilter}/>
    // ) : (
    <>
      <Helmet>
        <title>
          {publicJobs?.company?.name
            ? publicJobs?.company?.name + ` - Jobs`
            : 'Jobs'}
        </title>
      </Helmet>
      {/* <div className="row bg-body"> */}
      <div className={`col px-0`}>
        <Portlet>
          <Wrap className="d-flex align-items-center justify-content-center justify-content-md-start ms-lg-5 p-4 gap-2">
            <img
              src={
                publicJobs?.company?.compLOGOSignedUrl
                  ? publicJobs?.company?.compLOGOSignedUrl
                  : CompanyAvatar
              }
              width="100"
              height="20%"
              alt={'company-logo'}
            />
            <StyledLabel size="22px">{publicJobs?.company?.name}</StyledLabel>
          </Wrap>
          <div className="public_portlet__body p-4 ">
            <StyledLabel size="20px" className='mb-3 d-sm-block d-sm-none'>Open Positions</StyledLabel>

            <PortletBody className="rounded-3 p-3 p-lg-4 mx-lg-5 my-lg-4">
              <>
                <StyledLabel size="22px" className="mb-3 d-none d-md-block">
                  Open Positions
                </StyledLabel>
                <Wrap className="d-flex gap-3">
                  <StyledIconInput
                    $background={isSmallScreen && 'white'}
                    icon={<SearchIcon />}
                    className="col"
                    $maxWidth="100%"
                    onChange={handleSearch}
                    placeholder="Search"
                    loading={isFetching}
                  />

                  {/* <Wrap className="d-block d-md-none">
                    <StyledButton
                      icon={<SortIcon />}
                      variant="icon"
                      onClick={() => setShowFilter(true)}
                    />
                  </Wrap> */}
                </Wrap>
                <Formik>
                  {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                      <Container fluid className="mt-3 feature-pending">
                        <Row>
                          <div className="col-12 col-md-2">
                            <div className="mb-3">
                              <FormikSelect
                                name="Department44"
                                id="Department12"
                                placeholder="Select department"
                                options={jobTypes}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-2">
                            <div className="mb-3">
                              <FormikSelect
                                name="Department44"
                                id="Department12"
                                placeholder="Select Location"
                                options={jobTypes}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-2">
                            <div className="mb-3">
                              <FormikSelect
                                name="Department44"
                                id="Department12"
                                placeholder="Select Radius"
                                options={jobTypes}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-2">
                            <div className="mb-3">
                              <FormikSelect
                                name="Department44"
                                id="Department12"
                                placeholder="Select department"
                                options={jobTypes}
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-2">
                            <div className="mb-3">
                              <Wrap className=" align-items-center">
                                <StyledLabel weight="400" className="me-2">
                                  Remote Only
                                </StyledLabel>
                                <StyledSwitch
                                // isToggle={values.isLive}
                                // onToggle={() => setFieldValue('isLive', !values.isLive)}
                                />
                              </Wrap>
                            </div>
                          </div>
                        </Row>
                      </Container>
                    </Form>
                  )}
                </Formik>
                {publicJobs && (
                  <CareerPageTable CareerPageTableData={publicJobs?.jobs} />
                )}
                {publicJobs && (
                  <PaginationPerPage
                    align="end"
                    perPage={publicJobs?.perPage || LISTING_PER_PAGE}
                    pageNo={publicJobs?.page || 1}
                    onPerPageChange={handlePerPageChange}
                    totalCount={publicJobs?.total}
                    onPageChange={handlePageChange}
                    access_key="careerPublicPreviewPg"
                    paginate={paginate}
                  />
                )}
              </>
            </PortletBody>
          </div>
        </Portlet>
        <Wrap fluid>
          <StyledContainerWrap

          // backgroundSettings={backgroundSettings}
          // cardAlignment={cardAlignment}
          // contentAlignment={contentAlignment}
          >
            <StyledLinkWrap className="d-flex align-items-center gap-5 w-10">
              <div className='d-flex justify-content-center align-items-center w-100 flex-column flex-md-row'>
                <div ><label className='fs-5'>Powered By</label></div>
                <div className='ps-2'>
                  {/* <OccyLogoBlue /> */}
                  <img
                    src={OccyLogoBlue1}
                    alt="img"
                    height={45}
                    width={170}
                  />
                </div>
                <div className='ps-3 d-none d-md-block'>
                  <DotIcon />
                </div>
                <div className='d-flex justify-content-center align-items-center'><label className='ps-3 fs-5'>Privacy & Policy</label>
                  <a
rel="noreferrer"
                    color="descriptionTextColor"
                    href={`https://occy.com/all-policies`}
                    target={'_blank'}
                  >
                    <div className='ps-2'>
                      <RedirectIcon />
                    </div>
                  </a>
                </div>
              </div>
            </StyledLinkWrap>
          </StyledContainerWrap>
        </Wrap>
      </div>

      {/* <div className="col app_container px-0">
          <Container fluid className="p-0">
            <h1>laskdnasdkl</h1>
          </Container>
        </div> */}
      {/* </div> */}
    </>
    // );
  );
};
const StyledContainerWrap = styled.div`
margin-top: auto;
    display: flex;
  background: ${({ theme }) =>
    theme?.bgColor
      ? theme?.bgColor ?? theme.white
      : theme?.backgroundSettings?.imageURL
        ? `url(${theme?.backgroundSettings?.imageURL})`
        : theme.white};
  display: flex;
  flex-direction: ${({ theme }) =>
    theme?.cardAlignment ? theme?.cardAlignment : 'column'};
  align-items: ${({ theme }) =>
    theme?.contentAlignment ? theme?.contentAlignment : 'center'};
  text-align: center;
  gap: 8px;
  width: ${({ theme }) => (theme?.width ? theme?.width : '100%')};
padding:10px;
  cursor: pointer;
  @media (max-width: 768px) {
    
    margin-bottom: 90px;
    padding: 20px;
  }
`;
const StyledLinkWrap = styled.div`
  color: ${theme.headingColor};
  font-weight: 500;
  font-size: 18px;

  & > a {
    // color: ${({ theme }) =>
    theme.color ? theme.color : theme.headingColor};
    text-decoration: none;
  }
`;

export default CareerPublicPreview;
