import { apiSlice } from '../../app/services/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllPublicJobs: builder.query({
      query: (params) => ({
        url: `public/jobs`,
        params,
      }),
    }),

    getSinglePublicJobById: builder.query({
      query: (id) => `public/jobs/${id}`,
    }),
     getSinglePublicJobByJpId: builder.query({
      query: (id) => `public/jobs/posting/${id}/details`,
    }),
    jobApplyAdvert: builder.mutation({
      query: (data) => ({
        url: `public/candidates`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    preSignedPublicUrl: builder.mutation({
      query: (data) => ({
        url: `public/candidates/presigned-doc`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    preSignedPublicFormsUrl: builder.mutation({
      query: (data) => ({
        url: `public/forms/presigned`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
  }),
});

export const {
  useGetAllPublicJobsQuery,
  useGetSinglePublicJobByIdQuery,
  useGetSinglePublicJobByJpIdQuery,
  useJobApplyAdvertMutation,
  usePreSignedPublicUrlMutation,
  usePreSignedPublicFormsUrlMutation,
} = extendedApiSlice;
