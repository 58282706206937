
import theme from "../../../utils/themes";




export const CALENDER_EVENT_STATUSES = {
  EVENT: 1,
  INTERVIEW: 2,
  MEETING: 3
};



export const CALENDER_EVENT_COLORS = {
  OTHER: theme.eventColor1,
  INTERVIEW: theme.eventColor2,
  MEETING: theme.eventColor3
};


export  const OC_CALENDAR_TYPE = {
  OFFICE365 : "OFFICE365",
  GOOGLE_CALENDAR : "GOOGLE_CALENDAR"
}