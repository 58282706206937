import React from 'react';

const PortletBody = ({ bgColor, withBg, className = '', style, children, ...props }) => (
  <div
    className={`${className} portlet__body${withBg ? '-bg' : ''}`}
    style={{ ...style, backgroundColor: bgColor ? bgColor : '' }}
    {...props}
  >
    {children}
  </div>
);

export default PortletBody;
