import React from 'react';

const TickCircleIconOpen = ({ size = 18, color = "#4A154B",fill, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill={fill??'none'} xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M8.00016 14.6666C11.6668 14.6666 14.6668 11.6666 14.6668 7.99992C14.6668 4.33325 11.6668 1.33325 8.00016 1.33325C4.3335 1.33325 1.3335 4.33325 1.3335 7.99992C1.3335 11.6666 4.3335 14.6666 8.00016 14.6666Z" stroke={fill?fill:color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.1665 7.99995L7.05317 9.88661L10.8332 6.11328" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>


  );
};

export default TickCircleIconOpen;
