import styled from 'styled-components';
export const StyledCustomLoader = styled.div`
  border: 2.5px solid white;
  border-radius: 50%;
  border-top: 2.5px solid #4a154b;
  border-bottom: 2.5px solid #4a154b;
  border-left: 2.5px solid #4a154b;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  z-index:${({zIndex})=> zIndex ?? 0};
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
