import React from 'react';

const MultipleBoxIcon = ({ size, ...props }) => (
    <svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M19.67 6.64002C19.24 4.47002 17.63 3.52002 15.39 3.52002H6.60996C3.96996 3.52002 2.20996 4.84002 2.20996 7.92002V13.07C2.20996 15.29 3.11996 16.59 4.61996 17.15C4.83996 17.23 5.07996 17.3 5.32996 17.34C5.72996 17.43 6.15996 17.47 6.60996 17.47H15.4C18.04 17.47 19.8 16.15 19.8 13.07V7.92002C19.8 7.45002 19.76 7.03002 19.67 6.64002ZM6.02996 12C6.02996 12.41 5.68996 12.75 5.27996 12.75C4.86996 12.75 4.52996 12.41 4.52996 12V9.00002C4.52996 8.59002 4.86996 8.25002 5.27996 8.25002C5.68996 8.25002 6.02996 8.59002 6.02996 9.00002V12ZM11 13.14C9.53996 13.14 8.35996 11.96 8.35996 10.5C8.35996 9.04002 9.53996 7.86002 11 7.86002C12.46 7.86002 13.64 9.04002 13.64 10.5C13.64 11.96 12.46 13.14 11 13.14ZM17.46 12C17.46 12.41 17.12 12.75 16.71 12.75C16.3 12.75 15.96 12.41 15.96 12V9.00002C15.96 8.59002 16.3 8.25002 16.71 8.25002C17.12 8.25002 17.46 8.59002 17.46 9.00002V12Z" fill="#4A154B" />
        <path d="M22.7998 10.92V16.07C22.7998 19.15 21.0398 20.48 18.3898 20.48H9.60977C8.85977 20.48 8.18977 20.37 7.60977 20.15C7.13977 19.98 6.72977 19.73 6.39977 19.41C6.21977 19.24 6.35977 18.97 6.60977 18.97H15.3898C19.0898 18.97 21.2898 16.77 21.2898 13.08V7.92003C21.2898 7.68003 21.5598 7.53003 21.7298 7.71003C22.4098 8.43003 22.7998 9.48003 22.7998 10.92Z" fill="#4A154B" />
    </svg>


);


export default MultipleBoxIcon;
