import React from 'react';
import { Modal } from 'react-bootstrap';
import ContactUsFeedbackIcon from '../../../app/components/svgIcons/ContactUsFeedbackIcon';
import CrossIcon from '../../../app/components/svgIcons/CrossIcon';
import { StyledLabel, Wrap } from '../../../assets/styledComponents/Global';

const ContactusModal = ({ show, onHide }) => {
  return (
    <Modal
      show={show}
      centered
      onHide={onHide}
      size={'lg'}
      backdrop="static"
      className="bg-black bg-opacity-50"
    >
      <Modal.Header className=" bg-body d-flex align-items-end justify-content-end border-0">
        <CrossIcon onClick={onHide} className="cursor-pointer" />
      </Modal.Header>
      <Modal.Body className="d-flex  flex-column bg-body rounded">
        <Wrap className="d-flex align-items-center justify-content-center flex-column my-4">
          <ContactUsFeedbackIcon />
          <StyledLabel size="20px" className="mt-5 mb-2 text-center">
            Your message has been submitted{' '}
          </StyledLabel>
          <StyledLabel weight="500px" className="text-center">
            Our hiring team personnel will get in touch with you shortly
          </StyledLabel>

          {/* <StyledButton variant="white" className="mt-4" onClick={onHide}>
            Close
          </StyledButton> */}
        </Wrap>
      </Modal.Body>
    </Modal>
  );
};

export default ContactusModal;
