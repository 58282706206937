import React from 'react';

const MultipleChoiceIcon = ({ size = 16, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.3332 3.56675C14.3332 4.17341 14.0465 4.71341 13.6065 5.06008C13.2865 5.31341 12.8799 5.46675 12.4332 5.46675C11.3799 5.46675 10.5332 4.62008 10.5332 3.56675C10.5332 3.12008 10.6865 2.72008 10.9399 2.39341H10.9465C11.2865 1.95341 11.8265 1.66675 12.4332 1.66675C13.4865 1.66675 14.3332 2.51341 14.3332 3.56675Z"
      stroke="#3D5F91"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.46699 3.56675C5.46699 4.62008 4.62033 5.46675 3.56699 5.46675C3.12033 5.46675 2.72033 5.31341 2.39366 5.06008C1.95366 4.71341 1.66699 4.17341 1.66699 3.56675C1.66699 2.51341 2.51366 1.66675 3.56699 1.66675C4.17366 1.66675 4.71366 1.95341 5.06033 2.39341C5.31366 2.72008 5.46699 3.12008 5.46699 3.56675Z"
      stroke="#3D5F91"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />

    <path
      d="M14.3332 12.4334C14.3332 13.4868 13.4865 14.3334 12.4332 14.3334C11.8265 14.3334 11.2865 14.0468 10.9465 13.6068H10.9399C10.6865 13.2868 10.5332 12.8801 10.5332 12.4334C10.5332 11.3801 11.3799 10.5334 12.4332 10.5334C12.8799 10.5334 13.2799 10.6868 13.6065 10.9401V10.9468C14.0465 11.2868 14.3332 11.8268 14.3332 12.4334Z"
      stroke="#3D5F91"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.46699 12.4334C5.46699 12.8801 5.31366 13.2801 5.06033 13.6068C4.71366 14.0534 4.17366 14.3334 3.56699 14.3334C2.51366 14.3334 1.66699 13.4868 1.66699 12.4334C1.66699 11.8268 1.95366 11.2868 2.39366 10.9468V10.9401C2.71366 10.6868 3.12033 10.5334 3.56699 10.5334C4.62033 10.5334 5.46699 11.3801 5.46699 12.4334Z"
      stroke="#3D5F91"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3337 8.00008C14.3337 9.06675 14.0737 10.0601 13.607 10.9401C13.287 10.6867 12.8803 10.5334 12.4337 10.5334C11.3803 10.5334 10.5337 11.3801 10.5337 12.4334C10.5337 12.8801 10.687 13.2801 10.9403 13.6067C10.0603 14.0734 9.06699 14.3334 8.00033 14.3334C6.94033 14.3334 5.94033 14.0734 5.06033 13.6067C5.31366 13.2867 5.46699 12.8801 5.46699 12.4334C5.46699 11.3801 4.62033 10.5334 3.56699 10.5334C3.12033 10.5334 2.72033 10.6867 2.39366 10.9401C1.92699 10.0601 1.66699 9.06675 1.66699 8.00008C1.66699 6.94008 1.92699 5.94008 2.39366 5.06008C2.72033 5.31341 3.12033 5.46675 3.56699 5.46675C4.62033 5.46675 5.46699 4.62008 5.46699 3.56675C5.46699 3.12008 5.31366 2.72008 5.06033 2.39341C5.94033 1.92675 6.94033 1.66675 8.00033 1.66675C9.06699 1.66675 10.0603 1.92675 10.9403 2.39341C10.687 2.71341 10.5337 3.12008 10.5337 3.56675C10.5337 4.62008 11.3803 5.46675 12.4337 5.46675C12.8803 5.46675 13.2803 5.31341 13.607 5.06008C14.0737 5.94008 14.3337 6.94008 14.3337 8.00008Z"
      stroke="#5F82B6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MultipleChoiceIcon;
