import { createSlice } from '@reduxjs/toolkit';
import theme from '../../../utils/themes';
import { isArray, sortBy} from 'lodash';
import { STATUSES } from '../../../utils/Statuses';
import { ControlTypes } from '../../../utils/helper';

const formsInitialState = {
  form: {
    compFormSetting: {
      logo: null,
      banner: null,
      color: theme.primaryLight2,
      backgroundColor: theme.primary,
      fontFamily: 'Quick Sand',
      labelAlignment: 'left',
      customCss: '',
      formType: null,
      formViewType: 'standard',
      companyInfo: false,
    },
    refOpts: {
      isAccepting: true,
      expireAfter: 21,
    },
    sections: [],
  },
};
const slice = createSlice({
  name: 'formDesign',
  initialState: { ...formsInitialState },
  reducers: {
    setFormData: (state, { payload: { form } }) => {
      let sequence = 0;
      let formQuestions = form?.formQuestions || [];
      formQuestions = sortBy(
        formQuestions?.filter(
          (f) => f.statusId !== STATUSES.DELETE && !f?.isSensitive,
        ),
        ['sectionOrder'],
      ).map((q, i) => {
        return {
          ...q,
          index:
            q.sectionType !== ControlTypes.SECTION
              ? ++sequence
              : `s-${sequence}`,
          sectionOrder: i + 1,
          ...(q?.controls &&
            isArray(q?.controls) && {
              controls: q?.controls?.map((cq) => {
                ++sequence;
                return {
                  ...cq,
                  index: sequence,
                };
              }),
            }),
        };
      });

      const sensitiveSections = form?.formQuestions.filter(
        (section) => section?.isSensitive,
      );
      formQuestions = [...formQuestions, ...sensitiveSections];
      let _sequence = 0;
      formQuestions = formQuestions.map((q, i) => {
        return {
          ...q,
          index:
            q.sectionType !== ControlTypes.SECTION
              ? ++_sequence
              : `s-${_sequence}`,
          sectionOrder: i + 1,
          ...(q?.controls &&
            isArray(q?.controls) && {
              controls: q?.controls?.map((cq) => {
                ++_sequence;
                return {
                  ...cq,
                  index: _sequence,
                };
              }),
            }),
        };
      });

      state.form = { ...form, formQuestions };
    },
    resetFormData: (state) => {
      state.form = { ...formsInitialState.form };
    },
  },
});

export const { setFormData, resetFormData } = slice.actions;

export default slice.reducer;

export const selectCandidate = (state) => state.candidates.activeCandidate;

// selectors

export const selectForm = (state) => state.formDesign.form;
