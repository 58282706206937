import React from 'react';

const FillLocation = ({ size = 17,color="#767476", ...props }) => (
    <svg width={size} height={size} viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M14.6834 6.04171C13.8084 2.19171 10.4501 0.458374 7.50008 0.458374C7.50008 0.458374 7.50008 0.458374 7.49175 0.458374C4.55008 0.458374 1.18341 2.18337 0.308413 6.03337C-0.666587 10.3334 1.96675 13.975 4.35008 16.2667C5.23341 17.1167 6.36675 17.5417 7.50008 17.5417C8.63341 17.5417 9.76675 17.1167 10.6417 16.2667C13.0251 13.975 15.6584 10.3417 14.6834 6.04171ZM7.50008 10.2167C6.05008 10.2167 4.87508 9.04171 4.87508 7.59171C4.87508 6.14171 6.05008 4.96671 7.50008 4.96671C8.95008 4.96671 10.1251 6.14171 10.1251 7.59171C10.1251 9.04171 8.95008 10.2167 7.50008 10.2167Z" fill={color} />
    </svg>


);


export default FillLocation;
