import { apiSlice } from '../../app/services/apiSlice';

const MAIL_URL = 'mail';

export const mailboxApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendEmail: builder.mutation({
      query: (data) => ({
        url: `${MAIL_URL}`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    sendEmailApplication: builder.mutation({
      query: (data) => ({
        url: `applications/contact`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    deleteMailAttachment: builder.mutation({
      query: (params) => ({
        url: `${MAIL_URL}/attachment`,
        method: 'DELETE',
        params,
      }),
    }),

    removeMailsItem: builder.mutation({
      query: (params) => ({
        url: `${MAIL_URL}/${params}`,
        method: 'DELETE',
        // params,
      }),
    }),
    getOneThread: builder.mutation({
      query: (data) => ({
        url: `${MAIL_URL}/${data.id}`,
      }),
    }),

    getAllMails: builder.mutation({
      query: (params) => ({
        url: `${MAIL_URL}`,
        params,
      }),
    }),

    preSignedMailUrl: builder.mutation({
      query: (data) => ({
        url: `${MAIL_URL}/preSignedMailAttachment`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),

    getUnread: builder.query({
      query: () => ({
        url: `${MAIL_URL}/unRead`,
      }),
    }),
  }),
});

export const {
  useSendEmailApplicationMutation,
  useGetAllMailsMutation,
  useGetOneThreadMutation,
  useRemoveMailsItemMutation,
  usePreSignedMailUrlMutation,
  useSendEmailMutation,
  useDeleteMailAttachmentMutation,
  useGetUnreadQuery
} = mailboxApi;
