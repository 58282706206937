import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import PortletBody from '../../../app/components/portlet/PortletBody';
import StyledRadioButton from '../../../app/components/radio/StyledRadioButton';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import StyledDot from '../../../app/components/styledComponents/StyledDot';
import StyledInput from '../../../app/components/styledComponents/StyledInput';
import InfoIconOutline from '../../../app/components/svgIcons/InfoIconOutline';
import { StyledLabel, Wrap } from '../../../assets/styledComponents/Global';
import { getCurrencySign } from '../../../utils/helper';
import theme from '../../../utils/themes';
// import { dateSuggestion } from '../assets/kanbanUtill';

const OfferLetter = () => {
  const parentData = window.opener.parentData;
  const [selectDate, setSelectDate] = useState({});
  const { job, salary, payPer, currency, offerLetter, availableDates, offertype, specificDate, account } = parentData

  const filterHtmlRigix = (text) => {
    return <div dangerouslySetInnerHTML={{ __html: text.replace('<p className="ql-align-justify"><br></p>', '') }} />;
  };
  return (
    <Wrap className="justify-content-center d-flex">
      <Wrap className="col-7  rounded">
        <StyledPreviewContainer className='my-2 rounded fw-semibold'>
          <InfoIconOutline className='me-2 ' />
          This is just a preview
        </StyledPreviewContainer>
        <Wrap className="bg-gray rounded-3 mt-5">
          <Wrap className="bg-white rounded-top d-flex flex-column px-5 py-4">
            <StyledLabel size="24px">Offer Letter</StyledLabel>
            <StyledLabel weight="500px">
              The following is your offer letter. Please read carefully before
              accepting
            </StyledLabel>
          </Wrap>

          <Wrap className='px-5 py-4'>
            <PortletBody>
              <StyledLabel weight="500px" color={theme.textColor}>
                {filterHtmlRigix(offerLetter ?? '')}
              </StyledLabel>
              <Wrap className="d-flex align-items-center gap-2 my-2 mt-3">
                <StyledLabel weight="500px" color={theme.textColor}>Job Title:</StyledLabel>
                <StyledLabel color={theme.primary}>{job?.title ?? ''} ({job?.job_type ?? ''})</StyledLabel>
              </Wrap>

              <Wrap className="d-flex align-items-center gap-2">
                <StyledLabel weight="500px" color={theme.textColor}>Salary:</StyledLabel>
                <StyledLabel color={theme.primary}>{getCurrencySign(currency ?? '')} {salary ?? 0}/{payPer ?? ''}</StyledLabel>
              </Wrap>
            </PortletBody>

            {(availableDates && availableDates.length) || specificDate ? <PortletBody className='mt-3'>
              <StyledLabel className='mb-3'>Select Start Date</StyledLabel>

              {offertype === 1 ? availableDates?.map((option, index, array) => option?.isChecked ? (
                <div
                  className={`py-3 px-3 rounded-2 border mb-3 ${option.dataValue === selectDate?.dataValue ? 'bg-lightPrimary' : ''}`}
                  key={`radio-${index}-${option.dataValue}`}
                >
                  <StyledRadioButton
                    label={option?.datelabel}
                    id={`${index}-${option.dataValue}`}
                    value={selectDate.dataValue === option?.dataValue}
                    size={12}
                    disabled={false}
                    onChange={() => {
                      setSelectDate(option);
                    }}
                  />
                </div>
              ) : null) : specificDate ?
                <div className={`py-3 px-3 rounded-2 border mb-3 ${specificDate.value === selectDate?.value ? 'bg-lightPrimary' : ''}`}
                  key={`radio-${specificDate.value}`}>
                  <StyledRadioButton
                    label={specificDate?.label}
                    id={`${specificDate?.value}`}
                    value={selectDate?.value === specificDate?.value}
                    size={12}
                    disabled={false}
                    onChange={() => {
                      setSelectDate(specificDate);
                    }}
                  /></div> : null}


              <StyledLabel className='mb-1 mt-2'>Don’t like any slot?</StyledLabel>
              <StyledLabel weight="500px" className='mb-3'>If you don’t like any slot, select a slot closest to your requirement and comment to the recruiter with the date suitable for you</StyledLabel>
              <StyledInput
                name="comment"
                placeholder="Leave Comment"
                as="textArea"
              />
            </PortletBody> : null}
            {account && <PortletBody className="d-flex flex-column border mt-3">
              <StyledLabel className="mb-2">For queries contact:</StyledLabel>
              <StyledLabel color={theme.textColor}>{account?.firstName ?? ''} {account?.lastName ?? ''}</StyledLabel>
              <Wrap className="d-flex align-items-center gap-1">
                <StyledLabel
                  color={theme.lightBoldBlack}
                  size="12px"
                  weight="500px"
                >
                  {account?.email ?? ''}
                </StyledLabel>
                <StyledDot $size="4px" />
                <StyledLabel
                  color={theme.lightBoldBlack}
                  size="12px"
                  weight="500px"
                >
                  {account?.mobile}
                </StyledLabel>
              </Wrap>
            </PortletBody>}

            <Wrap className='d-flex align-items-center gap-3 mt-3'>
              <StyledButton>Confirm and accept</StyledButton>
              <StyledButton variant="border" className="py-2">Reject</StyledButton>

            </Wrap>
          </Wrap>
        </Wrap>
      </Wrap>
    </Wrap>
  );
};

const StyledPreviewContainer = styled.div`
  background-color:${({ theme }) => theme.previewColor};
  border:1px solid ${({ theme }) => theme.previewColorBorder};
  width: 100%;
  padding: 8px 10px;
  display: flex;
  gap: 2;
`;
export default OfferLetter;
