import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import authReducer, {
  updateProfileState,
  updateToken
} from "../features/auth/authSlice";
import jobCreationReducer from '../features/job/creation/jobCreationSlice';
import mailCreationReducer from '../features/mailbox/creation/mailCreationSlice';
import messageCreationReducer from '../features/mailbox/creation/components/messageCreationSlice';
import candidatesReducer from '../features/candidates/candidatesSlice';
import formDesignReducer from '../features/forms/formsDesign/FormDesignSlice';
import NotesReducer from '../features/kanban/NotesDataSlice';
import stagesReducer from '../features/job/StagesDataSlice';
import notificationsReducer from '../features/Setting/Notifications/Apis/notificationsDataSlice';
import paginationReducer from '../app/components/pagination/paginationSlice';
import { apiSlice } from './services/apiSlice';
import campaignsReducer from '../features/campaigns/CampaignDataSlice';
import childCompanyReducer from '../features/Setting/ChildCompanies/ChildCompanyDataSlice';
import childCompanyHeaderReducer from '../features/Setting/ChildCompanies/ChildCompanyHeaderDataSlice';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { occyLs } from '../utils/localStorage';
import emailTemplateReducer from '../features/users/emailTemplateSlice';
import { isObject } from 'lodash';
import DocumentReducer from '../features/Setting/OfferTemplate/DocumentSlice';
import creditCompanyReducer from './layout/header/HeaderDataSlice';
import WizardDataReducer from '../features/dashboard/listing/WizardDataSlice';
import AccountReducer from '../features/Setting/AccountCalender/AccountSlice';
import { processValidationErrors } from '../features/Setting/utils';

let refreshStatus = 1; // 0 = failed, 1 = all okay, 2 = in progress
/**
 * this function monitors the accessToken expiry and
 * fetches a new accessToken based on refreshToken - only if user is active
 * @returns {function(*): function(*)}
 */
const tokenRefresher = (store) => (next) => (action) => {
  const token = occyLs.getObject('token');
  if (!token) return next(action);
  try {
    const tokenObj = jwtDecode(token);
    const activeTokenExpiry = moment.unix(tokenObj.exp).diff(moment(), 'minutes'); // token expiry in minutes
    if (
      tokenObj &&
      refreshStatus === 1 &&
      activeTokenExpiry > 0 &&
      activeTokenExpiry < 30
    ) {
      refreshStatus = 2;
      const ls = occyLs.getObject('refreshToken');

      fetch(`${process.env.REACT_APP_API_URL}/api/auth/refresh-auth`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          refreshToken: ls,
          username: tokenObj.username,
        }),
      })
        .then(async (rawResponse) => {
          const rsp = await rawResponse.json();
          const account = occyLs.getObject('account');
          store.dispatch(updateToken({
            accessToken: rsp?.accessToken + '|' + account?.company?.id,
            expiresIn: rsp?.expiresIn
          }));
          next(action);
          refreshStatus = 1;
        })
        .catch((e) => {
          console.error(e);
          refreshStatus = 0;
          next(action);
        });
    } else {
      // update user profile information in the global state if the profile is changed
      if (
        action?.meta?.arg?.queryCacheKey === 'getProfileInformation(undefined)' &&
        isObject(action?.payload?.account)
      ) {
        store.dispatch(updateProfileState(action?.payload?.account));
      }
      return next(action);
    }
  } catch (e) {
    console.error(e);
    window.location.href = '/logout';
  }
};

let lastErrorTime = 0;
const THROTTLE_TIME = 3000; 

const rtkQueryErrorLogger = (store) => (next) => (action) => {
  const now = Date.now();
  
      // if 401 then redirect to /
  if (action?.payload?.status === 401) {
    window.location.href = '/logout';
    return;
  }

  //if 504 securitas Background Check
  if (action?.payload?.status === 504 || action?.payload?.status === 'FETCH_ERROR') {

    const user = occyLs.getObject('account');
    if (
      user &&
      (user?.superCompanyId === process.env.REACT_APP_SECURITAS_ID) &&
      action?.meta?.baseQueryMeta?.request?.url?.includes(
        'api/checks/background',
      ) && now - lastErrorTime > THROTTLE_TIME 
    ) {
      toast.error(
        'Failed to process your request. Please contact Securitas IT Support. Error code: 504',
      );
      lastErrorTime = now;
    }
    
    return;
  }

  // Log a warning and show a toast!
  if (isRejectedWithValue(action)) {
    console.warn('We got a rejected action!');
    if (!action?.meta?.baseQueryMeta?.request?.url?.includes('api/templates') && !(action?.meta?.baseQueryMeta?.request?.url?.includes('api/candidates') && action?.meta?.baseQueryMeta?.request?.method === 'PUT') && now - lastErrorTime > THROTTLE_TIME ) {
      const validationErrors = processValidationErrors(action?.payload);
      if(validationErrors?.length > 0 ){
        toast.error(validationErrors);
      }
     else{ toast.error(
        action?.payload?.data?.message ||
        action?.payload?.error ||
        'Something went wrong!',
      );}
      lastErrorTime = now;
    }
  }
  return next(action);
};

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    document: DocumentReducer,
    auth: authReducer,
    createJob: jobCreationReducer,
    createEmail: mailCreationReducer,
    createMessage: messageCreationReducer,
    emailTemplate: emailTemplateReducer,
    candidates: candidatesReducer,
    formDesign: formDesignReducer,
    notes: NotesReducer,
    pagination: paginationReducer,
    stages: stagesReducer,
    notificationss: notificationsReducer,
    campaigns: campaignsReducer,
    childCompany: childCompanyReducer,
    childCompanyHeader: childCompanyHeaderReducer,
    headerCredits: creditCompanyReducer,
    WizardDataSlice: WizardDataReducer,
    accounts: AccountReducer,

  },
  middleware: (getDefaultMiddleware) => {
    return [
      rtkQueryErrorLogger,
      tokenRefresher,
      ...getDefaultMiddleware({
        serializableCheck: false,
      }).concat(apiSlice.middleware),
    ];
  },
  devTools: process.env.NODE_ENV !== 'production',
});
export default store;
