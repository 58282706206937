
export const GeneralPrivacyPolicy = [
  {
    title: 'This privacy policy explains:',
    data: [
      'What information we collect from our website and why we collect it.',
      'How we use that information.',
      'How to access and update that information.',
    ],
  },
];

export const CookiesPolicy = [
  {
    title: 'occy uses cookies for a number of purposes, including:',
    data: [
      'Authentication: occy uses cookies to recognise if you are logged into the platform, so that we can show you the right information and personalise your experience. ',
      'Security: occy uses cookies to support or enable security features we have deployed, and to help us detect malicious activity and violations of our Subscription Agreement.',
      'Preferences, features and services: occy uses cookies to know what your communications preferences are, and they help you fill out forms on the site. Plus they provide you with features, insights, and customised content through our plugins.',
      'Advertising: occy may use cookies to show you relevant advertising both on and off the site.',
      'Trusted partners help us serve advertising on and off the website, and analytics companies may also place cookies on your machine.',
      'Performance, Analytics and Research: occy uses cookies to help us learn how well our site and plugins perform across the globe. We also use cookies to understand, improve, and research products, features, and services, including when you access our site from other websites, applications, or devices such as your work computer or your mobile device. Most browsers allow you to control cookies through their settings preferences. Limiting the ability of websites to set cookies, however, may worsen your overall user experience.',
    ]
  }];


export const CookiePolicyInformation = [
  {
    id: 1,
    question: 'Why does occy use cookies? ',
    title:
      <div>
        occy uses cookies and similar technologies like pixels and tags to ensure that we give you the best possible experience on our site and platform.  <br />
        <br />
        A cookie is a small file placed onto your device. Cookies enable us to identify your device and enable the functioning of many occy features, including your ability to log into your account.
        <br />
        <br />
        If you don’t want to receive cookies, you can change your browser settings. If you use occy without changing your browser settings, we’ll assume that you’re happy to receive all cookies. Please note that the occy platform will not work properly without cookies.
      </div>
  },
  {
    id: 2,
    question: 'What types of cookies do we use?',
    title:
      <div>
        occy uses two types of cookies, persistent and session. A persistent cookie is set once you have logged in to your account. The next time you visit the platform using the same device, the persistent cookie will enable us to recognize you as an existing user, so you may not need to log in before using our services.
        <br />
        <br />
        A session cookie identifies a particular visit to the platform. Session cookies expire after a short time, or when you close your web browser.
      </div>
  },
  {
    id: 3,
    question: 'When does occy put cookies on my device?',
    title:
      'Cookies may be set by us when you visit the platform or website. Or they may be set by other websites or services who run content on the page you’re viewing (known as third-party cookies).'
  },
  {
    id: 4,
    question: 'What are cookies used for?',
    title: 'Cookies are used for many different purposes, they recognise when you navigate to our platform, store your preferences and improve your experience. Cookies also make your interactions with us more secure, faster, and help us ensure that your experience is personalised to you and in line with your settings.'

  },
];

export const PrivacyPolicyInformation = [
  {
    id: 1,
    question: 'What type of information do we collect?',
    title:
      'The privacy policy covers the information you share with us via our website or when you become a client:',
    data: [
      'Your name.',
      'Organisation address.',
      'Your email address.',
      'Telephone number and other contact information.',
    ],
  },
  {
    id: 2,
    question: 'How do we use the information we collect?',
    title:
      'Your information will be used by occy to provide you with information, including:',
    data: [
      'Assess webinars which you have signed up for.',
      'Send you your chosen downloads.',
      'Contact you to arrange a requested demonstration.',
      'To send you industry relevant information on a regular basis.',
      'Telephone number and other contact information.',
    ],
  },
  {
    id: 3,
    question: 'Who may have access to your information?',
    data: [
      'Employees and contractors of occy.',
      'occy take appropriate steps to protect information about you that is collected, processed, and stored.',
    ],
  },
];

export const TermsOfUse = [
  {
    title: <div>
      occy provides this Website and Services (located at www.occy.com) to you subject to the notices, terms, and conditions set forth in these terms (the “Terms of Use”). In addition, when you use any of our Services, you will be subject to the rules, guidelines, policies, terms, and conditions applicable to such service, and they are incorporated into this Terms of Use by this reference. This Terms of Service was created by occy, a brand of Smart Recruit Online.
      <br />
      <br />
      <br />
      These Terms of Use are effective as of 12th Feb 2023.
      <br />
      <br />
      <br />

      Your eligibility for use of the Website is contingent upon meeting the following conditions:
      <br />
      <br />
    </div>,
    data: [
      'You are at least 18 years of age ',
      'You use the Website and Services according to these Terms of Use and all applicable laws and regulations determined by the state and country of residence',
      'You provide complete and accurate registration information and maintain accurate registration information on the Website ',
      'You agree and understand that occy may, at any time, and without prior notice, revoke and/or cancel your access if you fail to meet these criteria or violate any portion of these Terms of Use'
    ]
  }];



export const UpdatingYourInformation = [
  {
    id: 1,
    question: 'Updating your information',
    title:
      `Should you wish to update your information, change your watchdogs, instigate your right to erasure, or see what information is held about yourself, you can access the occy portal here.
      When you originally applied for a role through the occy system, you will have received an email with your username and password. 
      If you have any problems accessing your portal please request a password reset, or email support@occy.com and we will be pleased to assist you.`,
  }
];

export const ChangestoPolicy = [
  {
    id: 1,
    question: 'We may change this policy from time to time. We will post any changes to this policy in the Candidate Portal',
    title:
      `Candidate GDPR to be added to this section`,
  }
];


export const CookiePolicy = [
  {
    id: 1,
    question: 'Does occy use Cookies?',
    title:
      `Candidate GDPR to be added to this section:`,
    data: [
      'occy uses cookies and similar technologies like pixels and tags to ensure that we give you the best possible experience on our platform.',
      'A cookie is a small file placed onto your device. Cookies enable us to identify your device and enable the functioning of many occy features, including your ability to log into your account.',
      'If you don’t want to receive cookies, you can change your browser settings. If you use occy without changing your browser settings, we’ll assume that you’re happy to receive all cookies. Please note that the occy platform will not work properly without cookies.',
      'Trusted partners help us serve advertising on and off the website, and analytics companies like Google Analytics, Quantcast, Nielsen, and Leadforensics, may also place cookies on your machine.',
    ],
  }
];

export const CookiesWeUse = [
  {
    id: 1,
    question: 'What types of cookies do we use?',

    data: [
      'occy uses two types of cookies, persistent and session. A persistent cookie is set once you have logged in to your account. The next time you visit the platform using the same device, the persistent cookie will enable us to recognize you as an existing user, so you may not need to log in before using our services.',
      'A session cookie identifies a particular visit to the platform. Session cookies expire after a short time, or when you close your web browser.',
      'Other technology, such as Adobe Flash, can be used to place the functional equivalent of a cookie on your computer.'
    ],
  }
];



export const PutCookies = [
  {
    id: 1,
    question: 'When does occy put cookies on my device?',
    data: [
      'Cookies may be set by us when you visit the platform. Or they may be set by other websites or services who run content on the page you’re viewing (known as third-party cookies).',

    ],
  }
];

export const CookiesUsedFor = [
  {
    id: 1,
    question: 'What are cookies used for?',
    data: [
      'Cookies are used for many different purposes, they recognise when you navigate to our platform, store your preferences and improve your experience. Cookies also make your interactions with us more secure, faster, and help us ensure that your experience is personalised to you and in line with your settings. ',

    ],
  }
];

export const CookiesNumber = [
  {
    id: 1,
    question: 'occy uses cookies for a number of purposes, including:',
    data: [
      'Authentication: occy uses cookies to recognise if you are logged into the platform, so that we can show you the right information and personalise your experience.',
      'Security: occy uses cookies to support or enable security features we have deployed, and to help us detect malicious activity and violations of our Subscription Agreement.'
    ],
  }
];

export const privacySideBar = [
  {
    id: 1,
    name: "Privacy Policy",
    tick: false,

  },
  {
    id: 2,
    name: "Terms and Conditions",
    tick: false,

  },
]