import React from 'react';

const Calender365Icon = ({ size, ...props }) => (
    <svg width={size} height={size} {...props}
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 278050 333334" shapeRendering="geometricPrecision"
        textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd"
        clipRule="evenodd"><path fill="#ea3e23"
            d="M278050 305556l-29-16V28627L178807 0 448 66971l-448 87 22 200227 60865-23821V80555l117920-28193-17 239519L122 267285l178668 65976v73l99231-27462v-316z" />
    </svg>

);


export default Calender365Icon;
