import { createSlice } from '@reduxjs/toolkit';
import { occyLs } from '../../../utils/localStorage';
import { uniqBy } from 'lodash';

const lsPagination = occyLs.getObject('config');

const slice = createSlice({
  name: 'pagination',
  initialState: {
    perPage: 10,
    page: 1,
    sortedColumn: { path: '', order: 'ASC' },
    query: '',
    pagination: lsPagination ?? []
  },
  reducers: {
    setPaginationPage: (state, { payload: { page } }) => {state.page = page},
    setPagination: (state, { payload }) => {
      if (payload?.access_key) {
        state.pagination = uniqBy([payload, ...state.pagination], "access_key");
        occyLs.setObject('config', state.pagination);
      }
    },
  }
})

export const {
  setPagination,
  setPaginationPage,
} = slice.actions;

export default slice.reducer;
export const getPaginationState = (state) => state.pagination;
export const getPagination = (state) => state.pagination.pagination;
export const getPaginationPage = (state) => state.pagination.page;
