import React from 'react';
import { Calendar } from 'react-calendar';
import styled from 'styled-components';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import CrossIcon from '../../../app/components/svgIcons/CrossIcon';
import DropDownIcon from '../../../app/components/svgIcons/DropDownIcon';
import { Wrap } from '../../../assets/styledComponents/Global';

function BottomModal({
  tileClassName,
  timeZone,
  selectedDate,
  selectDate,
  setChooseDate,
}) {
  return (
    <StyledModal>
      <ModalContent className="modal-content">
        <Wrap className='d-flex justify-content-between align-items-center px-4 pt-4'>
        <h2>Choose a Date</h2>
        <CrossIcon onClick={() => setChooseDate(false)}/>
        </Wrap>
        <hr/>
        <Calendar
          tileClassName={tileClassName}
          className="shadow-none p-0 w-auto px-4"
          value={selectDate}
          minDate={new Date()}
          onChange={(date) => selectedDate(date, timeZone)}
          next2Label={null}
          prev2Label={null}
          nextLabel={<DropDownIcon rotate="right" className="p-0" />}
          prevLabel={<DropDownIcon rotate="left" className="p-0" />}
        />
        <StyledButton className="mt-4 mx-4" onClick={() => setChooseDate(false)}>
          Confirm
        </StyledButton>
      </ModalContent>
    </StyledModal>
  );
}

const StyledModal = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 7000;
  background-color: rgba(0, 0, 0, 0.6);
`;

const ModalContent = styled.div`
  bottom: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 7000;
  animation: slide-up 0.3s ease-in-out;

  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;

export default BottomModal;
