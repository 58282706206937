import React from 'react';

const UploadIconStyled = ({ size, ...props }) => (
  <svg width={size} height={size} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.2666 5.41678L10.3999 3.28345L12.5333 5.41678" stroke="#4A154B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.3999 11.8166V3.34155" stroke="#4A154B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.8335 10C3.8335 13.6833 6.3335 16.6667 10.5002 16.6667C14.6668 16.6667 17.1668 13.6833 17.1668 10" stroke="#4A154B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default UploadIconStyled;
