import React from 'react';
import { Modal } from 'react-bootstrap';
import CrossIcon from '../../../app/components/svgIcons/CrossIcon';
import { StyledLabel, Wrap } from '../../../assets/styledComponents/Global';
import OpenMailBoxIcon from '../../../app/components/svgIcons/OpenMailBoxIcon';

const OfferAcceptedModal = ({
  show,
  onHide,
  setOfferLetter,
  agreement
}) => {
  return (
    <Modal
      show={show}
      centered
      onHide={onHide}
      size={'lg'}
      className="bg-black bg-opacity-50"
      backdrop="static"
    >
      <Modal.Body className="d-flex  flex-column bg-body rounded">
        <Modal.Header className=" bg-body d-flex align-items-end justify-content-end border-0">
          <CrossIcon size='30' className='cursor-pointer' onClick={() => setOfferLetter('')} />
        </Modal.Header>
        <Wrap className="col-8 d-flex align-items-center justify-content-center flex-column my-4  align-self-center">
          <OpenMailBoxIcon />
          <StyledLabel size="20px" className="mt-3 mb-1">
            Check your mailbox
          </StyledLabel>
          <StyledLabel weight="500px" className="text-center">
            We have mailed you an e-signing link for the {agreement ? 'NDA' : 'offer letter'}
          </StyledLabel>
        </Wrap>
      </Modal.Body>
    </Modal>
  );
};

export default OfferAcceptedModal;
