export const fileStatuses = {
  COVER: 55,
  CV: 56,
  DOC: 57,
  PRISM: 58,
  APP_FORM_DOC: 59, // if application form contains any file, then store it with APP_FORM_DOC name
  VIDEO_INTERVIEW: 60,
  REDACTED_CV: 61,
  JOB_REDACTED_CV: 62,
};
