import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'candidates',
  initialState: { activeCandidate: {}, CvValues: {}, FromKanban: false },
  reducers: {
    setCandidate: (state, { payload: { candidate } }) => {
      state.activeCandidate = { ...candidate };
    },
    setCVSearch: (state, { payload: { val } }) => {
      state.CvValues = { ...val };
    },
    setKanban: (state, { payload: val }) => {
      state.FromKanban = val;
    },
  },
});

export const { setCandidate, setCVSearch, setKanban } = slice.actions;

export default slice.reducer;

export const selectCandidate = (state) => state.candidates.activeCandidate;
export const getCvSearch = (state) => state.candidates.CvValues;
export const getFromKanban = (state) => state.candidates.FromKanban;
