import React from 'react';

const InfoIconOutline = ({ className, size='20', color='#FFAE1A', strokeWidth=1.5 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M10.0013 18.3334C14.5846 18.3334 18.3346 14.5834 18.3346 10.0001C18.3346 5.41675 14.5846 1.66675 10.0013 1.66675C5.41797 1.66675 1.66797 5.41675 1.66797 10.0001C1.66797 14.5834 5.41797 18.3334 10.0013 18.3334Z" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10 6.66675V10.8334" stroke={color} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.99609 13.3333H10.0036" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
  );
};

export default InfoIconOutline;
