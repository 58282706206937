import React from 'react';

const ImgIcon = ({ size = 20, ...props }) => (
<svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M4.625 0.5C2.351 0.5 0.5 2.351 0.5 4.625V23.375C0.5 24.1287 0.706285 24.8344 1.06104 25.4434L11.8906 14.8525C13.0726 13.7073 14.9273 13.7058 16.1108 14.8525L26.936 25.4463C27.2915 24.8365 27.5 24.1302 27.5 23.375V4.625C27.5 2.351 25.649 0.5 23.375 0.5H4.625ZM18.875 6.5C20.3248 6.5 21.5 7.67525 21.5 9.125C21.5 10.5747 20.3248 11.75 18.875 11.75C17.4252 11.75 16.25 10.5747 16.25 9.125C16.25 7.67525 17.4252 6.5 18.875 6.5ZM14 16.2456C13.8037 16.2455 13.6073 16.3201 13.4551 16.4683L2.68115 27.0107C3.26015 27.322 3.92225 27.5 4.625 27.5H23.375C24.0763 27.5 24.7369 27.3234 25.3159 27.0137L14.5464 16.4697C14.3934 16.3212 14.1963 16.2457 14 16.2456Z" fill="#869BB8"/>
</svg>

);

export default ImgIcon;
