import React from 'react';

const ArrowButtonIcon = ({ size = 18, color = "white", ...props }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M9.62012 3.95331L13.6668 7.99998L9.62012 12.0466" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.33301 8H13.553" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    

  );
};

export default ArrowButtonIcon;
