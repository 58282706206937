export const routesWithMeta = [
    {
      path: "/",
      meta: {
        title: 'Dashboard',
        description: 'occy APP...!'
      }
    },
    {
      path: "/jobs",
      meta: {
        title: 'Jobs',
        description: 'List of Jobs',
        // img: 'https://i.imgur.com/V7irMl8.png'
      }
    },
    {
      path: "/calendar",
      meta: {
        title: 'Calendar',
        description: 'Calendar ,contains all the events and interviews'
      }
    },
    {
      path: "/mails",
      meta: {
        title: 'Mailbox',
        description: 'User emails'
      }
    },
  ];


  export const trackEvent = [
    {
      path: "/dashboard",
      meta: {
        title: 'Dashboard',
        description: 'Dashboard'
      }
    },
    {
      path: "/jobs",
      meta: {
        title: 'Jobs',
        description: 'Jobs',
        // img: 'https://i.imgur.com/V7irMl8.png'
      }
    },
    {
      path: "/calendar",
      meta: {
        title: 'Calendar',
        description: 'Calendar'
      }
    },
    {
      path: "/campaigns",
      meta: {
        title: 'Campaigns',
        description: 'Campaigns'
      }
    },
    {
      path: "/mails",
      meta: {
        title: 'Mailbox',
        description: 'Mailbox'
      }
    },
    {
      path: "/candidates",
      meta: {
        title: 'Candidates',
        description: 'Candidates'
      }
    },
    {
      path: "/copywrite",
      meta: {
        title: 'Copywrite',
        description: 'Copywrite'
      }
    },
    {
      path: "/analytics",
      meta: {
        title: 'Analytics',
        description: 'Analytics'
      }
    },
    {
      path: "/settings",
      meta: {
        title: 'Settings',
        description: 'Settings'
      }
    },
    {
      path: "/slots",
      meta: {
        title: 'Slots',
        description: 'Slots'
      }
    },
    {
      path: "/notifications",
      meta: {
        title: 'Notifications',
        description: 'Notifications'
      }
    },
    {
      path: "/public",
      meta: {
        title: 'Public',
        description: 'Public'
      }
    },
    {
      path: "/p",
      meta: {
        title: 'Public',
        description: 'Public'
      }
    },
    {
      path: "/logout",
      meta: {
        title: 'Logout',
        description: 'Logout'
      }
    }
  ];

