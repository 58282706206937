import React, { useEffect, useState } from 'react';
import { useGetCustomFormByIdQuery, useGetDeiFormQuery } from '../formApiSlice';
import { useParams } from 'react-router-dom';
import PublicForm from '../formPublic/PublicForm';
import { cloneDeep } from 'lodash';
import { ControlTypes } from '../../../utils/helper';
import MultipleChoiceIcon from '../../../app/components/svgIcons/MultipleChoiceIcon';
import { getNestedControls } from '../assets/formUtils';

const FormPreview = () => {
  const { id } = useParams();
  const [form, setForm] = useState({});
  const { data: formData } = useGetCustomFormByIdQuery(id, {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });

  const { data: deiForm } = useGetDeiFormQuery(null, {
    refetchOnMountOrArgChange: true,
    skip: !formData?.dei,
  });

  useEffect(() => {
    if (formData) {
      let _form = cloneDeep(formData);
      if (deiForm) {
        setForm({
          ..._form,
          formQuestions: [
            ..._form.formQuestions,
            {
              sectionLabel: 'DE&I',
              sectionType: ControlTypes.SECTION,
              icon: MultipleChoiceIcon,
              description: 'Questions on Diversity, Equality and Inclusion',
              controls: [...(deiForm?.formQuestions || [])],
              isSensitive: true,
            },
          ],
        });
      } else {
        setForm(_form);
      }
    }
  }, [formData, deiForm]);

  return (
    <PublicForm
      formData={{
        ...form,
        viewType: 'single',
        formQuestions: [...getNestedControls(form?.formQuestions)],
      }}
      isReadOnly
    />
  );
};

export default FormPreview;
