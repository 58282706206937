import { useEffect } from 'react';
import { useLoginWithCalenderQuery } from './CalenderApiSlice';
import { OC_CALENDAR_TYPE } from '../../calender/assets/calenderutils';

const GoogleCalendar = () => {
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  } //http://localhost:3000/api/calendar/google-callback-url?code=4/0Adeu5BXg5FGSQVUum3kWR_kLhmEXXsFFexkUtGCBakgQkB7oo64z4o9YeDautHpjEmiyWg&scope=email%20profile%20https://www.googleapis.com/auth/calendar%20openid%20https://www.googleapis.com/auth/userinfo.profile%20https://www.googleapis.com/auth/userinfo.email&authuser=5&prompt=consent
  const urlParams = new URLSearchParams(window.location.search);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  const { data: token } = useLoginWithCalenderQuery(
    { code: params.code, state: params.state,provider:OC_CALENDAR_TYPE.GOOGLE_CALENDAR },
    {
      skip: !params.code,
    },
  );

  useEffect(() => {
    if (token) {
      window.onunload = function (e) {
        window.opener.onunload(token);
      };
      window.close();
    }
  }, [token]);

  return 'Loading';
};

export default GoogleCalendar;
