import React from 'react';

const FileFormIcon = ({ size = '14',color='#4A154B', ...props }) => (
  <svg
    width={size}
    height={size}
    {...props}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00033 11.3334V7.33337L4.66699 8.66671"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 7.33337L7.33333 8.66671"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6663 6.66671V10C14.6663 13.3334 13.333 14.6667 9.99967 14.6667H5.99967C2.66634 14.6667 1.33301 13.3334 1.33301 10V6.00004C1.33301 2.66671 2.66634 1.33337 5.99967 1.33337H9.33301"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6663 6.66671H11.9997C9.99967 6.66671 9.33301 6.00004 9.33301 4.00004V1.33337L14.6663 6.66671Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FileFormIcon;
