import React from 'react';

const CandidatesIcon = ({ size = 18 ,color = 'currentColor',...props}) => {
  return (
    <svg width={size} height={size+1} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M18.0001 8.05502C17.9401 8.04502 17.8701 8.04502 17.8101 8.05502C16.4301 8.00502 15.3301 6.87502 15.3301 5.47502C15.3301 4.04502 16.4801 2.89502 17.9101 2.89502C19.3401 2.89502 20.4901 4.05502 20.4901 5.47502C20.4801 6.87502 19.3801 8.00502 18.0001 8.05502Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.9704 15.335C18.3404 15.565 19.8504 15.325 20.9104 14.615C22.3204 13.675 22.3204 12.135 20.9104 11.195C19.8404 10.485 18.3104 10.245 16.9404 10.485" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.97047 8.05502C6.03047 8.04502 6.10047 8.04502 6.16047 8.05502C7.54047 8.00502 8.64047 6.87502 8.64047 5.47502C8.64047 4.04502 7.49047 2.89502 6.06047 2.89502C4.63047 2.89502 3.48047 4.05502 3.48047 5.47502C3.49047 6.87502 4.59047 8.00502 5.97047 8.05502Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.00043 15.335C5.63043 15.565 4.12043 15.325 3.06043 14.615C1.65043 13.675 1.65043 12.135 3.06043 11.195C4.13043 10.485 5.66043 10.245 7.03043 10.485" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.0001 15.525C11.9401 15.515 11.8701 15.515 11.8101 15.525C10.4301 15.475 9.33008 14.345 9.33008 12.945C9.33008 11.515 10.4801 10.365 11.9101 10.365C13.3401 10.365 14.4901 11.525 14.4901 12.945C14.4801 14.345 13.3801 15.485 12.0001 15.525Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.08973 18.675C7.67973 19.615 7.67973 21.155 9.08973 22.095C10.6897 23.165 13.3097 23.165 14.9097 22.095C16.3197 21.155 16.3197 19.615 14.9097 18.675C13.3197 17.615 10.6897 17.615 9.08973 18.675Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  );
};

export default CandidatesIcon;
