import styled from 'styled-components';
import theme from '../../utils/themes';
import StyledButton from '../../app/components/styledComponents/StyledButton';

export const Wrap = styled.div``;
export const Image = styled.img``;
export const H1 = styled.h1``;
export const H2 = styled.h2``;
export const H3 = styled.h3``;
export const H4 = styled.h4``;
export const H5 = styled.h5``;
export const H6 = styled.h6``;

export const P = styled.p``;
export const B = styled.b``;
export const I = styled.i``;
export const Span = styled.span``;

export const HR = styled.hr`
  border-top: 1px solid $shadow-color-dark;
  width: ${({ width }) => width ?? 'auto'};
`;

export const StyledLabel = styled.label`
font-family: ${({ font }) => font && font};
  font-size: ${({ size }) => size ?? '14px'};
  font-weight: ${({ weight }) => weight ?? '600'};
  color: ${({ color }) => color ?? 'black'};
  background-color: ${({ bgColor }) => bgColor ?? 'none'};
  word-wrap: break-word;
`;

export const StyledSpan = styled.span`
  font-size: ${({ size }) => size ?? '14px'};
  font-weight: ${({ weight }) => weight ?? '600'};
  color: ${({ color }) => color ?? 'black'};
`;

export const ScrollWrap = styled.div`
  height: ${({ height }) => height ?? '14px'};
  width: ${({ width }) => width ?? '100%'};
  margin-top: 12px;
  overflow: auto;
`;

export const StyledButtonNew = styled(StyledButton)`
  color: ${({ color, theme }) => color ?? theme.blackCardColor};
  padding: 10px 20px;
  font-size: ${({ size }) => size ?? '14px'};
  font-weight: ${({ weight }) => weight ?? '600'};
  font-family: ${({ fontFamily }) => fontFamily ?? 'Quick Sand'};
  color: ${({ color }) => color ?? theme.body};
  border-radius: 8px;
  border: ${({ borderWidth , $bordercolor, $bordertype }) =>  `${borderWidth ?? '1'}px ${$bordertype ?? "solid"} ${$bordercolor ?? 'none'}`} !important;
  background: ${({ background, theme }) =>
    background ? background : theme.white} !important;
`;


export const StyledTruncateText = styled.label`
font-family: ${({ font }) => font && font};
  font-size: ${({ size }) => size ?? '14px'};
  font-weight: ${({ weight }) => weight ?? '600'};
  color: ${({ color }) => color ?? 'black'};
  background-color: ${({ bgColor }) => bgColor ?? 'none'};
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: ${({ width }) => width ?? '300px'};
  // height: 1.2em;
  white-space: nowrap;
`;


export const StyledDotsLoader= styled.div`
  position: relative;
  width: ${({size}) => size ?? '10px'};
  height: ${({size}) => size ?? '10px'};
  border-radius: 5px;
  background-color: ${({ color }) => color ?? theme.primary};
  color: ${({ color }) => color ?? theme.primary};
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.3s;

&::before, &::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
&::before {
  left: -${({gap}) => gap ?? '15px'};
  width: ${({size}) => size ?? '10px'};
  height: ${({size}) =>size ?? '10px'};
  border-radius: 5px;
  background-color: ${({ color }) => color ?? theme.primary};
  color: ${({ color }) => color ?? theme.primary};
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
&::after {
  left: ${({gap}) => gap ?? '15px'};
  width: ${({size}) => size ?? '10px'};
  height: ${({size}) => size ?? '10px'};
  border-radius: 5px;
  background-color: ${({ color }) => color ?? theme.primary};
  color: ${({ color }) => color ?? theme.primary};
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: ${({ color }) => color ?? theme.primary};
  }
  50%, 100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}
`