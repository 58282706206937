import { createSlice } from '@reduxjs/toolkit';


const slice = createSlice({
    name: 'campaigns',
    initialState: {
        campaigns: {
            jobId: "",
            campId: "",
            isJob: false
        }
    },
    reducers: {
        setCampaignData: (state, { payload }) => {
            state.campaigns = { ...state?.campaigns, ...payload };
        },
        resetCampaignData: (state) => {
            state.campaigns = {};
        },
    },
});

export const { setCampaignData, resetCampaignData } = slice?.actions;

export default slice?.reducer;

export const getStagesState = (state) => state?.campaigns?.campaigns;