import styled from 'styled-components';
import themes from '../../../utils/themes';

export const StyledPublicCheck = styled.span`
  border-radius: ${(props) => (props?.size ? `${props?.size / 3}px` : '6px')};
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: start;
  color: ${themes.white};
  transition: background-color 0.3s ease;
  background-color: ${({ theme, value, assessment }) =>
    assessment ? "none" : value ? theme.primary : 'transparent'};
  border: 1px solid
    ${({ theme, value, assessment }) => (assessment ? "none" : value ? theme.primary : themes.inputBorder)};
  cursor: pointer;

  svg {
    fill: currentColor;
  }

  &.publicForms {
    border: 1px solid
      ${({ theme, value }) => (value ? theme.backgroundColor : theme.primary)};
  }
`;
