import styled from 'styled-components';

const StyledFormContainer = styled.div`
  position: relative;
  background: ${(props) =>
    `linear-gradient(to bottom, ${props?.theme?.backgroundColor}, ${props?.theme?.backgroundColor}db)`};
  font-family: ${(props) => props?.theme?.fontFamily};
  border-radius: 5px;
  color: ${(props) => props?.theme?.primary};
  transition: transform 0.3s ease;

  .ql-editor {
    color: ${({ theme, publicForm }) =>
      publicForm ? theme.primary : theme.textColor};
  }
`;

export default StyledFormContainer;
