import React from 'react';

const OccyLogoBlue = ({ size = 170 }) => (
  <svg
    width="170"
    height="51"
    viewBox="0 0 560 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M69.23 148.55C73.65 149.43 78.2 149.93 82.85 150C115.29 150.45 143.09 130.38 154.12 101.94H156.18C159.67 101.94 162.81 99.85 164.19 96.65C165.98 92.51 167.85 85.59 167.85 74.74C167.85 63.89 165.99 56.97 164.19 52.83C162.8 49.63 159.67 47.54 156.18 47.54H154.12C144.3 22.16 121.11 3.45002 93.19 0.0300181C91.62 -0.159982 90.07 0.660018 89.38 2.09002C88.4 4.12002 86.33 5.52002 83.93 5.52002C81.53 5.52002 79.45 4.12002 78.48 2.09002C77.79 0.660018 76.25 -0.159982 74.68 0.0300181C46.76 3.45002 23.57 22.16 13.73 47.53H11.67C8.18 47.53 5.04 49.62 3.66 52.82C1.87 56.96 0 63.88 0 74.73C0 85.58 1.86 92.5 3.66 96.64C5.05 99.84 8.18 101.93 11.67 101.93H13.79C23.02 125.51 43.85 143.46 69.22 148.54L69.23 148.55Z"
      fill="#EC008C"
    />
    <path
      d="M84.06 133.56C114.706 133.56 139.55 113.082 139.55 87.82C139.55 62.5585 114.706 42.08 84.06 42.08C53.4137 42.08 28.57 62.5585 28.57 87.82C28.57 113.082 53.4137 133.56 84.06 133.56Z"
      fill="white"
    />
    <path
      d="M55.81 77.02C48.4 77.02 42.4 83.02 42.4 90.43C42.4 97.84 48.4 103.84 55.81 103.84C63.22 103.84 69.22 97.84 69.22 90.43C69.22 83.02 63.22 77.02 55.81 77.02ZM60.35 89.13C58.44 89.13 56.89 87.58 56.89 85.67C56.89 83.76 58.44 82.21 60.35 82.21C62.26 82.21 63.81 83.76 63.81 85.67C63.81 87.58 62.26 89.13 60.35 89.13Z"
      fill="#EC008C"
    />
    <path
      d="M113.35 77.02C105.94 77.02 99.94 83.02 99.94 90.43C99.94 97.84 105.94 103.84 113.35 103.84C120.76 103.84 126.76 97.84 126.76 90.43C126.76 83.02 120.76 77.02 113.35 77.02ZM118.32 89.13C116.41 89.13 114.86 87.58 114.86 85.67C114.86 83.76 116.41 82.21 118.32 82.21C120.23 82.21 121.78 83.76 121.78 85.67C121.78 87.58 120.23 89.13 118.32 89.13Z"
      fill="#EC008C"
    />
    <path
      d="M236.7 111.65C216.11 111.65 201 95.73 201 74.74C201 53.75 216.12 39.32 236.7 39.32H258.2C279.48 39.32 294.2 53.75 294.2 74.74C294.2 95.73 279.08 111.65 258.2 111.65H236.7ZM258.2 96.13C270.23 96.13 278.69 87.47 278.69 75.54C278.69 63.61 270.24 54.85 258.2 54.85H236.7C225.06 54.85 216.51 63.51 216.51 75.54C216.51 87.57 224.96 96.13 236.7 96.13H258.2Z"
      fill="#262262"
    />
    <path
      d="M340.22 111.65C319.63 111.65 304.52 95.73 304.52 74.74C304.52 53.75 319.64 39.32 340.22 39.32H376.54C380.82 39.32 384.3 42.79 384.3 47.08C384.3 51.37 380.83 54.84 376.54 54.84H340.22C328.58 54.84 320.03 63.5 320.03 75.53C320.03 87.56 328.48 96.12 340.22 96.12H376.54C380.82 96.12 384.3 99.59 384.3 103.88C384.3 108.17 380.83 111.64 376.54 111.64H340.22V111.65Z"
      fill="#262262"
    />
    <path
      d="M424.39 111.65C403.8 111.65 388.69 95.73 388.69 74.74C388.69 53.75 403.81 39.32 424.39 39.32H461.31C465.59 39.32 469.07 42.79 469.07 47.08C469.07 51.37 465.6 54.84 461.31 54.84H424.39C412.75 54.84 404.2 63.5 404.2 75.53C404.2 87.56 412.65 96.12 424.39 96.12H461.31C465.59 96.12 469.07 99.59 469.07 103.88C469.07 108.17 465.6 111.64 461.31 111.64H424.39V111.65Z"
      fill="#262262"
    />
    <path
      d="M510.93 103.89V85.39L479.45 52.42C475.17 47.19 478.89 39.33 485.65 39.33C488.1 39.33 490.41 40.45 491.93 42.37L519.29 71.26L546.47 42.2C547.91 40.38 550.1 39.32 552.41 39.32C558.81 39.32 562.33 46.76 558.27 51.71L526.44 85.38V103.88C526.44 108.17 522.97 111.64 518.68 111.64C514.4 111.64 510.92 108.17 510.92 103.88L510.93 103.89Z"
      fill="#262262"
    />
  </svg>
);

export default OccyLogoBlue;
