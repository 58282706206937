import React from 'react';

const AlignIcon = ({ size = 16, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.33301 3H14.333"
      stroke="#4A154B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.33301 6.33325H8.64634"
      stroke="#4A154B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.33301 9.66675H14.333"
      stroke="#4A154B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.33301 13H8.64634"
      stroke="#4A154B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AlignIcon;
