import React from 'react';

const GreenTickIcon = ({ size = 30, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0ZM17.1803 8.16967C16.907 7.8963 16.4751 7.87808 16.1806 8.11499L16.1197 8.16967L10.05 14.2393L7.88033 12.0697C7.58744 11.7768 7.11256 11.7768 6.81967 12.0697C6.5463 12.343 6.52808 12.7749 6.765 13.0694L6.81967 13.1303L9.51967 15.8303C9.79304 16.1037 10.2249 16.1219 10.5194 15.885L10.5803 15.8303L17.1803 9.23033C17.4732 8.93744 17.4732 8.46256 17.1803 8.16967Z"
      fill="#26A69A"
    />
  </svg>
);

export default GreenTickIcon;
