import { createSlice } from '@reduxjs/toolkit';


const slice = createSlice({
  name: 'stages',
  initialState: {
    stages:[]
  },
  reducers: {
    setStagesData: (state, { payload: { values } }) => {
      state.stages = [...values];
    },
    resetStagesData: (state) => {
      state.stages = [];
    },
  },
});

export const { setStagesData, resetStagesData } = slice.actions;

export default slice.reducer;

export const getStagesState = (state) => state.stages.stages;