import { apiSlice } from '../../app/services/apiSlice';

const INTERVIEW_URL = 'interviews';

export const InterviewSliceApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addSlots: builder.mutation({
      query: (data) => ({
        url: `${INTERVIEW_URL}/slot`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),

    updateSlots: builder.mutation({
      query: (data) => ({
        url: `${INTERVIEW_URL}/slot/${data.id}`,
        method: 'PUT',
        body: {
          ...data,
        },
      }),
    }),
    getAllSlots: builder.mutation({
      query: (params) => ({
        url: `${INTERVIEW_URL}/slot`,
        method: 'GET',
        params: params,
      }),
      transformResponse: (responseData) => {
        responseData = responseData?.slots;
        return responseData || [];
      },
    }),
    removeSlots: builder.mutation({
      query: (params) => ({
        url: `${INTERVIEW_URL}/slot/${params.id}`,
        method: 'DELETE',
        // params,
      }),
    }),
    getInterviewDetails: builder.query({
      query: (params) => ({
        url: `interviews/public?token=${params}`,
      }),
    }),
    getInterviewSlots: builder.query({
      query: (params) => ({
        url: `/interviews/slots/public?token=${params}`,
      }),
    }),
    sendInvitaions: builder.mutation({
      query: (data) => ({
        url: `${INTERVIEW_URL}/invitation`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),

    sendSmsInvitaton: builder.mutation({
      query: (data) => ({
        url: `applications/sms`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),

    addAppInSlots: builder.mutation({
      query: (data) => ({
        url: `${INTERVIEW_URL}/app/slot`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),

    preSignedAttachmentInterviewUrl: builder.mutation({
      query: (data) => ({
        url: `${INTERVIEW_URL}/slot/preSignedMailAttachment`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    postInterviewSlotTime: builder.mutation({
      query: (data) => ({
        url: `interviews/public?token=${data?.token}`,
        method: 'POST',
        body: data,
       
      }),
    }),
    getInterviewstimeSlots: builder.mutation({
      query: (params) => ({
        url: `${INTERVIEW_URL}/slot`,
        method: 'GET',
        params: params,
      }),
      transformResponse: (responseData) => {
        responseData = responseData?.slots;
        return responseData || [];
      },
    }),

    deleteInterview: builder.mutation({
      query: (id) => ({
        url: `${INTERVIEW_URL}/${id}`,
        method: 'DELETE',
        // params,
      }),
    }),


  }),
});

export const {
  useGetAllSlotsMutation,
  useAddSlotsMutation,
  useSendInvitaionsMutation,
  useSendSmsInvitatonMutation,
  useRemoveSlotsMutation,
  useUpdateSlotsMutation,
  useGetInterviewDetailsQuery,
  useGetInterviewSlotsQuery,
  usePostInterviewSlotTimeMutation,
  useAddAppInSlotsMutation,
  usePreSignedAttachmentInterviewUrlMutation,
  useGetAllInterviewsSlotsMutation,
  useDeleteInterviewMutation,
} = InterviewSliceApi;